@import '../common.scss';

.cmp-search-user {
    &__content {
        width: px2rem(345px);
        height: px2rem(55px);
        border-radius: px2rem(6px);
        display: flex;
        align-items: center;
        background-color: #F3F3F3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 px2rem(11px);
        box-sizing: border-box;
    }

    &__input {
        flex-grow: 1;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            width: px2rem(16px);
            height: px2rem(16px);
            background-image: url('~@/images/guild-HistoricalDataSearch-icon.png');
            background-repeat: no-repeat;
            background-size: cover;
        }

        input {
            all: unset;
            flex: 1;
            margin-left: px2rem(6px);
        }
    }

    &__confirm {
        flex-shrink: 0;
        width: px2rem(73px);
        height: px2rem(30px);
        color: white;
        display: flex;
        align-items: center;
        border-radius: px2rem(30px);
        justify-content: center;
        background: linear-gradient(to right, #1FF5A9, #00E0D5);
    }

    &__user {
        flex: 1;
        display: flex;
        align-items: center;

        &-avatar {
            width: px2rem(35px);
            height: px2rem(35px);
            border-radius: 50%;
            object-fit: cover;
            flex-shrink: 0;
        }

        &-info {
            margin-left: px2rem(10px);
            font-size: px2rem(13px);
            color: #000000;
            width: px2rem(250px);
            overflow: hidden;
            display: flex;
            flex-direction: column;

            &-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }



    &__cancel {
        width: px2rem(22px);
        height: px2rem(22px);
        background: transparent url('~@/images/close.png') no-repeat;
        background-size: cover;
    }
}

.mirror .cmp-search-user {
    .isAr {
        direction: rtl;
    }

    direction: rtl;

    &__input {

        input {
            margin-left: unset;
            margin-right: px2rem(6px);
        }
    }

    &__user {
        &-info {
            margin-left: unset;
            margin-right: px2rem(10px);
            text-align: right;
        }
    }
}