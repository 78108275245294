@import '../../common.scss';

.pg-room-data {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: px2rem(30px);


  .cmp-member-card {
    margin-top: px2rem(14px);
    width: px2rem(345px);
    background-color: #fff;

    &__info {
      &-avatar {
        img {
          border-radius: px2rem(6px);
        }
      }

      &-name {
        color: #000000;
        width: px2rem(250px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-No {
        margin-top: px2rem(8px);
        color: #B7B7B7;
        font-size: px2rem(11px);
      }
    }
  }

  .cmp-number-card2 {
    width: px2rem(345px);
    margin: px2rem(15px) px2rem(10px) 0;
    background: unset;
    background-color: #fff;
    border-radius: px2rem(6px);
    display: flex;
    flex-wrap: wrap;

    &__item {
      flex-shrink: 0;
      flex: unset;
      margin-right: unset;

      &-val {
        font-size: px2rem(14px);
        color: #000;

        &:after {
          border-bottom: rgba($color: rgb(153, 153, 153), $alpha: 0.2) px2rem(1px) solid;
        }
      }

      &-key {
        color: #B7B7B7;
      }

      &:not(:nth-child(3n)) {

        &:after {
          border-right: rgba($color: rgb(153, 153, 153), $alpha: 0.2) px2rem(1px) solid;
        }
      }
    }
  }

  .adm-infinite-scroll {
    display: none;
  }

  .cmp-mdt {
    margin: px2rem(30px) px2rem(15px) 0;
  }
}

.mirror .pg-room-data {
  .cmp-member-card {

    &__info {
      &-basic {
        align-items: flex-end;
      }

      .isAr {
        direction: rtl;
      }
    }
  }
}