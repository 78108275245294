@import '~@/common.scss';

.cmp-guild-wallet-data-member-detail-list {
    &__scroll {
        min-height: px2rem(150px);

        .cmp-scroll-list__empty {
            &>img {
                width: px2rem(100px);
                height: px2rem(90px);
            }
        }
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: px2rem(64px);
        border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;

        &:last-child {
            border-bottom: none;
        }

        &>.left {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            &>div:first-child {
                display: flex;
                align-items: center;
                margin-bottom: px2rem(8px);

                span {
                    word-break: break-word;
                }

                &>div {
                    flex-shrink: 0;
                }

                &>span:first-child {
                    font-size: px2rem(16px);
                    font-weight: 500;
                    color: #FFFFFF;
                }

                &>.ok {
                    width: px2rem(75px);
                    height: px2rem(22px);
                    border-radius: px2rem(10px);
                    font-size: px2rem(10px);
                    margin: 0 px2rem(5px);
                    box-shadow: 0 px2rem(1px) px2rem(3px) rgba(18, 164, 209, 0.46);
                }

                &>.fail {
                    height: px2rem(22px);
                    border-radius: px2rem(10px);
                    font-size: px2rem(10px);
                    color: #fff;
                    margin: 0 px2rem(5px);
                    background-color: rgba(255, 92, 92, 1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 px2rem(1px) px2rem(3px) rgba(209, 21, 18, 0.46);
                    padding: 0 px2rem(10px);
                }

                &>.unactivated-btn {
                    height: px2rem(22px);
                    border-radius: px2rem(10px);
                    padding: 0 px2rem(10px);
                    font-size: px2rem(10px);
                    margin: 0 px2rem(5px);
                    color: #fff;
                    background: linear-gradient(to bottom, #F5B21F, #E09900);
                }
            }

            &>span:last-child {
                font-size: px2rem(12px);
                color: #999999;
            }
        }

        &>.right {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;

            &>div:first-child {
                display: flex;
                align-items: center;
                margin-bottom: px2rem(8px);
                justify-content: flex-end;

                &>.num {
                    font-size: px2rem(13px);
                    font-weight: 500;
                    color: #FFFFFF;
                    margin: 0 px2rem(3px);
                }

                &>.img {
                    width: px2rem(16px);
                    height: px2rem(16px);
                    background: url('~@/images/gem.png') no-repeat;
                    background-size: cover;
                }
            }

            &>span:last-child {
                font-size: px2rem(12px);
                color: #999999;
            }
        }

        &>.right-btn {
            min-width: px2rem(108px);
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &>.right-transferring-cancel {
                width: px2rem(60px);
                height: px2rem(24px);
                background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
                border: px2rem(1px) solid #F9AF4A;
                box-sizing: border-box;
                border-radius: px2rem(12px);
                font-size: px2rem(10px);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
            }
        }
    }
}