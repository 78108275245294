@import '../../../common.scss';

.cmp-help-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image {
    width: px2rem(68px);
    height: px2rem(68px);
  }
  &__name {
    font-size: px2rem(12px);
    color: #999999;
    margin-top: px2rem(5px); 
  }
  &__value {
    font-size: px2rem(12px);
    color: #1A1A1A;
    margin-top: px2rem(4px);
    line-height: px2rem(20px);
    display: flex;
    align-items: center;
  }
  &__copy {
    width: px2rem(20px);
    height: px2rem(20px);
  }
}