@import '~@/common.scss';

.cmp-agency-reward-income-table {
  background-color: rgba($color: #ffffff, $alpha: 0.1);
  border-radius: px2rem(6px);
  padding: 0 px2rem(10px);
  width: px2rem(345px);
  box-sizing: border-box;

  table {
    width: 100%;
    color: rgba($color: #ffffff, $alpha: 0.6);
    border-collapse: collapse;

    th {
      text-align: left;
      font-size: px2rem(12px);
    }

    tr {
      height: px2rem(45px);
      position: relative;

      td {
        font-size: px2rem(10px);
      }

      &>td:last-child {

        &>div {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 100%;
          flex-wrap: wrap;

          span {
            font-size: px2rem(10px);
            color: #FFFFFF;
            margin-right: px2rem(6px);
            word-break: break-word;
            max-width: px2rem(60px);
          }

          .img {
            width: px2rem(16px);
            height: px2rem(16px);
            background: url('~@/images/gem.png');
            background-size: cover;
          }
        }

      }

      &>th:last-child {
        text-align: right;
      }


    }

    thead tr,
    tbody tr:not(:last-child) {
      border-bottom: px2rem(1px) solid rgba($color: #ffffff, $alpha: 0.1);
    }

    .empty {
      width: 100%;
      height: px2rem(176px);
      display: flex;
      justify-content: center !important;
      align-items: center;
      flex-direction: column;
      padding-bottom: px2rem(12px);
      padding-top: px2rem(30px);

      img {
        width: px2rem(114px);
        height: px2rem(106px);
        object-fit: cover;
      }

      span {
        font-size: px2rem(13px) !important;
        color: rgba($color: #FFFFFF, $alpha: 0.6) !important;
      }
    }

    .member,
    .day-hours {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .member {

      span {
        word-break: break-word;
        max-width: px2rem(80px);
      }
    }
  }
}

.mirror {
  .cmp-agency-reward-income-table {
    table {
      direction: rtl;

      th {
        text-align: right;
      }

      tr {
        &>th:last-child {
          text-align: left;
        }

        &>td:last-child {
          &>div {

            span {
              margin-right: unset;
              margin-left: px2rem(6px);
            }
          }
        }
      }
    }
  }
}