@import '../../../common.scss';

.cmp-guild-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../images/guild-top-bg.png');
  background-size: cover;
  background-repeat: no-repeat;

  // padding-top: px2rem(45px);
  &__content {
    margin-top: px2rem(26px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__avatar {
    width: px2rem(108px);
    height: px2rem(108px);
    border-radius: px2rem(20px);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    &-mask {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../../images/guild-avatar.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &__name {
    height: px2rem(21px);
    margin-top: px2rem(15px);
    font-size: px2rem(18px);
    color: white;
    text-align: center;
  }

  .cmp-vip-progress {
    margin-top: px2rem(12px);
  }

  .cmp-info-card {
    margin-top: px2rem(15px);
  }
}

.cmp-card-agency {
  position: relative;
  width: px2rem(345px);
  height: px2rem(107px);
  padding: px2rem(14px);
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url(~@/images/pane-main.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &__info {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-avatar {
      width: px2rem(57px);
      height: px2rem(57px);
      background-image: url(~@/images/border-avatar.webp);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: px2rem(6px);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      &-inner {
        width: px2rem(55px);
        height: px2rem(55px);
        border-radius: px2rem(6px);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: 100%;
        }
      }


    }

    &-text {
      margin-left: px2rem(9px);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &-name {
      color: white;
      line-height: px2rem(20px);
      font-size: px2rem(14px);
    }

    &-id {
      margin-top: px2rem(4px);
      color: #C1BCBD;
      line-height: px2rem(13px);
      font-size: px2rem(12px);
    }
  }

  &__slogan {
    margin-top: px2rem(7px);
    color: #C1BCBD;
    line-height: px2rem(17px);
    font-size: px2rem(11px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon {
    position: absolute;
    top: px2rem(-10px);
    right: px2rem(10px);
    width: px2rem(93px);
  }
}

.mirror .cmp-card-agency {
  &:before {
    transform: scaleX(-1);
  }

  &__info {
    flex-direction: row-reverse;

    &-text {
      margin-left: unset;
      margin-right: px2rem(9px);
      text-align: right;
    }
  }

  &__slogan {
    text-align: right;
  }

  &__icon {
    left: px2rem(10px);
    right: unset;
  }
}

.cmp-card-member {
  margin-top: px2rem(20px);
  width: px2rem(355px);
  height: px2rem(107px);
  background-image: url(~@/images/pane-sub.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: px2rem(36px);
    padding: 0 px2rem(15px);

    &-title {
      color: white;
    }

    &-count {
      flex: 1;
      text-align: right;
      color: #C1BCBD;
    }
  }

  &__box {
    display: flex;
  }

  &__list {
    display: flex;
    flex: 1;
    overflow-x: scroll;
  }

  &__plus {
    margin: 0 px2rem(19px);
    width: px2rem(41px);
    height: px2rem(41px);
  }
}

.mirror .cmp-card-member {
  &__top {
    flex-direction: row-reverse;

    &-count {
      text-align: left;
    }
  }

  &__box {
    flex-direction: row-reverse;
  }

  &__list {
    flex-direction: row-reverse;
  }
}

.cmp-member {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: px2rem(60px);
  align-items: center;
  margin: 0 px2rem(5px);

  &__top {
    &-title {
      color: white;
      font-size: px2rem(13px);
      line-height: px2rem(19px);
    }
  }

  &__avatar {
    width: px2rem(40px);
    height: px2rem(40px);
    position: relative;

    &-inner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
    }

    img:first-child {
      width: 100%;
    }
  }

  &__icon {
    width: px2rem(16px);
    height: px2rem(16px);
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__name {
    margin-top: px2rem(6px);
    color: #C1BCBD;
    font-size: px2rem(10px);
    line-height: px2rem(12px);
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}