@import '~@/common.scss';

.pg-agent-recharge-coins-transfer-transfer {
    &-top-up-popup__popup {

        &-header {
            height: px2rem(55px);
            line-height: px2rem(55px);
            position: relative;
            border-bottom: px2rem(1px) solid rgba($color: #999, $alpha: 0.4);
            border-top-left-radius: px2rem(12px);
        }

        &-title {
            width: 100%;
            line-height: px2rem(55px);
            font-size: px2rem(18px);
            text-align: center;
            color: #000;
        }

        &-cancel {
            position: absolute;
            font-size: px2rem(15px);
            color: #999;
            right: px2rem(15px);
            top: 0;
        }
    }

    .cmp-transfer-content__cancel {
        display: none;
    }

    .place {
        min-height: px2rem(100px) !important;
    }
}