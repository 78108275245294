@import '~@/common.scss';

.pg-guild-create-advocacy {
    background: #29005D;
    min-height: 100vh;
    padding-bottom: px2rem(20px);
    display: flex;
    flex-direction: column;
}

.mirror .pg-guild-create-advocacy {
    direction: rtl;
}