@import '../../../common.scss';

.cmp-form-item {
  position: relative;
  width: 100%;
  height: px2rem(58px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  &__key {
    font-size: px2rem(16px);
    color: white;
  }
  &__val {
    font-size: px2rem(12px);
    color: white;
    padding-right: px2rem(20px);
  }
  &__arrow {
    position: absolute;
    width: px2rem(20px);
    height: px2rem(20px);
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: px2rem(8px);
      height: px2rem(13px);
      transform: rotateZ(180deg);
    }
  }
  &__popup {
    &-header {
      height: px2rem(55px);
      line-height: px2rem(55px);
      position: relative;
    }
    &-title {
      width: 100%;
      line-height: px2rem(55px);
      font-size: px2rem(18px);
      text-align: center;
      color: #FFFFFF;
    }
    &-confirm {
      position: absolute;
      font-size: px2rem(15px);
      color: #B7B7B7;
      right: px2rem(15px);
      top: 0;
    }
    &-content {
      margin: 0 px2rem(15px) px2rem(15px);
      padding: px2rem(12px);
      background-color: rgba($color: #F0F0F0, $alpha: .1);
      border-radius: px2rem(6px);
    }
    &-textarea {
      height: px2rem(97px);
      .adm-text-area-element {
        color: #999999;
        &::placeholder {
          color: #999999;
        }
      }
    }
    &-max {
      height: px2rem(15px);
      line-height: px2rem(15px);
      margin-top: px2rem(12px);
      text-align: right;
      font-size: px2rem(11px);
      color: #B7B7B7;
    }
  }
}
.cmp-form-item-popup{
  & .adm-popup-body-position-bottom{
    border-radius: px2rem(10px) px2rem(10px) 0 0;
    
  }
}