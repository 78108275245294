@import '../../../common.scss';

.cmp-withdraw {
  display: flex;
  flex-direction: column;
  height: px2rem(460px);
  &__header {
    height: px2rem(55px);
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: px2rem(1px);
      background-color: rgba($color: #999999, $alpha: .2);
    }
  }
  &__title {
    width: 100%;
    height: px2rem(55px);
    line-height: px2rem(55px);
    text-align: center;
    font-size: px2rem(18px);
    color: white;
  }
  &__close {
    width: px2rem(40px);
    height: px2rem(55px);
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: px2rem(17px);
      transform: rotateZ(-90deg);
    }
  }
  &__tab {
    margin: px2rem(15px) px2rem(15px) 0;
    font-size: px2rem(16px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      .tab-item {
        &__name {
          padding: px2rem(6px) px2rem(14px);
          color: rgba($color: #FFFFFF, $alpha: .6);
        }
      }
      &.selected {
        color: #10EBBE;
        border: solid px2rem(2px) #10EBBE;
        border-radius: px2rem(20px);
        .tab-item {
          &__name {
            color: #10EBBE;
          }
        }
      }
    }
  }
  &__bar {
    margin: px2rem(30px) px2rem(15px) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: px2rem(14px);
    &-left {
      display: flex;
      align-items: center;
    }
    &-box {
      width: px2rem(5px);
      height: px2rem(12px);
      border-radius: px2rem(3px);
      background: linear-gradient(to bottom, #1FF5A9, #00E0D5);
    }
    &-total {
      margin-left: px2rem(5px);
    }
    &-right {
      .time {
        padding: px2rem(2px) px2rem(10px);
        color: #B1ADB1;
        border: solid px2rem(1px) #B1ADB1;
        border-radius: px2rem(12px);
        font-size: px2rem(14px);
        img {
          width: px2rem(11px);
          margin-left: px2rem(6px);
          margin-bottom: px2rem(2px);
        }
      }
    }
  }
  &__list {
    flex: 1;
    margin: px2rem(11px) px2rem(15px) 0;
    color: rgba($color: #FFFFFF, $alpha: .6);
    overflow: scroll;
    .card {
      font-size: px2rem(13px);
      margin-bottom: px2rem(15px);
      background-color: rgba($color: #ffffff, $alpha: .1);
      border-radius: px2rem(6px);
      &__info {
        display: flex;
        justify-content: space-between;
        height: px2rem(75px);
        box-sizing: border-box;
        padding: px2rem(10px) px2rem(10px) px2rem(15px);
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: px2rem(1px);
          background-color: rgba($color: #999999, $alpha: .2);
        }
        &-left {
          width: 50%;
        }
        &-right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-end;
        }
        .info {
          display: flex;
          &__avatar {
            width: px2rem(50px);
            height: px2rem(50px);
            margin-right: 10px;
            border-radius: 50%;
          }
          &__basic {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            &-nick {
              color: white;
              white-space: nowrap;
            }
          }
          &__status {
            color: #10EBBE;
            &.done {
              color: #FFC400;
            }
            &.fail {
              color: #FF5C5C;
            }
          }
        }
      }
      &__gem {
        display: flex;
        justify-content: center;
        align-items: center;
        height: px2rem(50px);
        color: rgba($color: #FFFFFF, $alpha: .6);
        span {
          color: white;
        }
      }
    }
  }
}