@import '~@/common.scss';

.cmp-guild-invite-data-rank-list-item {
    width: 100%;
    max-height: px2rem(436px);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: px2rem(6px);
    padding: 0 px2rem(10px);
    box-sizing: border-box;
    overflow-y: auto;

    &__item {
        width: 100%;
        height: px2rem(70px);
        display: flex;
        align-items: center;
        font-size: px2rem(16px);
        color: #fff;
        position: relative;

        &:not(&:last-child) {
            border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.1);
        }

        &>.top1 {
            background-image: url('~@/images/guild-top1-icon.webp');

            &>span {
                display: none;
            }
        }

        &>.top2 {
            background-image: url('~@/images/guild-top2-icon.webp');

            &>span {
                display: none;
            }
        }

        &>.top3 {
            background-image: url('~@/images/guild-top3-icon.webp');

            &>span {
                display: none;
            }
        }

        &-sort {
            width: px2rem(30px);
            height: px2rem(26px);
            display: flex;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-avatar {
            width: px2rem(50px);
            height: px2rem(50px);
            border: px2rem(1px) solid rgba(255, 194, 35, 1);
            border-radius: 50%;
            overflow: hidden;
            margin-left: px2rem(10px);
            margin-right: px2rem(15px);
            flex-shrink: 0;

            &>img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-nick {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-num {
            margin-left: auto;
            display: flex;
            align-items: center;

            &>span {
                font-size: px2rem(14px);
                font-weight: 500;
            }

            &>img {
                width: px2rem(13px);
                height: px2rem(13px);
                object-fit: cover;
                margin: 0 px2rem(5px);
            }
        }
    }
}

.mirror .cmp-guild-invite-data-rank-list-item {
    direction: rtl;

    &__item {
        &-num {
            margin-left: unset;
            margin-right: auto;
        }

        &-avatar {
            margin-left: px2rem(15px);
            margin-right: px2rem(10px);
        }
    }
}