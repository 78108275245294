@import '~@/common.scss';

.pg-wallet-anchorman-detail {
    min-height: 100vh;
    background: #FAFAFA;
    padding-top: px2rem(45px);
    box-sizing: border-box;
    padding-bottom: px2rem(30px);
    position: relative;

    .walletDetailBgPenetration {
        background-position: center bottom px2rem(58px);
    }

    &>.content {
        padding: 0 px2rem(11px);

        &>.card2 {
            z-index: 2;
            position: relative;
            margin-top: px2rem(-80px);
            height: px2rem(174px);
            background-image: url('~@/images/wallet/agency-gem-cash-card2.webp');
            padding-top: px2rem(80px);
        }

        &>.card3 {
            position: relative;
            margin-top: px2rem(-30px);
            background-image: url('~@/images/wallet/agency-gem-cash-card3.webp');
            padding-top: px2rem(30px);
            padding-bottom: px2rem(30px);
            background-size: 100% 100%;

            .content {
                position: absolute;
                right: px2rem(14px);
                top: px2rem(13px);
            }

            .extra-content {
                margin-top: px2rem(20px);
            }
        }

        &>.card4 {
            margin-top: px2rem(-50px);
            // height: px2rem(281px);
            background-image: url('~@/images/wallet/agency-gem-cash-card4.webp');
            padding-top: px2rem(50px);
            padding-bottom: px2rem(20px);

            .read-more {
                color: #FFFFFF;
                text-decoration: underline;
                white-space: nowrap;
                font-weight: 500;
                font-size: px2rem(13px);
            }

            .extra-content {
                margin-top: px2rem(15px);

                .tips {
                    display: flex;
                    flex-direction: column;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-bottom: px2rem(15px);
                    font-size: px2rem(13px);

                    div {
                        margin-bottom: px2rem(8px);

                        &:last-child {
                            display: flex;
                            align-items: center;
                        }
                    }

                    img {
                        width: px2rem(15px);
                        height: px2rem(15px);
                        object-fit: cover;
                        margin: 0 px2rem(8px);
                    }
                }

                .cmp-award-progress {
                    padding: 0;
                }
            }
        }
    }

    .card1 {
        position: relative;
        z-index: 3;
        width: px2rem(353px);
        // height: px2rem(222px);
        background-image: url('~@/images/wallet/agency-gem-cash-card1.webp');
        margin: 0 auto;
        margin-top: px2rem(-30px);
        padding-bottom: px2rem(30px);

        .extra-content {
            margin-top: px2rem(10px);
            background-color: rgba(0, 61, 59, 0.2);
            border-radius: px2rem(4px);
            padding: px2rem(10px);
            font-size: px2rem(11px);
            color: #fff;
            overflow-wrap: break-word;
        }
    }

    .popup-pane {
        margin-top: px2rem(15px);

        .cmp-pane__title {
            margin: 0;
            font-size: px2rem(14px);
            font-weight: 500;
            color: #999999;
            margin-bottom: px2rem(15px);
        }
    }

    .bottom-btn {
        all: unset;
        width: 100%;
        height: px2rem(38px);
        border-radius: px2rem(20px);
        background: rgba(240, 240, 240, 1);
        text-align: center;
        line-height: px2rem(38px);
        font-size: px2rem(14px);
        font-weight: 500;
        color: #B7B7B7;
        margin-top: px2rem(30px);
        margin-bottom: px2rem(20px);
    }

    .btn-blue {
        color: #FFFFFF;
        background: linear-gradient(to bottom, rgba(31, 245, 169, 1), rgba(0, 224, 213, 1));
    }

    .swiper__content {
        .check-tips {
            margin-top: px2rem(20px);
            font-size: px2rem(12px);
            color: #999999;
            line-height: px2rem(17px);
            display: flex;
            flex-direction: column;
        }
    }
}

.mirror .pg-wallet-anchorman-detail {
    .card3 {
        .content {
            right: unset;
            left: px2rem(14px);
        }
    }

    .cmp-pane {
        direction: rtl;

        &__title {
            &::before {
                left: unset;
                right: px2rem(-10px);
            }
        }
    }
}