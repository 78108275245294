@import '~@/common.scss';

.cmp-agency-reward-user-info {
    width: 100%;

    .cmp-member-card {
        padding: 0 px2rem(10px);
        background-color: unset;
        margin: 0;
        height: px2rem(75px);

        &__info {
            padding-left: unset;

            &-avatar {
                flex-shrink: 0;
                width: px2rem(50px);
                height: px2rem(50px);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &-basic {
                flex: unset;
            }

            &-name {
                margin-bottom: px2rem(8px);
                max-width: px2rem(110px);
                display: -webkit-box;
                word-break: break-word;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &-No {
                font-size: px2rem(12px);
            }


        }
    }

    .cmp-guild-wallet-data-calculate-data {

        &>.content {
            width: 100%;
            min-height: px2rem(60px);
            justify-content: space-around;

            &>.item {
                min-height: px2rem(60px);
                border-radius: px2rem(4px);
                width: px2rem(65px);
                flex: unset;
                padding: 0;
                box-sizing: unset;
                padding-top: px2rem(5px);

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    &::after {
                        content: '';
                        position: absolute;
                        right: px2rem(-25px);
                        top: px2rem(5px);
                        font-size: px2rem(16px);
                    }
                }

                &:nth-child(1) {
                    &::after {
                        content: '=';
                    }
                }

                &:nth-child(2) {
                    &::after {
                        content: 'x';
                    }
                }

                &:nth-child(3) {
                    &::after {
                        content: '';
                    }
                }
            }

            &>.item:not(:first-child) {
                background-color: rgba(0, 0, 0, 0.20);
            }

            &>.item:last-child {
                width: px2rem(85px);
            }
        }
    }

    &>.content {
        width: 100%;
        padding: 0 px2rem(15px);
        box-sizing: border-box;

        &>.user {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: px2rem(6px);
            margin-top: px2rem(15px);
            padding-bottom: px2rem(15px);
            position: relative;

            &>.tips {
                font-size: px2rem(11px);
                font-weight: 500;
                color: rgba($color: #fff, $alpha: 0.6);
                padding: 0 px2rem(10px);
                margin-top: px2rem(10px);
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                &>span:first-child {
                    white-space: nowrap;
                }

                &>span:last-child {
                    color: #FFFFFF;
                    text-decoration: underline;
                    white-space: nowrap;
                }
            }
        }
    }
}

.mirror .cmp-agency-reward-user-info {
    .cmp-member-card {
        &__info {
            .grade {
                direction: rtl;
            }
        }
    }

    .cmp-guild-wallet-data-calculate-data {
        &>.content {
            &>.item {

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    &::after {
                        right: unset;
                        left: px2rem(-25px);
                    }
                }
            }

        }
    }

    &>.content {
        &>.user {
            &>.tips {
                flex-direction: row-reverse;
            }
        }
    }
}