@import '~@/common.scss';

.cmp-transfer-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  height: px2rem(460px);

  .cmp-pane {
    margin: px2rem(30px) px2rem(15px) 0;

    .cmp-pane:first-child {
      margin: px2rem(15px) px2rem(15px) 0;
    }

    &__title {
      margin-left: 0;
      margin-right: 0;
      font-size: px2rem(14px);
      font-weight: 500;
      color: #333333;
    }
  }

  &__input,
  &__pay {
    width: px2rem(345px);
    height: px2rem(55px);
    border-radius: px2rem(6px);
    display: flex;
    align-items: center;
  }

  &__input {
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 px2rem(11px);
    box-sizing: border-box;

    &-inp {
      flex-grow: 1;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        left: 0;
        width: px2rem(16px);
        height: px2rem(16px);
        background-image: url('~@/images/guild-HistoricalDataSearch-icon.png');
        background-repeat: no-repeat;
        background-size: cover;
      }

      input {
        all: unset;
        flex: 1;
        margin-left: px2rem(6px);
      }
    }

  }

  &__confirm {
    flex-shrink: 0;
    width: px2rem(73px);
    height: px2rem(30px);
    color: white;
    display: flex;
    align-items: center;
    border-radius: px2rem(30px);
    justify-content: center;
    background: linear-gradient(to right, #1FF5A9, #00E0D5);
  }

  .user-input {
    .tips {
      display: block;
      font-size: px2rem(12px);
      font-weight: 400;
      color: #999999;
      margin-top: px2rem(15px);
    }
  }

  &__user {
    flex: 1;
    display: flex;
    align-items: center;

    &-avatar {
      width: px2rem(35px);
      height: px2rem(35px);
      border-radius: 50%;
      object-fit: cover;
      flex-shrink: 0;
    }

    &-info {
      margin-left: px2rem(10px);
      font-size: px2rem(13px);
      color: #000000;
      width: px2rem(250px);
      overflow: hidden;
      display: flex;
      flex-direction: column;

      &-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }



  &__cancel {
    width: px2rem(22px);
    height: px2rem(22px);
    background: transparent url('~@/images/close.png') no-repeat;
    background-size: cover;
  }

  &__pay {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border: solid px2rem(1px) #EEEEEE;

    &-icon {
      height: px2rem(20px);
      margin-left: px2rem(15px);
    }
  }

  &__coin {
    margin-top: px2rem(15px);
    display: grid;
    width: 100%;
    grid-template-columns: px2rem(108px) px2rem(108px) px2rem(108px);
    grid-gap: px2rem(10px) px2rem(11px);

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: px2rem(115px);
      border-radius: px2rem(10px);
      border: solid px2rem(1px) #E8E8E8;
      background-color: white;

      &.selected {
        border-color: #10EBBE;
        background-color: #E1FFF9;
      }
    }

    &-icon {
      width: px2rem(30px);
      height: px2rem(30px);
      margin-top: px2rem(15px);
    }

    &-num {
      font-size: px2rem(15px);
      color: #FFAF00;
      margin-top: px2rem(10px);
      font-weight: 400;
    }

    &-price {
      font-size: px2rem(12px);
      color: #B7B7B7;
      margin-top: px2rem(10px);
    }
  }

  .place {
    flex-grow: 1;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 px2rem(15px);
    box-sizing: border-box;

    .info-box {
      display: flex;
      flex-direction: column;

      .info-tip {
        font-size: px2rem(12px);
        font-weight: 400;
        color: #999999;
        line-height: px2rem(17px);
      }

    }


    &-confirm {
      all: unset;
      border-radius: px2rem(20px);
      margin: px2rem(15px) auto 0;
      background: linear-gradient(to right, #1FF5A9, #00E0D5);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: px2rem(14px);
      color: white;
      width: px2rem(345px);
      height: px2rem(38px);
      font-weight: 500;
    }

    .transfer-loading {
      background: rgba(240, 240, 240, 1);
      color: #B7B7B7;
      pointer-events: none;
      touch-action: none;
    }
  }

  .bottom-clsx {
    margin-bottom: px2rem(21px);
  }

  &__balance {
    width: 100%;
    height: px2rem(91px);
    display: flex;
    align-items: center;
    position: relative;

    .bg {
      position: absolute;
      width: 100%;
      height: px2rem(91px);
      background-image: url('~@/images/ThirdRecharge-iconBg.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .icon {
      z-index: 2;
      width: px2rem(40px);
      height: px2rem(40px);
      object-fit: cover;
      margin-left: px2rem(20px);
      margin-right: px2rem(10px);
    }

    .num {
      z-index: 2;
      font-size: px2rem(25px);
      font-weight: bold;
      color: #FFFFFF;
      line-height: 30px;
      text-shadow: #BF9400 px2rem(1px) 0 0, #BF9400 0 px2rem(1px) 0, #BF9400 px2rem(-1px) 0 0, #BF9400 0 px2rem(-1px) 0;
    }
  }

  &__amount {
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    padding: 0 px2rem(11px);
    box-sizing: border-box;
    width: px2rem(345px);
    height: px2rem(55px);
    border-radius: px2rem(6px);

    &::before {
      content: '';
      left: px2rem(15px);
      width: px2rem(16px);
      height: px2rem(16px);
      background-image: url('~@/images/coin.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    input {
      all: unset;
      flex: 1;
      margin-left: px2rem(6px);
      font-size: px2rem(16px);
    }
  }
}

.mirror .cmp-transfer-content {
  .isAr {
    direction: rtl;
  }

  &__input {
    flex-direction: row-reverse;

    &-inp {
      flex-direction: row-reverse;

      input {
        direction: rtl;
        margin-left: unset;
        margin-right: px2rem(6px);
      }
    }
  }

  .user-input {
    .tips {
      direction: rtl;
    }
  }

  &__user {
    flex-direction: row-reverse;

    &-info {
      margin-left: unset;
      margin-right: px2rem(10px);
      text-align: right;
    }
  }

  &__pay {
    flex-direction: row-reverse;

    &-icon {
      margin-left: unset;
      margin-right: px2rem(15px);
    }
  }

  &__coin {
    direction: rtl;
  }

  &__amount {
    flex-direction: row-reverse;

    &::before {
      left: unset;
      right: px2rem(15px);
    }

    input {
      direction: rtl;
      margin-left: unset;
      margin-right: px2rem(6px);
    }
  }

  &__bottom {
    .info-tip {
      direction: rtl;
    }
  }

}