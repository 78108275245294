@import '../../../common.scss';

.cmp-message-card {
  height: px2rem(119px);
  &__actions {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: px2rem(303px);
      height: px2rem(1px);
      background-color: rgba($color: #999999, $alpha: 0.2);
      right: 0;
      bottom: px2rem(-46px);
    }
  }
  &__btn {
    &-reject,
    &-consent {
      position: absolute;
      width: px2rem(110px);
      height: px2rem(30px);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: px2rem(23px);
    }
    &-reject {
      left: px2rem(72px);
      background-color: rgba($color: #f0f0f0, $alpha: 0.4);
    }
    &-consent {
      left: px2rem(232px);
      background: linear-gradient(to right, #1ff5a9, #00e0d5);
    }
  }
  .cmp-member-card__info:after {
    content: none;
  }
}
.mirror .cmp-message-card {
  &__actions {
    &:after {
      right: unset;
      left: 0;
    }
  }
  &__btn {
    &-reject {
      left: unset;
      right: px2rem(72px);
    }
    &-consent {
      left: unset;
      right: px2rem(232px);
    }
  }
}
