@import '~@/common.scss';

.wallet-select-type {
    margin-top: px2rem(20px);
    height: px2rem(24px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: px2rem(6px);
    border: 1px solid #EEEEEE;
    padding: 0 px2rem(10px);

    &__name {
        color: #000;
        font-size: px2rem(12px);
        flex: 1;
        text-align: center;
    }

    &__delta {
        width: px2rem(6px);
        height: px2rem(5px);
        margin-left: px2rem(4px);
    }

    &__popup {

        &-header {
            height: px2rem(53px);
            line-height: px2rem(53px);
            border-bottom: px2rem(1px) solid rgba($color: #999, $alpha: 0.4);
            display: flex;
            justify-content: space-between;
            padding: 0 px2rem(15px);
        }


        &-cancel {
            font-size: px2rem(15px);
            color: #999;
            right: px2rem(15px);
            top: 0;
        }

        &-confirm {
            font-size: 15px;
            font-weight: 400;
            color: #10EBBE;
        }

        &-content {
            padding: 0 px2rem(15px);
            padding-top: px2rem(15px);
        }
    }
}

.mirror .wallet-select-type {
    &__delta {
        margin-right: px2rem(4px);
        margin-left: unset;
    }

    &__popup {

        &-header {
            direction: rtl;
        }
    }
}