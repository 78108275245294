@import '../../common.scss';

.pg-recharge {
  padding-bottom: px2rem(58px);
  background-color: #FAFAFA;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  .cmp-pane {
    margin: px2rem(20px) px2rem(15px) 0;
    &__title {
      margin-left: 0;
      margin-right: 0;
      color: #333333;
    }
  }
  &__input, &__pay {
    width: px2rem(345px);
    height: px2rem(55px);
    border-radius: px2rem(6px);
    display: flex;
    align-items: center;
  }
  .first .cmp-pane {
    &__title {
      color: #999999;
      font-size: px2rem(14px);
    }
  }
  &__input {
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    padding: 0 px2rem(15px);
    box-sizing: border-box;
    input {
      background-color: transparent;
      border: none;
      flex: 1;
    }
  }
  &__user {
    flex: 1;
    display: flex;
    align-items: center;
    &-avatar {
      width: px2rem(35px);
      height: px2rem(35px);
      border-radius: 50%;
    }
    &-name {
      font-size: px2rem(13px);
      color: #000000;
      margin-left: px2rem(10px);
    }
  }
  &__confirm, &__cancel {
    width: px2rem(73px);
    height: px2rem(30px);
    color: white;
    display: flex;
    align-items: center;
    border-radius: px2rem(30px);
    justify-content: center;
  }
  &__confirm {
    background: linear-gradient(to right, #1FF5A9, #00E0D5);
  }
  &__cancel {
    background-color: #CECECE;
  }
  &__pay {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border: solid px2rem(1px) #EEEEEE;
    &-icon {
      height: px2rem(20px);
      margin-left: px2rem(15px);
    }
  }
  &__coin {
    margin-top: px2rem(15px);
    display: grid;
    width: 100%;
    grid-template-columns: px2rem(108px) px2rem(108px) px2rem(108px);
    grid-gap: px2rem(10px) px2rem(11px);
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: px2rem(115px);
      border-radius: px2rem(10px);
      border: solid px2rem(1px) #E8E8E8;
      background-color: white;
      &.selected {
        border-color: #10EBBE;
        background-color: #E1FFF9;
      }
    }
    &-icon {
      width: px2rem(30px);
      height: px2rem(30px);
      margin-top: px2rem(15px);
    }
    &-num {
      font-size: px2rem(15px);
      color: #FFAF00;
      margin-top: px2rem(10px);
      font-weight: 400;
    }
    &-price {
      font-size: px2rem(12px);
      color: #B7B7B7;
      margin-top: px2rem(10px);
    }
  }
  &__bottom {
    position: fixed;
    z-index: 10;
    text-align: center;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  &__btn {
    border-radius: px2rem(20px);
    margin: 0 auto;
    background: linear-gradient(to right, #1FF5A9, #00E0D5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: px2rem(14px);
    color: white;
    width: px2rem(345px);
    height: px2rem(38px);
  }
}

.mirror {
  .pg-recharge {
    &__input {
      flex-direction: row-reverse;
      input {
        text-align: right;
      }
    }
    &__user {
      flex-direction: row-reverse;
      &-name {
        margin-left: unset;
        margin-right: px2rem(10px);
      }
    }
    &__pay {
      flex-direction: row-reverse;
      &-icon {
        margin-left: unset;
        margin-right: px2rem(15px);
      }
    }
    &__coin {
      direction: rtl;
    }
  }
}