@import '~@/common.scss';

.cmp-lovecard-header {
  width: 100%;
  height: px2rem(88px);
  background: linear-gradient(to right, #1FF5A9, #00E1D6);
  position: fixed;
  top: 0;
  font-size: px2rem(18px);
  z-index: 1000;

  &__line {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: px2rem(44px);
    color: white;

  }

  &__back {
    position: absolute;
    width: px2rem(16px);
    height: px2rem(16px);
    object-fit: cover;
    top: px2rem(14px);
    left: px2rem(15px);
  }
}

.mirror {
  .cmp-lovecard-header {
    &__back {
      left: unset;
      right: px2rem(15px);
      transform: rotateZ(180deg);
    }
  }
}