@import '~@/common.scss';

.cmp-agency-reward-grade {
    display: flex;
    align-items: center;
    font-size: px2rem(12px);
    font-weight: 600;
    color: #10EBBE;
    margin: 0 px2rem(10px);

    &>.lv,
    &>.rate {
        flex-shrink: 0;
        border: px2rem(1px) solid rgba(16, 235, 190, 1);
        border-radius: px2rem(11px);
        padding: px2rem(3px) px2rem(14px);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
    }

    &>.rate {
        margin: 0 px2rem(6px);
    }

    img {
        width: px2rem(30px);
        height: px2rem(15px);
        object-fit: cover;
    }
}

.mirror .cmp-agency-reward-grade {
    direction: rtl;
}