@import '~@/common.scss';

.cmp-agent-recharge-status-user-info {
    &>.info {
        box-sizing: border-box;
        width: px2rem(345px);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;

        .user,
        .coin {
            width: 100%;
            height: px2rem(55px);
            background: #F3F3F3;
            display: flex;
            align-items: center;
            border-radius: px2rem(6px);
            padding: 0 px2rem(10px);
            box-sizing: border-box;
        }

        .user {
            img {
                width: px2rem(35px);
                height: px2rem(35px);
                border-radius: 50%;
                object-fit: cover;
                flex-shrink: 0;
            }

            .detail-info {
                margin-left: px2rem(10px);
                font-size: px2rem(13px);
                color: #000000;
                width: px2rem(280px);
                overflow: hidden;
                display: flex;
                flex-direction: column;

                .name {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .coin {
            margin-top: px2rem(15px);
            padding: 0 px2rem(15px);
            font-size: px2rem(13px);
            color: #000000;

            .img {
                width: px2rem(16px);
                height: px2rem(16px);
                border-radius: 50%;
                object-fit: cover;
                flex-shrink: 0;
                margin-right: px2rem(6px);
                background: url('~@/images/coin.png') no-repeat;
                background-size: cover;
            }
        }
    }
}

.mirror .cmp-agent-recharge-status-user-info {
    .isAr {
        direction: rtl;
    }

    &>.info {

        .user,
        .coin {
            flex-direction: row-reverse;
        }

        .user {
            .detail-info {
                align-items: flex-end;
                margin-left: unset;
                margin-right: px2rem(10px);
            }
        }

        .coin {

            .img {
                margin-right: unset;
                margin-left: px2rem(6px);
            }
        }

    }
}