@import '~@/common.scss';

$prefix-cls: 'cmp-guild-create-advocacy-third';

.#{$prefix-cls} {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &>main {
        padding: 0 px2rem(15px);
        display: flex;
        flex-direction: column;
    }

    .cmp-pane {
        &__title {
            color: #fff;
            margin: px2rem(15px) 0;

            &::before {
                background: linear-gradient(to bottom, #F5FF00, #F7652F);
            }
        }
    }

    &__interests {
        color: #A999BE;
        border-radius: px2rem(6px);
        padding: px2rem(15px);
        background-color: rgba($color: #fff, $alpha: 0.16);
        display: flex;
        flex-direction: column;
        margin-bottom: px2rem(15px);

        &-title {
            color: #FFFFFF;
            margin-bottom: px2rem(10px);
        }

        &-line {
            height: px2rem(2px);
            background: rgba($color: #D8D8D8, $alpha: 0.1);
            margin: px2rem(15px) 0;
        }

        &-info {
            display: flex;
            align-items: flex-start;
            margin-bottom: px2rem(10px);

            &.mb-unset {
                margin-bottom: unset;
            }

            &::before {
                content: '-';
                display: block;
            }
        }
    }

    &__bold {
        margin: 0 px2rem(5px);

        &>span {
            font-weight: bold;
        }
    }

    &__no-margin {
        margin: unset;
    }

    &__tips {
        color: #A999BE;
        margin-bottom: px2rem(10px);

        &>span {
            color: #fff;
            font-weight: bold;
        }

        .pg-guild-create-advocacy-third__bold {
            margin: unset;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: px2rem(46px);
        width: 100%;
        padding: 0 px2rem(15px);
        box-sizing: border-box;
        margin-top: auto;
        margin-bottom: px2rem(20px);

        &>span {
            width: px2rem(13px);
        }

        &>div {
            flex: 1;
            border-radius: px2rem(22px);
            font-weight: bold;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
            border: px2rem(1px) solid #F9AF4A;
            box-sizing: border-box;
            height: 100%;

            &:first-child {
                opacity: 0.6;
            }
        }
    }
}

.mirror .#{$prefix-cls} {
    direction: rtl;
}