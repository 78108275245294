@import '~@/common.scss';

.pg-guild-agency-reward {
    background-color: black;
    background-image: url('~@/images/guild-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: px2rem(30px);
    box-sizing: border-box;

    .cmp-guild-tab-buttons {
        margin: px2rem(15px) 0 0;
        padding: 0 px2rem(20px);
        box-sizing: border-box;

        &__tab-item {
            padding: px2rem(6px) px2rem(20px);
        }
    }

    &__header-img {
        position: absolute;
        bottom: 0;
        width: px2rem(45px);
        height: px2rem(45px);
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;

        img {
            width: px2rem(16px);
            height: px2rem(16px);
            object-fit: cover;
        }
    }

    &__member-list {
        &-header {
            height: px2rem(55px);
            position: relative;
            border-bottom: rgba(153, 153, 153, 0.2) px2rem(1px) solid;
        }

        &-title {
            width: 100%;
            height: px2rem(55px);
            line-height: px2rem(55px);
            text-align: center;
            font-size: px2rem(18px);
            color: white;
        }

        &-close {
            width: px2rem(40px);
            height: px2rem(55px);
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: px2rem(10px);
                height: px2rem(17px);
                transform: rotateZ(-90deg);
            }

            &-down {
                width: px2rem(17px) !important;
                height: px2rem(10px) !important;
                transform: rotateZ(0deg) !important;
            }
        }

        &-content {
            height: px2rem(405px);
            overflow-x: hidden;
            overflow-y: auto;

            &__loading {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: px2rem(20px);
            }

            .cmp-member-card {
                background-color: transparent;

                &__info {
                    padding: px2rem(15px) 0;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        width: px2rem(303px);
                        height: px2rem(1px);
                        background-color: rgba($color: #999999, $alpha: 0.2);
                        left: px2rem(60px);
                        bottom: 0;
                    }
                }

                &__right-btn {
                    all: unset;
                    color: #fff;
                }
            }
        }



        & .adm-popup-body.adm-popup-body-position-bottom {
            border-radius: px2rem(15px) px2rem(15px) 0 0;
        }
    }

    .cmp-agency-reward-user-info {
        margin-bottom: px2rem(15px);
    }

    .cmp-pane {
        &__title {
            margin: 0;
            margin-bottom: px2rem(15px);
        }
    }

    .cmp-number-card2 {
        width: px2rem(345px);
        background: none;
        margin-bottom: px2rem(15px);

        &__item {
            background-color: rgba(255, 255, 255, 0.1);

            &::after {
                all: unset;
            }
        }
    }

    &__level {
        width: 100%;
        padding: 0 px2rem(15px);
        box-sizing: border-box;
    }
}

.mirror .pg-guild-agency-reward {

    &__header-img {
        right: unset;
        left: 0;
    }

    .cmp-number-card2 {
        &__item:first-child {
            margin-right: unset;
            margin-left: px2rem(8px);
        }
    }
}