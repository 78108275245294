@import '~@/common.scss';

.pg-wallet-cash-to-coins {
    background-color: #fff;
    padding-bottom: unset;

    .cmp-pane {

        &__title {
            margin: px2rem(15px) 0;
            font-size: px2rem(14px);
            font-weight: 500;
            color: #333333;
        }
    }

    .cmp-wallet-banner {
        background-image: url('~@/images/wallet/withdraw-banner-bg.webp');

        &>.info {
            .img {
                background-image: url('~@/images/money.webp');
            }

            span {
                @include text-stroke('#00BF37', px2rem(1px));
            }
        }
    }

    .wallet-coin-exchange-select {
        &__coin {
            &-box {
                &>span {
                    color: #FFAF00;
                }
            }
        }
    }

    &__calculate {
        display: flex;
        align-items: center;
        margin-top: px2rem(15px);
    }

    &__max-box {
        background-color: #F3F3F3;
        display: flex;
        align-items: center;
        padding: px2rem(4px) px2rem(4px) px2rem(4px) px2rem(10px);
        box-sizing: border-box;
        width: px2rem(235px);
        height: px2rem(38px);
        border-radius: px2rem(19px);

        &::before {
            content: '';
            left: px2rem(15px);
            width: px2rem(16px);
            height: px2rem(16px);
            background-image: url('~@/images/money.webp');
            background-repeat: no-repeat;
            background-size: cover;
        }

        &>input {
            all: unset;
            flex: 1;
            margin-left: px2rem(6px);
            font-size: px2rem(11px);
        }


    }

    &__gold {
        display: flex;
        align-items: center;
        font-size: px2rem(15px);

        &>span {
            font-weight: bold;
            color: #B7B7B7;
            margin-left: px2rem(10px);
        }
    }

    &__coin {
        display: flex;
        align-items: center;

        &>img {
            width: px2rem(18px);
            height: px2rem(18px);
            object-fit: cover;
            margin: 0 px2rem(4px);
        }

        &>span {
            color: #000000;
            word-break: break-word;
        }
    }

    &__tips {
        font-size: px2rem(12px);
        color: #999999;
        margin-top: px2rem(15px);

        &-item {
            display: flex;
            align-items: center;
        }

        &-gold {
            display: flex;
            align-items: center;

            &>img {
                width: px2rem(12px);
                height: px2rem(12px);
                object-fit: cover;
                margin: 0 px2rem(4px);
            }

            &>span {
                color: #000000;
            }
        }
    }

    &__btn {
        all: unset;
        width: 100%;
        height: px2rem(38px);
        border-radius: px2rem(20px);
        margin-top: auto;
        margin-bottom: px2rem(20px);
    }

    .place {
        min-height: px2rem(35px);
    }

    &-popup {
        &-header {
            height: px2rem(55px);
            position: relative;
            border-bottom: px2rem(1px) solid rgba($color: #999, $alpha: 0.4);
            border-top-left-radius: px2rem(12px);
            display: flex;
            align-items: center;
        }

        &-title {
            width: 100%;
            font-size: px2rem(18px);
            text-align: center;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;

            span:first-child {
                margin: 0 px2rem(8px);
            }
        }

        &-cancel {
            position: absolute;
            font-size: px2rem(15px);
            color: #999;
            right: px2rem(15px);
            top: 50%;
            transform: translateY(-50%);
        }


        &-content {
            padding: 0 px2rem(15px);
        }
    }

    .swiper__content {
        .check-tips {
            margin-top: px2rem(20px);
            font-size: px2rem(12px);
            color: #999999;
            line-height: px2rem(17px);
            display: flex;
            flex-direction: column;
            margin-bottom: px2rem(30px);
        }

        .cmp-pane {
            margin-top: px2rem(15px);

            &__title {
                margin: 0;
                font-size: px2rem(14px);
                font-weight: 500;
                color: #999999;
                margin-bottom: px2rem(15px);
            }
        }

    }
}

.mirror .pg-wallet-cash-to-coins {
    direction: rtl;

    &__gold {
        &>span {
            margin-left: unset;
            margin-right: px2rem(10px);
        }
    }

    &__tips {
        &-item {
            direction: ltr;
            justify-content: flex-end;
        }
    }

    &__max-box {
        &>input {
            margin-left: unset;
            margin-right: px2rem(6px);
        }
    }
}