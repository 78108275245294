@import '~@/common.scss';

.cmp-guild-agency-reward-rule-table {

    &>table {
        border: solid px2rem(1px) rgba(254, 215, 169, 0.4);
        border-radius: px2rem(6px);
        width: 100%;
        color: #fff;
        font-size: px2rem(12px);

        tr {
            line-height: px2rem(44px);

            &:last-child {

                td {
                    border-bottom: none;
                }
            }
        }

        tbody tr {
            td:first-child {
                &>div {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    // position: relative;

                    // iPhone8定位无效，跑到页面最左上角了
                    // &::after {
                    //     content: '';
                    //     position: absolute;
                    //     width: px2rem(43px);
                    //     height: px2rem(43px);
                    //     background: url('~@/images/now-icon.webp') no-repeat;
                    //     background-size: cover;
                    //     left: 0;
                    //     top: 0;
                    //     opacity: 0;
                    // }
                    &>img {
                        width: px2rem(43px);
                        height: px2rem(43px);
                        object-fit: cover;
                        align-self: flex-start;
                        opacity: 0;
                    }

                    &>span {
                        align-self: center;
                    }
                }
            }
        }

        th {
            text-align: center;
            padding: px2rem(4px) 0;
            font-size: px2rem(14px);
            line-height: px2rem(17px);
        }

        td,
        th {
            width: 33.3%;
            border-right: solid px2rem(1px) rgba(254, 215, 169, 0.4);
            border-bottom: solid px2rem(1px) rgba(254, 215, 169, 0.4);
            text-align: center;

            &:last-child {
                border-right: none;
            }

            &.lv-box {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: px2rem(12px);
                color: #10EBBE !important;
            }
        }

        img {
            width: px2rem(27px);
            height: px2rem(13px);
            object-fit: cover;
        }

        .selected {
            background-color: rgba(16, 235, 190, 0.30);

            td:first-child {
                &>div {

                    // &::after {
                    //     opacity: 1;
                    // }
                    &>img {
                        opacity: 1;
                    }
                }

            }


            td {
                border-top: px2rem(1px) solid rgba(16, 235, 190, 1);
                border-bottom: px2rem(1px) solid rgba(16, 235, 190, 1) !important;

                &:first-child {
                    border-left: px2rem(1px) solid rgba(16, 235, 190, 1);
                }

                &:last-child {
                    border-right: px2rem(1px) solid rgba(16, 235, 190, 1);
                }
            }
        }
    }
}

.mirror .cmp-guild-agency-reward-rule-table {
    // direction: rtl;
}