@import '~@/common.scss';

.cmp-collection-information-channel {
    &-header {
        font-size: px2rem(18px);
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        height: px2rem(60px);
        border-bottom: px2rem(1px) solid rgba($color: #EEEEEE, $alpha: 0.2);
        position: relative;
        margin-bottom: px2rem(6px);

        &>span:last-child {
            font-size: px2rem(15px);
            color: #999999;
            position: absolute;
            right: 0;
        }
    }

    &-content {
        &__item {
            width: 100%;
            height: px2rem(46px);
            padding: 0 px2rem(15px);
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: rgba(240, 240, 240, 0.1);
            border-radius: px2rem(6px);
            margin-bottom: px2rem(10px);

            &>.left {
                &>img {
                    width: px2rem(35px);
                    height: px2rem(35px);
                    object-fit: cover;

                    &:nth-child(2n) {
                        margin: 0 px2rem(15px);
                    }
                }
            }

            &>.right {
                display: flex;
                align-items: center;
                font-size: px2rem(13px);
                color: #FFFFFF;

                &>span {
                    margin: 0 px2rem(15px);
                }

                &>img {
                    width: px2rem(8px);
                    height: px2rem(10px);
                    object-fit: cover;
                    transform: scaleX(-1);
                }
            }
        }
    }
}

.mirror .cmp-collection-information-channel {
    direction: rtl;

    &-content {
        &__item {
            &>.right {
                &>img {
                    transform: scaleX(1);
                }
            }
        }
    }
}