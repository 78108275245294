@import '~@/common.scss';

.pg-bd-agency-data {
    .first-cmp-number-card {
        margin-top: px2rem(30px);
    }

    .cmp-number-card {
        margin-bottom: px2rem(5px);
    }

    .cmp-number-card .selected {
        border: px2rem(1px) solid rgba(16, 235, 190, 1);
    }

    .cmp-scroll-table {

        .special-content {
            &__user {
                display: flex;
                flex-direction: column;

                &>span:first-child {
                    color: #fff;
                }

                &>span:last-child {
                    font-size: px2rem(12px);
                }
            }
        }
    }

    &__table {
        width: 100%;
        margin-top: px2rem(30px);
        padding: 0 px2rem(15px);
        box-sizing: border-box;
    }
}