@import '~@/common.scss';

.pg-guild-agency-search {
    background: #29005D;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &__bar {
        position: relative;

        &-confirm {
            width: px2rem(69px);
            height: px2rem(28px);
            position: absolute;
            right: px2rem(2px);
            top: 50%;
            transform: translateY(-50%);
            background-image: url(./images/search.webp);
            background-size: cover;
            background-repeat: no-repeat;
            color: white;
            font-size: px2rem(12px);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .cmp-empty {
        margin-top: px2rem(100px);
    }

    &>main {
        background-color: #FAFAFA;
        flex-grow: 1;
        padding: 0 px2rem(15px);
        box-sizing: border-box;
        padding-top: px2rem(18px);
        padding-bottom: px2rem(20px);
    }


    .adm-search-bar {
        &-input-box {
            background: #F0F0F0;
            border-radius: px2rem(17px);

            .adm-input-element {
                font-size: px2rem(12px);
                color: #000;

                &::placeholder {
                    color: #999999;
                }
            }
        }
    }

    &__item {
        width: 100%;
        height: px2rem(85px);
        border-radius: px2rem(6px);
        box-sizing: border-box;
        margin-top: px2rem(15px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: px2rem(1px) solid #EEEEEE;

        &-avatar {
            width: px2rem(55px);
            height: px2rem(55px);
            object-fit: cover;
            border-radius: px2rem(6px);
        }

        &-info {
            display: flex;
            flex-direction: column;
            margin: 0 px2rem(10px);
            flex-grow: 1;

            &>span:first-child {
                font-size: px2rem(13px);
                color: #000000;
                font-weight: bold;
                margin-bottom: px2rem(4px);
            }

            &>span:last-child {
                font-size: px2rem(11px);
                color: #B7B7B7;
                max-width: px2rem(260px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &-middle {
            display: flex;
            align-items: center;
            margin-bottom: px2rem(4px);

            &>span:first-child {
                font-size: px2rem(11px);
                color: #B7B7B7;
            }
        }

        &-people-icon {
            margin: 0 px2rem(20px);

            img {
                width: px2rem(10px);
                height: px2rem(10px);
                object-fit: cover;
            }

            span {
                font-size: px2rem(11px);
                color: #B7B7B7;
                margin: 0 px2rem(2px);
            }
        }

        &-logo {
            width: px2rem(65px);
            height: px2rem(56px);
            object-fit: contain;
        }
    }

    &__scroll {
        min-height: px2rem(350px);

        .cmp-scroll-list__empty {
            &>img {
                width: px2rem(163px);
                height: px2rem(146px);
            }
        }
    }
}

.mirror .pg-guild-agency-search {
    direction: rtl;

    .adm-search-bar-input-box {
        padding-right: var(--padding-left);
    }

    .adm-input-element {
        padding-left: px2rem(64px);
    }

    .adm-input-clear {
        display: none;
    }

    &__bar {
        &-confirm {
            right: unset;
            left: px2rem(2px);
        }
    }
}