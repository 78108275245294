@import '~@/common.scss';

.pg-guild-task-data-member-list {
    background-color: #171526;
    background-image: url('~@/images/guild-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: px2rem(21px);

    .cmp-member-card {
        background-color: unset;
        margin: 0;
        height: px2rem(75px);
        border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.1);

        &__info {
            padding-left: unset;

            &-avatar {

                width: px2rem(50px);
                height: px2rem(50px);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &-name {
                margin-bottom: px2rem(8px);
            }

            &-No {
                font-size: px2rem(12px);
            }
        }
    }

    .cmp-number-card2 {
        width: 100%;
        background: unset;

        &__item {
            &-val {
                &::after {
                    width: px2rem(100px);
                }
            }
        }
    }

    &>.content {
        margin-top: px2rem(20px);
        width: 100%;
        padding: 0 px2rem(15px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        &>.list-item {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: px2rem(6px);
            margin-top: px2rem(30px);
            padding: 0 px2rem(10px);
            box-sizing: border-box;
        }
    }

}