@import '../../../common.scss';

.cmp-form-item-list {
  padding: 0 px2rem(15px);
  width: 100%;
  box-sizing: border-box;
  .cmp-form-item {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      width: px2rem(340px);
      height: px2rem(1px);
      bottom: 0;
      background-color: rgba($color: #999999, $alpha: .2);
    }
  }
}