@import '~@/common.scss';

.pg-guild-invite-host {
    &__share {
        display: flex;
        margin: 0 px2rem(15px);
        align-items: center;
        justify-content: space-between;
        margin-top: px2rem(15px);

        &-item {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: px2rem(6px);
            width: px2rem(112px);
            height: px2rem(106px);
            font-size: px2rem(14px);
            color: #FFFFFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: px2rem(50px);
                height: px2rem(50px);
                margin-bottom: px2rem(6px);
            }
        }
    }

    &__tips {
        margin: 0 px2rem(15px);
        display: flex;
        flex-direction: column;

        &-title {
            font-weight: 500;
            font-size: px2rem(16px);
            color: #FFFFFF;
            margin-top: px2rem(30px);
            margin-bottom: px2rem(10px);
        }

        &-item {
            font-weight: 500;
            font-size: px2rem(13px);
            color: #B7B7B7;
            margin-bottom: px2rem(20px);
        }

        img {
            width: px2rem(125px);
            height: px2rem(271px);
            object-fit: cover;
            margin-bottom: px2rem(10px);

            &:last-child {
                margin: 0 px2rem(20px);
                margin-bottom: px2rem(10px);
            }
        }
    }
}

.mirror .pg-guild-invite-host {
    direction: rtl;
}