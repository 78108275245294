@import '~@/common.scss';

.cmp-wallet-status {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: calc(px2rem(60px) + env(safe-area-inset-bottom));

    .cmp-pane {
        &__title {
            margin: 0;
            margin-bottom: px2rem(15px);
            color: #333333;
            font-size: px2rem(14px);
            font-weight: 500;
        }
    }

    &>.bottom-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 px2rem(15px);
        position: fixed;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        background-color: white;
        padding: px2rem(15px);
        padding-bottom: calc(px2rem(15px) + env(safe-area-inset-bottom));
        box-sizing: border-box;

        &>div {
            flex: 1;
            height: px2rem(38px);
            border-radius: px2rem(20px);
            text-align: center;
            line-height: px2rem(38px);
            font-size: px2rem(14px);
            font-weight: 500;
        }

        &>.left {
            margin-right: px2rem(15px);
            background-color: rgba(240, 240, 240, 1);
            color: #B7B7B7;
        }

        &>.right {
            color: #fff;
            background: linear-gradient(to right, #1FF5A9, #00E0D5);
        }
    }
}

.mirror .cmp-wallet-status {
    &>.bottom-btn {
        direction: rtl;

        &>.left {
            margin-right: unset;
            margin-left: px2rem(15px);
        }
    }

    .cmp-pane {
        &__title {
            text-align: right;
        }
    }
}