@import '~@/common.scss';

.pg-bd-system {
    main {
        padding: 0 px2rem(15px);
    }

    &>.header-manager {
        background-image: url('~@/images/guild-top-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 150%;

        .cmp-identity-card {
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    .cmp-select-month-time {
        margin: 0 auto px2rem(15px);
    }

    .cmp-scroll-table {

        .special-content {
            &__user {
                display: flex;
                flex-direction: column;

                &>span:first-child {
                    color: #fff;
                }

                &>span:last-child {
                    font-size: px2rem(12px);
                }
            }

            &__topup {
                display: flex;
                align-items: center;
                justify-content: center;

                &>span {
                    margin: 0 px2rem(8px);
                    color: #fff;
                }

                &>img {
                    width: px2rem(13px);
                    height: px2rem(13px);
                }
            }
        }

        .cmp-agency-reward-grade {
            flex-direction: column;
            margin: 0;

            &>.lv {
                padding: px2rem(1px) px2rem(10px);
                margin-bottom: px2rem(6px);

                &>img {
                    width: px2rem(16px);
                    height: px2rem(9px);
                }
            }

            &>.rate {
                padding: px2rem(1px) px2rem(10px);
                font-size: px2rem(9px);
            }
        }
    }

    &-self-card,
    &-manager-card {
        display: flex;
        margin-top: px2rem(30px);
    }

    &-header-content {
        padding: px2rem(20px) px2rem(15px) px2rem(30px);
    }

    &__bd-list {
        &-header {
            height: px2rem(55px);
            position: relative;
            border-bottom: rgba(153, 153, 153, 0.2) px2rem(1px) solid;
        }

        &-title {
            width: 100%;
            height: px2rem(55px);
            line-height: px2rem(55px);
            text-align: center;
            font-size: px2rem(18px);
            color: white;
        }

        &-close {
            width: px2rem(40px);
            height: px2rem(55px);
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: px2rem(10px);
                height: px2rem(17px);
                transform: rotateZ(-90deg);
            }

            &-down {
                width: px2rem(17px) !important;
                height: px2rem(10px) !important;
                transform: rotateZ(0deg) !important;
            }
        }

        &-content {
            height: px2rem(405px);
            overflow-x: hidden;
            overflow-y: auto;

            .cmp-member-card {
                background-color: transparent;

                &__info {
                    padding: px2rem(15px) 0;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        width: px2rem(303px);
                        height: px2rem(1px);
                        background-color: rgba($color: #999999, $alpha: 0.2);
                        left: px2rem(60px);
                        bottom: 0;
                    }
                }
            }
        }



        & .adm-popup-body.adm-popup-body-position-bottom {
            border-radius: px2rem(15px) px2rem(15px) 0 0;
        }
    }

    &__hosts {
        &-header {
            height: px2rem(55px);
            position: relative;
            border-bottom: rgba(153, 153, 153, 0.2) px2rem(1px) solid;
        }

        &-title {
            width: 100%;
            height: px2rem(55px);
            line-height: px2rem(55px);
            text-align: center;
            font-size: px2rem(18px);
            color: white;
        }

        &-close {
            width: px2rem(40px);
            height: px2rem(55px);
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: px2rem(17px);
                transform: rotateZ(-90deg);
            }
        }

        &-content {
            height: px2rem(405px);
            overflow-x: hidden;
            overflow-y: auto;

            .cmp-member-card {
                background-color: transparent;

                &__info {
                    padding: px2rem(15px) 0;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        width: px2rem(303px);
                        height: px2rem(1px);
                        background-color: rgba($color: #999999, $alpha: 0.2);
                        left: px2rem(57px);
                        bottom: 0;
                    }
                }
            }
        }
    }
}

.mirror .pg-bd-system {
    direction: rtl;

}