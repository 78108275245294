@import '~@/common.scss';

.cmp-guild-wallet-data-calculate-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: px2rem(15px) 0;
    margin-bottom: px2rem(5px);
    position: relative;

    &::after,
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: px2rem(1px);
        background: linear-gradient(to right, rgba(252, 195, 1, 0), rgba(253, 195, 0, 0.6), rgba(255, 255, 255, 1), rgba(253, 195, 0, 1), rgba(238, 188, 24, 0));
        border-radius: px2rem(2px);
    }

    &::after {
        bottom: 0;
    }

    &::before {
        top: 0;
    }

    &>.content {
        display: flex;

        &>.item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex: 1;
            position: relative;
            color: #fff;
            padding: 0 px2rem(8px);
            box-sizing: border-box;

            &:nth-child(1) {
                .top {
                    display: flex;
                    flex-wrap: nowrap;

                    &::before {
                        content: '';
                        flex-shrink: 0;
                        width: px2rem(16px);
                        height: px2rem(16px);
                        background: url('~@/images/gem.png') no-repeat;
                        background-size: cover;
                        margin: 0 px2rem(4px);
                        z-index: 20;
                    }
                }
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                &::after {
                    content: '';
                    position: absolute;
                    right: px2rem(-5px);
                    top: 0;
                    font-size: px2rem(16px);
                }
            }

            &:nth-child(1) {
                &::after {
                    content: '=';
                }
            }

            &:nth-child(2) {
                &::after {
                    content: '+';
                }
            }

            &:nth-child(3) {
                &::after {
                    content: '-';
                }
            }

            &>.top {
                width: 100%;
                font-size: px2rem(16px);
                font-weight: 600;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: px2rem(7px);

                &>span {
                    word-break: break-word;
                    text-align: center;
                }
            }

            &>.bottom {
                font-size: px2rem(10px);
                color: rgba($color: #fff, $alpha: 0.6);
                text-align: center;
            }
        }

        &>.selected {
            border: px2rem(1px) solid rgba(16, 235, 190, 1);
        }
    }

    &>.status {
        position: absolute;
        left: 0;
        top: px2rem(-4px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px2rem(10px);
        font-weight: bold;
        color: #FFFFFF;
        width: px2rem(85px);
        height: px2rem(25px);

        span {
            z-index: 2;
            margin-bottom: px2rem(2px);
        }

        img {
            position: absolute;
            width: px2rem(85px);
            height: px2rem(25px);
            object-fit: cover;
        }
    }
}

.mirror .cmp-guild-wallet-data-calculate-data {
    direction: rtl;

    &>.content {
        &>.item {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                &::after {
                    right: unset;
                    left: px2rem(-5px);
                }
            }
        }
    }

    &>.status {
        left: unset;
        right: 0;
    }
}