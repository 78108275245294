@import '~@/common.scss';

.cmp-wallet-currency-exchange {
    &__content {
        display: flex;
        justify-content: center;

        .left,
        .right {
            display: flex;
            flex-direction: column;
            align-items: center;

            .img {
                width: px2rem(90px);
                height: px2rem(90px);
                background: #FFFFFF;
                border-radius: px2rem(10px);
                border: 1px solid #E8E8E8;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon-left,
                .icon-right {
                    width: px2rem(50px);
                    height: px2rem(50px);
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .icon-left {
                    background-image: url('~@/images/money.webp');
                }

                .icon-right {
                    background-image: url('~@/images/coin.png');
                }
            }

            .gem,
            .coin {
                display: flex;
                align-items: center;
                margin-top: px2rem(10px);

                .icon-left-small,
                .icon-right-small {
                    width: px2rem(12px);
                    height: px2rem(12px);
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                .icon-left-small {
                    background-image: url('~@/images/money.webp')
                }

                .icon-right-small {
                    background-image: url('~@/images/coin.png')
                }

                span {
                    font-size: px2rem(12px);
                    color: #000000;
                    margin: 0 px2rem(6px);
                }
            }
        }

        .middle {
            width: px2rem(39px);
            height: px2rem(18px);
            object-fit: cover;
            margin: px2rem(36px) px2rem(10px);
        }
    }
}

.mirror .cmp-wallet-currency-exchange {
    .middle {
        transform: scaleX(-1);
    }
}