@import '../../common.scss';

.pg-magic-lamp-rule {
  padding-bottom: px2rem(30px);
  color: white;
  &__title {
    img {
      height: px2rem(30px);
    }
    &:not(:first-child) {
      margin-top: px2rem(31px);
    }
  }
  &__content {
    width: 100%;
    &-item {
      margin: px2rem(20px) px2rem(16px) 0;
    }
  }
  table {
    border: solid px2rem(1px) #C5A8FF;
    border-radius: px2rem(6px);
    overflow: hidden;
    margin: px2rem(16px) px2rem(17px) 0;
    width: px2rem(343px);
    tr {
      line-height: px2rem(40px);
      text-align: center;
    }
    thead tr {
      background-color: #362162;
    }
    tbody tr:nth-child(even) {
      background-color: #362162;
    }
    tbody tr:nth-child(odd) {
      background-color: #211142;
    }
    th {
      color: white;
      border-collapse: collapse;
    }
    td {
      position: relative;
      width: 50%;
      border-collapse: collapse;
      &:nth-child(1) {
        &::after {
          content: '';
          position: absolute;
          width: px2rem(1px);
          height: px2rem(10px);
          background-color: rgba($color: #FFFFFF, $alpha: .2);
          right: 0;
          top: px2rem(15px);
        }
      }
    }
    .key {
      color: white;
    }
    .val {
      color: rgba($color: #FFFFFF, $alpha: .6);
    }
  }
}

.mirror {
  .pg-magic-lamp-rule {
    direction: rtl;
  }
}