@import '~@/common.scss';

.wallet-cmp-type-items {
    display: flex;
    flex-wrap: wrap;

    .item {
        width: px2rem(110px);
        height: px2rem(29px);
        background: #F0F0F0;
        border-radius: px2rem(6px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px2rem(12px);
        font-weight: 600;
        color: #999999;
        margin-right: px2rem(7px);
        margin-bottom: px2rem(7px);
        text-align: center;

        &:nth-child(3n) {
            margin-right: unset;
        }
    }

    .selected {
        background: #10EBBE;
        color: #fff;
    }
}

.mirror .wallet-cmp-type-items {
    direction: rtl;

    .item {
        margin-right: unset;
        margin-left: px2rem(7px);

        &:nth-child(3n) {
            margin-left: unset;
        }
    }


}