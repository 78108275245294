@import '../../common.scss';

.pg-guild-setting {
  background-color: black;
  background-image: url('../../images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  .cmp-update-avatar {
    margin-top: px2rem(30px);
  }
  .cmp-form-item-list {
    margin-top: px2rem(20px);
  }
}