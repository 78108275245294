@import '~@/common.scss';

.wallet-header-root {
    width: 100%;
    padding-top: px2rem(45px);
    box-sizing: border-box;

    .wallet-header {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background: linear-gradient(to right, #1FF5A9, #00E0D5);

        &__content {
            min-height: px2rem(45px);
            display: flex;
            align-items: center;
        }

        &__arrow,
        &__details {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            text-align: center;
        }

        &__arrow {
            left: px2rem(15px);

            img {
                width: px2rem(16px);
                height: px2rem(16px);
                object-fit: cover;
            }
        }

        &__details {
            right: px2rem(15px);
            font-size: px2rem(15px);
            color: #FFFFFF;
        }

        &__title {
            font-size: px2rem(18px);
            font-weight: 600;
            color: #FFFFFF;
        }

        //多tabs
        &__title-items {
            display: flex;
            // justify-content: center;
            align-items: center;
            // overflow-x: scroll;
            // max-width: px2rem(250px);
            height: 100%;

            &-item {
                font-size: px2rem(16px);
                font-weight: 600;
                color: rgba($color: #fff, $alpha: 0.6);
                padding: 0 px2rem(25px);

            }

            .selected {
                color: #FFFFFF;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: px2rem(15px);
                    height: px2rem(3px);
                    background: #FFFFFF;
                    border-radius: px2rem(2px);
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: px2rem(-6px);
                }
            }

        }

    }
}

.walletDetailBgPenetration {
    background: url('~@/images/wallet-header-bg.png') no-repeat;
    background-size: cover;
    background-position: center bottom px2rem(-20px);
}

.mirror .wallet-header-root {
    .wallet-header {
        &__arrow {
            left: unset;
            right: px2rem(15px);
            transform: scaleX(-1);
        }

        &__details {
            right: unset;
            left: px2rem(15px);
        }

        &__title-items {
            direction: rtl;

            &-item {
                padding: 0 px2rem(15px);
            }
        }
    }
}