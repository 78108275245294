@import '~@/common.scss';

.agent-recharge-coins-transfer-warning {
    background-color: #29005D !important;

    .cmp-wallet-status {
        .wallet-header-root {
            .wallet-header {
                background: #29005D;
            }
        }

        .cmp-pane {
            &__title {
                color: #AB89D3;
            }
        }

        .cmp-agent-recharge-status-user-info {
            .info {
                .user {
                    background: rgba($color: #F0F0F0, $alpha: 0.3);

                    .detail-info {
                        color: #FFFFFF;

                        &>span:last-child {
                            color: rgba($color: #fff, $alpha: 0.6);
                        }
                    }
                }

                .coin {
                    background: rgba($color: #F0F0F0, $alpha: 0.3);
                    color: #fff;

                    .img {
                        background: url('~@/images/wallet/coin-pic.png') no-repeat center/cover;
                    }
                }
            }
        }

        .bottom-btn {
            background-color: unset;

            .left,
            .right {
                background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
                border: px2rem(1px) solid #F9AF4A;
                box-sizing: border-box;
                color: #fff;
            }

            .left {
                opacity: 0.6;
            }
        }
    }
}