@import '~@/common.scss';

.cmp-anchorman-wallet-detail-popup {
    &-header {
        height: px2rem(55px);
        position: relative;
        border-bottom: px2rem(1px) solid rgba($color: #999, $alpha: 0.4);
        border-top-left-radius: px2rem(12px);
        display: flex;
        align-items: center;
    }

    &-title {
        width: 100%;
        font-size: px2rem(18px);
        text-align: center;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;

        span:first-child {
            margin: 0 px2rem(8px);
        }
    }

    &-cancel {
        position: absolute;
        font-size: px2rem(15px);
        color: #999;
        right: px2rem(15px);
        top: 50%;
        transform: translateY(-50%);
    }

    &-arrow {
        width: px2rem(9px);
        height: px2rem(15px);
        position: absolute;
        left: px2rem(15px);
        top: 50%;
        transform: translateY(-50%);
    }

    &-content {
        padding: 0 px2rem(15px);

        .cmp-pane {
            &__title {
                margin: 0;
                margin-top: px2rem(30px);
            }
        }
    }
}

.mirror .cmp-anchorman-wallet-detail-popup {
    &-arrow {
        left: unset;
        right: px2rem(15px);
        transform: translateY(-50%) scaleX(-1);
    }

    &-cancel {
        right: unset;
        left: px2rem(15px);
    }

    &-title {
        direction: rtl;
    }
}