@import '~@/common.scss';

.cmp-click-card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: px2rem(6px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: px2rem(78px);
    flex: 1;

    &:nth-child(2n-1) {
        margin-right: px2rem(5px);
    }

    &__top {
        display: flex;
        align-items: center;

        &-title {
            font-size: px2rem(14px);
            color: #ACABB0;
        }

        &-num {
            font-size: px2rem(18px);
            font-weight: bold;
            color: #FFFFFF;
            margin: 0 px2rem(4px);
        }

        &-arrow {
            width: px2rem(8px);
            height: px2rem(8px);
            object-fit: cover;
            transform: scaleX(-1);
            margin-top: px2rem(2px);
        }
    }

    &__line {
        width: px2rem(60px);
        height: px2rem(1px);
        background-color: rgba(255, 255, 255, 0.1);
        margin: px2rem(6px) 0;
    }

    &__bottom {
        &>div {
            font-size: px2rem(14px);
            color: #ACABB0;
            padding: px2rem(5px) px2rem(22px);
        }

        &>.btn {
            font-size: px2rem(13px);
            color: #fff;
            border: px2rem(1px) solid rgba(255, 220, 0, 1);
            background: linear-gradient(to bottom, rgba(53, 184, 56, 1), rgba(9, 100, 5, 1));
            border-radius: px2rem(6px);
            display: flex;
            align-items: center;
        }
    }
}

.mirror .cmp-click-card {
    direction: rtl;

    &:nth-child(2n-1) {
        margin-right: unset;
        margin-left: px2rem(5px);
    }

    &__top {
        &-arrow {
            transform: scaleX(1);
        }
    }
}