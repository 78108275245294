@import '../../common.scss';

.pg-guild-member {
  background-color: black;
  background-image: url('../../images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  .cmp-member-card, .cmp-number-card {
    margin-top: px2rem(20px);
  }
  .cmp-historical-card {
    margin-top: px2rem(20px);
  }
  .cmp-form-item-list {
    margin-top: px2rem(10px);
  }
  &__line {
    width: 100%;
    height: px2rem(1px);
    background-color: rgba($color: #999999, $alpha: .2);
    margin-top: px2rem(30px);
  }
  &__btn {
    &-kickout {
      position: absolute;
      padding: 0 px2rem(15px);
      top: 0;
      right: 0;
      height: 100%;
      display: flex;
      align-items: center;
      color: #10EBBE;
      font-size: px2rem(15px);
    }
  }
}

.mirror {
  .pg-guild-member {
    &__btn {
      &-kickout {
        right: unset;
        left: 0;
      }
    }
  }
}