@import '~@/common.scss';

$prefix-cls: 'cmp-guild-create-advocacy-second';

.#{$prefix-cls} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &>main {
        padding: 0 px2rem(15px);
        margin-bottom: px2rem(20px);
    }

    .cmp-pane {
        &__title {
            color: #fff;
            margin: px2rem(15px) 0;

            &::before {
                background: linear-gradient(to bottom, #F5FF00, #F7652F);
            }
        }
    }

    &__title-info {
        color: #A999BE;
    }

    &__conditions {
        color: #A999BE;
        border-radius: px2rem(6px);
        padding: px2rem(15px);
        background-color: rgba($color: #fff, $alpha: 0.16);
        display: flex;
        flex-direction: column;
        margin-bottom: px2rem(20px);

        &-title {
            color: #FFFFFF;
            margin-bottom: px2rem(10px);
        }

        &-days {
            font-weight: bold;
            color: #FFB327;
        }

        &-line {
            height: px2rem(2px);
            background: rgba($color: #D8D8D8, $alpha: 0.1);
            margin: px2rem(15px) 0;
        }

        &-task {
            color: #A999BE;
            margin-bottom: px2rem(6px);
        }

        &-hosts {
            &>span {
                font-weight: bold;
                color: #FFB327;
                margin: 0 px2rem(5px);
            }

            margin-bottom: px2rem(4px);
        }

        &-diamondNum {
            font-weight: bold;
            color: #7AC5FF;
            margin: 0 px2rem(5px);
        }

        &-gems {
            margin-bottom: px2rem(4px);
            display: flex;
            align-items: center;

            &-img {
                width: px2rem(17px);
                height: px2rem(17px);
                object-fit: contain;
            }
        }

        &-rewards {
            display: flex;
            align-items: center;
            color: #A999BE;

            .pg-guild-create-advocacy-second__conditions-diamondNum {
                margin: unset;
            }

            .pg-guild-create-advocacy-second__conditions-gems-img {
                margin: 0 px2rem(4px);
            }

            &-vip {
                width: px2rem(40px);
                height: px2rem(17px);
                object-fit: contain;
            }
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: px2rem(46px);
        width: 100%;
        padding: 0 px2rem(15px);
        box-sizing: border-box;
        margin-top: auto;

        &>span {
            width: px2rem(13px);
        }

        &>div {
            flex: 1;
            border-radius: px2rem(22px);
            font-weight: bold;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
            border: px2rem(1px) solid #F9AF4A;
            box-sizing: border-box;
            height: 100%;

            &:first-child {
                opacity: 0.6;
            }
        }
    }
}

.mirror .#{$prefix-cls} {
    direction: rtl;
}