@import '~@/common.scss';

.cmp-user {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__avatar {
    width: px2rem(35px);
    height: px2rem(35px);
    object-fit: cover;
    border-radius: 50%;
  }

  &__info {
    margin-left: px2rem(10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: px2rem(11px);

    span:not(:last-child) {
      margin-bottom: px2rem(7px);
    }
  }

  &__nick {
    max-height: px2rem(16px);
  }

  &__role {
    margin-left: px2rem(10px);
    background-color: rgba($color: #000000, $alpha: .2);
    border-radius: px2rem(18px);
    text-align: center;
    font-weight: bold;
    color: white;
    padding: px2rem(2px) px2rem(10px);
    box-sizing: border-box;
  }
}

.mirror {
  .cmp-user {
    flex-direction: row-reverse;
    text-align: right;

    &__info {
      margin-left: unset;
      margin-right: px2rem(10px);
    }
  }
}