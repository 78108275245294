@import '~@/common.scss';

.pg-guild-wallet-data-member-detail {
    background-color: #171526;
    background-image: url('~@/images/guild-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: px2rem(21px);
    position: relative;

    .cmp-member-card {
        padding: 0 px2rem(10px);
        background-color: unset;
        margin: 0;
        height: px2rem(75px);

        &__info {
            padding-left: unset;

            &-avatar {

                width: px2rem(50px);
                height: px2rem(50px);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &-name {
                margin-bottom: px2rem(8px);
            }

            &-No {
                font-size: px2rem(12px);
            }
        }

        &__right-btn {
            background: none;
            border: px2rem(2px) solid rgba(16, 235, 190, 1);
            font-size: px2rem(12px);
            font-weight: 500;
            color: #10EBBE;
        }
    }

    .cmp-number-card {
        padding: 0 px2rem(10px);

        &__item {
            background-color: rgba(0, 0, 0, 0.2);
            padding: px2rem(5px);
            box-sizing: border-box;
        }

        &.last {
            margin-top: px2rem(10px);
        }
    }

    .cmp-guild-tab-buttons {
        &__tab {
            font-weight: 500;

            .selected {
                border: unset;
                border-radius: unset;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: px2rem(20px);
                    height: px2rem(3px);
                    background: #10EBBE;
                    border-radius: px2rem(2px);
                }
            }
        }
    }

    .cmp-select-time-range {
        margin: px2rem(30px) auto px2rem(15px);
    }

    .cmp-guild-wallet-data-member-detail-data {
        background-color: unset;
    }

    &>.content {
        padding: 0 px2rem(15px);
        width: 100%;
        box-sizing: border-box;

        &>.top {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: px2rem(6px);
            margin-top: px2rem(15px);
            padding-bottom: px2rem(10px);

            &>.balance {
                display: flex;
                align-items: center;
                padding: 0 px2rem(10px);
                box-sizing: border-box;
                margin-bottom: px2rem(20px);

                .line {
                    width: px2rem(1px);
                    height: px2rem(12px);
                    background-color: rgba(255, 255, 255, 0.1);
                    margin: 0 px2rem(20px);
                }

                .left,
                .right {
                    display: flex;
                    align-items: center;
                    color: rgba($color: #fff, $alpha: 0.6);

                    img {
                        width: px2rem(13px);
                        height: px2rem(13px);
                        object-fit: cover;
                        margin: 0 px2rem(5px);
                    }

                    span:last-child {
                        color: #fff;
                    }
                }
            }
        }

        &>.bottom {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: px2rem(6px);
            padding: px2rem(10px) 0;
            box-sizing: border-box;

            &>.cash {
                .cmp-guild-wallet-data-member-detail-list {
                    &__list-item {
                        .right {
                            div:first-child {
                                .img {
                                    background-image: url('~@/images/money.webp');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mirror .pg-guild-wallet-data-member-detail {
    &>.content {
        &>.top {
            &>.balance {
                direction: rtl;
            }
        }
    }
}