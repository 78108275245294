@import '../../../common.scss';

.cmp-top3 {
  position: relative;
  width: 100%;
  height: px2rem(246px);
  // margin: 0 px2rem(15px);
  &__item {
    position: absolute;
    width: px2rem(90px);
    height: px2rem(186px);
    &-bg {
      width: 100%;
      height: 100%;
    }
    &-avatar {
      position: absolute;
      text-align: left;
      &-img {
        // display: block;
        width: px2rem(58px);
        height: px2rem(58px);
        border-radius: 50%;
      }
      &-cover {
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    &.item0 {
      top: px2rem(10px);
      left: px2rem(142px);
    }
    &.item0 &-avatar {
      width: px2rem(78px);
      height: px2rem(77px);
      top: px2rem(34px);
      left: px2rem(6px);
      &-img {
        position: absolute;
        top: px2rem(8px);
        left: px2rem(10px);
      }
    }
    &.item1 {
      top: px2rem(29px);
      left: px2rem(25px);
    }
    &.item1 &-avatar{
      width: px2rem(78px);
      height: px2rem(78px);
      top: px2rem(34px);
      left: px2rem(6px);
      &-img {
        position: absolute;
        top: px2rem(8px);
        left: px2rem(10px);
      }
    }
    &.item2 {
      top: px2rem(29px);
      left: px2rem(260px);
    }
    &.item2 &-avatar {
      width: px2rem(73px);
      height: px2rem(70px);
      top: px2rem(39px);
      left: px2rem(9px);
      &-img {
        position: absolute;
        top: px2rem(4px);
        left: px2rem(8px);
      }
    }
  }
}