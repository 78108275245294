@import '~@/common.scss';

.wallet-screen-type {
    padding: 0 px2rem(15px);

    .control {
        display: flex;
        justify-content: space-between;
    }

    .tips {
        font-size: px2rem(13px);
        font-weight: 400;
        color: #B7B7B7;
        display: flex;
        justify-content: center;
        margin-top: px2rem(20px);

        span {
            border-bottom: px2rem(1px) solid #eee;
            padding-bottom: px2rem(15px);
        }
    }
}

.mirror .wallet-screen-type {
    .control {
        direction: rtl;
    }
}