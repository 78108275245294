@import '~@/common.scss';

.pg-wallet-withdraw {
    position: relative;
    padding-bottom: px2rem(98px);

    .cmp-pane {

        &__title {
            margin: px2rem(15px) 0;
            font-size: px2rem(14px);
            font-weight: 500;
            color: #333333;
        }
    }

    .cmp-wallet-banner {
        background-image: url('~@/images/wallet/withdraw-banner-bg.webp');

        &>.info {
            .img {
                background-image: url('~@/images/money.webp');
            }

            span {
                @include text-stroke('#00BF37', px2rem(1px));
            }
        }
    }

    &__amount {
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 0 px2rem(11px);
        box-sizing: border-box;
        width: px2rem(345px);
        height: px2rem(55px);
        border-radius: px2rem(6px);
        box-shadow: 0px 4px 10px 5px rgba($color: #6C426D, $alpha: .06);

        &-max {
            display: flex;
            justify-content: center;
            align-items: center;
            width: px2rem(80px);
            height: px2rem(30px);
            background: linear-gradient(to right, #1FF5A9, #00E0D5);
            color: white;
            border-radius: px2rem(30px);
        }

        &::before {
            content: '';
            left: px2rem(15px);
            width: px2rem(16px);
            height: px2rem(16px);
            background-image: url('~@/images/money.webp');
            background-repeat: no-repeat;
            background-size: cover;
        }

        input {
            all: unset;
            flex: 1;
            margin-left: px2rem(6px);
        }
    }


    &__fee {
        margin-top: px2rem(11px);
        width: px2rem(345px);
        border-radius: px2rem(6px);
        box-shadow: 0px 4px 10px 5px rgba($color: #6C426D, $alpha: .06);
        background-color: #FFFFFF;
        padding: px2rem(15px);
        box-sizing: border-box;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: px2rem(1px);
            background-color: #EEEEEE;
            top: px2rem(91px);
            left: 0;
        }

        &-amount {
            font-size: px2rem(14px);
            color: #333333;

            &-num {
                font-size: px2rem(25px);
                color: #333333;
                padding: px2rem(15px) 0;
                font-weight: bold;
            }
        }

        &-info {
            margin-top: px2rem(15px);
            color: #FFB761;

            span:not(:last-child) {
                margin-right: px2rem(10px);
            }
        }

        &-val,
        &-quota {
            margin-top: px2rem(7px);
            font-size: px2rem(16px);
        }

        &-val {
            &-un {
                color: #999999;
                text-decoration: line-through;
            }
        }

        &-quota {
            position: relative;

            &-checkbox {
                position: absolute;
                right: 0;
                width: px2rem(20px);
                height: px2rem(20px);
                background-image: url('~@/images/checkbox.png');
                background-position: left center;
                background-size: cover;

                &.checked {
                    background-position: 100% center;
                }
            }
        }


    }

    &__tips {
        margin-top: px2rem(10px);
        font-size: px2rem(12px);
        color: #999999;
        background-color: #F5F5F5;
        padding: px2rem(8px);
        line-height: px2rem(17px);
    }

    &__btn {
        width: 100%;
        height: px2rem(38px);
        border-radius: px2rem(20px);
        font-size: px2rem(14px);
        position: relative;
        border: none;
        // transform: translateY(-100%);

    }

    &__bottom {
        background-color: white;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: px2rem(15px);
        box-sizing: border-box;
    }

    .mirror .pg-wallet-withdraw {
        &__amount {
            flex-direction: row-reverse;

            &::before {
                left: unset;
                right: px2rem(15px);
            }

            input {
                direction: rtl;
                margin-left: unset;
                margin-right: px2rem(6px);
            }
        }

        &__fee {
            direction: rtl;
        }

        &__tips {
            direction: rtl;
        }
    }
}