@import '~@/common.scss';

.wallet-info-list {
    padding: 0 px2rem(15px);
    min-height: px2rem(200px);
    position: relative;

    .antd-loading {
        z-index: 999;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .cmp-scroll-list {
        min-height: px2rem(200px);
    }

    .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: px2rem(77px);
        border-bottom: px2rem(1px) solid #eee;
        padding: px2rem(15px) 0;
        box-sizing: border-box;

        .left,
        .right {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .left {
            .name {
                font-size: px2rem(16px);
                font-weight: 500;
                color: #121212;
            }

            .type {
                font-size: px2rem(12px);
                font-weight: 400;
                color: #999999;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .coin {
                display: flex;
                align-items: center;

                span {
                    font-size: px2rem(13px);
                    font-weight: 500;
                    color: #333333;
                    margin: 0 px2rem(6px);
                }

                img {
                    width: px2rem(22px);
                    height: px2rem(22px);
                    object-fit: cover;
                }
            }

            .time {
                font-size: px2rem(12px);
                font-weight: 400;
                color: #999999;
            }
        }
    }
}

.mirror .wallet-info-list {
    .list-item {
        direction: rtl;
    }
}