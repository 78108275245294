@import '~@/common.scss';

.pg-guild-agency-reward-rule {
  background-color: #1A1426;
  background-image: url('~@/images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-bottom: px2rem(26px);
  box-sizing: border-box;
  width: 100%;

  &__item {
    padding: px2rem(30px) px2rem(15px) 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-title {
      color: #FFFFFF;
      font-size: px2rem(16px);
      word-wrap: break-word;
    }

    &-content {
      margin-top: px2rem(10px);
      color: #B7B7B7;
      font-size: px2rem(13px);
      word-wrap: break-word;
    }

  }
}

.mirror {
  .pg-guild-agency-reward-rule {
    direction: rtl;
  }
}