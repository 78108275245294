@import '~@/common.scss';

.cmp-anchorman-wallet-detail-href-button {
    width: 100%;
    height: px2rem(40px);
    border-radius: px2rem(4px);
    background: rgba($color: #003D3B, $alpha: 0.2);
    font-size: px2rem(13px);
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: px2rem(15px);

    &>span {
        display: flex;
        align-items: center;

        &::before {
            content: '';
            width: px2rem(3px);
            height: px2rem(15px);
            background: linear-gradient(to right, #1FF5A9, #00E0D5);
            border-radius: px2rem(2px);
            margin: 0 px2rem(12px);
        }
    }

    &>img {
        width: px2rem(10px);
        height: px2rem(13px);
        object-fit: cover;
        position: absolute;
        right: px2rem(10px);
        transform: scaleX(-1);
    }
}

.mirror .cmp-anchorman-wallet-detail-href-button {
    &>img {
        right: unset;
        left: px2rem(10px);
        transform: scaleX(1);
    }
}