@import '../../../common.scss';

.cmp-select-time-room {
  margin-top: px2rem(30px);
  width: px2rem(167px);
  height: px2rem(24px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: px2rem(6px);
  border: 1px solid #EEEEEE;
  padding: 0 px2rem(10px);

  &__calendar {
    width: px2rem(15px);
    height: px2rem(16px);
    margin-right: px2rem(4px);
  }

  &__time {
    color: rgba($color: #000, $alpha: .6);
    font-size: px2rem(12px);
    flex: 1;
    text-align: center;
  }

  &__delta {
    width: px2rem(6px);
    height: px2rem(5px);
    margin-left: px2rem(4px);
  }
}