@import '~@/common.scss';

.cmp-guild-wallet-data-member-detail-data {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: px2rem(6px);
    padding: 0 px2rem(10px);
    box-sizing: border-box;
    color: #fff;
    font-size: px2rem(14px);
    position: relative;

    .cmp-scroll-tabs {
        font-size: px2rem(12px);
        height: px2rem(40px);
        border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.1);
        color: rgba($color: #fff, $alpha: 0.6);
        margin: 0;

        &>.selected {
            color: rgba($color: #fff, $alpha: 1);

            &::after {
                width: px2rem(20px);
                height: px2rem(3px);
                background-color: #fff;
                bottom: px2rem(14px);
            }
        }
    }

    &>.echarts-tips {
        font-size: px2rem(12px);
        font-weight: 500;
        color: rgba($color: #fff, $alpha: 0.4);
        display: flex;
        align-items: center;

        &>div {
            display: flex;
            flex-direction: column;
        }

        img {
            width: px2rem(15px);
            height: px2rem(15px);
            object-fit: cover;
        }

        span {
            margin: 0 px2rem(4px);
        }
    }

    &>.details-title {
        margin-top: px2rem(15px);
        margin-bottom: px2rem(10px);
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: px2rem(6px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: px2rem(5px) 0;
        font-size: px2rem(12px);
        font-weight: 600;
        color: #FFFFFF;
    }

    &>.details {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: px2rem(6px);
        padding: px2rem(15px) px2rem(10px) px2rem(10px);
        box-sizing: border-box;
    }
}

.mirror .cmp-guild-wallet-data-member-detail-data {
    direction: rtl;
}