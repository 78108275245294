@import '~@/common.scss';

.pg-guild-wallet-data-member-list {
    background-color: #171526;
    background-image: url('~@/images/guild-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding-bottom: px2rem(30px);
    box-sizing: border-box;

    .cmp-member-card {
        padding: 0 px2rem(10px);
        background-color: unset;
        margin: 0;
        height: px2rem(75px);

        &__info {
            padding-left: unset;

            &-avatar {

                width: px2rem(50px);
                height: px2rem(50px);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &-name {
                margin-bottom: px2rem(8px);
            }

            &-No {
                font-size: px2rem(12px);
            }
        }
    }

    .cmp-number-card {
        padding: 0 px2rem(10px);

        &__item {
            background-color: rgba(0, 0, 0, 0.2);
            padding: px2rem(5px);
            box-sizing: border-box;
        }

        &.last {
            margin-top: px2rem(10px);
        }
    }

    >.content {
        width: 100%;
        padding: 0 px2rem(15px);
        box-sizing: border-box;

        .list-item {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: px2rem(6px);
            margin-top: px2rem(15px);
            padding-bottom: px2rem(10px);

            &>.balance {
                display: flex;
                align-items: center;
                padding: 0 px2rem(10px);
                box-sizing: border-box;
                margin-bottom: px2rem(20px);

                .line {
                    width: px2rem(1px);
                    height: px2rem(12px);
                    background-color: rgba(255, 255, 255, 0.1);
                    margin: 0 px2rem(20px);
                }

                .left,
                .right {
                    display: flex;
                    align-items: center;
                    color: rgba($color: #fff, $alpha: 0.6);

                    img {
                        width: px2rem(13px);
                        height: px2rem(13px);
                        object-fit: cover;
                        margin: 0 px2rem(5px);
                    }

                    span:last-child {
                        color: #fff;
                    }
                }
            }
        }
    }

}

.mirror .pg-guild-wallet-data-member-list {
    &>.content {

        &>.list-item {

            &>.balance {
                direction: rtl;
            }
        }
    }
}