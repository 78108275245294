@import '../../common.scss';

.pg-update {
  background-color: rgba(7, 58, 40, 1);

  .header {
    width: 100%;
  }

  .update-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: px2rem(-169px);

    .up-btn {
      width: px2rem(110px);
      height: px2rem(41px);
      object-fit: cover;
    }

    .description {
      margin-top: px2rem(55px);
      width: px2rem(355px);
      height: px2rem(165px);
      background-image: url('./images/description-bg.webp');
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .title-img {
        width: px2rem(132px);
        height: px2rem(48px);
        object-fit: cover;
        position: absolute;
        top: px2rem(-24px);
      }

      .pk-btn,
      .newgame-btn {
        width: px2rem(315px);
        height: px2rem(41px);
        background-image: url('./images/pk-bg.webp');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        position: relative;

        span {
          font-size: px2rem(15px);
          color: #F4FF00;
        }

        img {
          width: px2rem(12px);
          height: px2rem(11px);
          object-fit: contain;
          position: absolute;
          right: px2rem(13px);
        }
      }

      .pk-btn {
        margin-bottom: px2rem(15px);
      }
    }
  }

  .content {
    width: px2rem(355px);
    height: px2rem(1918px);
    margin: px2rem(54px) auto 0;
    background-image: url('./images/img-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding-left: px2rem(21px);
    box-sizing: border-box;
    padding-top: px2rem(39px);

    .title-btn {
      width: px2rem(132px);
      height: px2rem(48px);
      object-fit: cover;
      position: absolute;
      top: px2rem(-24px);
      left: 50%;
      transform: translateX(-50%);
    }

    .content1,
    .content2 {
      display: flex;
      flex-direction: column;

      span {
        font-size: px2rem(15px);
        font-weight: bold;
        color: #FFFFFF;
      }

      img {
        margin-top: px2rem(29px);
        width: 100%;
        object-fit: cover;
      }
    }

    .content2 {
      margin-top: px2rem(46px);
      display: flex;
      flex-direction: column;

      img {
        width: px2rem(231px);
        object-fit: cover;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: px2rem(65px);
      }
    }
  }

  .bottom-tip {
    font-size: px2rem(12px);
    font-weight: 400;
    color: rgba($color: #FFFFFF, $alpha: 0.6);
    text-align: center;
    padding: px2rem(32px) 0 px2rem(29px);
  }
}

.mirror .pg-update {

  .update-box {
    .description {

      .pk-btn,
      .newgame-btn {
        img {
          right: unset;
          left: px2rem(13px);
          transform: scale(-1);
        }
      }
    }
  }

  .content {
    direction: rtl;

    .content1,
    .content2 {
      span {
        // direction: rtl;
        margin-right: px2rem(20px);
      }
    }
  }
}