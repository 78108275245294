@import '~@/common.scss';

.cmp-anchorman-wallet-detail-card4-rule {
  background-color: #1A1426;
  background-image: url('~@/images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-bottom: px2rem(26px);
  box-sizing: border-box;
  width: 100%;

  h2 {
    text-align: center;
    color: #fff;
  }

  &__item {
    padding: px2rem(30px) px2rem(15px) 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-title {
      color: #FFFFFF;
      font-size: px2rem(16px);
      word-wrap: break-word;
    }

    &-content {
      margin-top: px2rem(10px);
      color: #B7B7B7;
      font-size: px2rem(13px);
      word-wrap: break-word;

      &>div {
        margin-bottom: px2rem(10px);
      }

      .table-box {
        border: px2rem(1px) solid rgba($color: #ffffff, $alpha: 0.1);
      }

      table {
        width: 100%;
        color: rgba($color: #ffffff, $alpha: 0.6);
        border-collapse: collapse;
        text-align: center;

        tr {
          td:not(:last-child) {
            border-right: solid px2rem(1px) rgba($color: #ffffff, $alpha: 0.1);
            border-bottom: solid px2rem(1px) rgba($color: #ffffff, $alpha: 0.1);
          }

          th:not(:last-child) {
            border-right: solid px2rem(1px) rgba($color: #ffffff, $alpha: 0.1);
            border-bottom: solid px2rem(1px) rgba($color: #ffffff, $alpha: 0.1);
          }
        }

        thead tr,
        tbody tr:not(:last-child) {
          border-bottom: px2rem(1px) solid rgba($color: #ffffff, $alpha: 0.1);
        }
      }
    }

  }
}

.mirror {
  .cmp-anchorman-wallet-detail-card4-rule {
    direction: rtl;

    table {

      tr {
        td:not(:last-child) {
          border-right: unset;
          border-bottom: unset;
          border-left: solid px2rem(1px) rgba($color: #ffffff, $alpha: 0.1);
        }

        th:not(:last-child) {
          border-right: unset;
          border-left: solid px2rem(1px) rgba($color: #ffffff, $alpha: 0.1);
        }
      }
    }
  }
}