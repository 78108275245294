@import '../../common.scss';

.pg-guild-rule {
  background-color: #1A1426;
  background-image: url('../../images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-bottom: px2rem(26px);
  box-sizing: border-box;
  &__item {
    margin: px2rem(30px) px2rem(15px) 0;
    &-title {
      color: #FFFFFF;
      font-size: px2rem(16px);
    }
    &-content {
      margin-top: px2rem(10px);
      color: #B7B7B7;
      font-size: px2rem(13px);
    }
    table {
      border: solid px2rem(1px) rgba($color: #FFFFFF, $alpha: .6);
      border-radius: px2rem(6px);
      width: 100%;
      tr {
        line-height: px2rem(44px);
      }
      td, th {
        width: 33.3%;
        border-right: solid px2rem(1px) rgba($color: #FFFFFF, $alpha: .6);
        border-bottom: solid px2rem(1px) rgba($color: #FFFFFF, $alpha: .6);
        text-align: center;
        div {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      img {
        width: px2rem(55px);
      }
    }
  }
}

.mirror {
  .pg-guild-rule {
    direction: rtl;
  }
}