@import '../../common.scss';

.pg-rule-level {
  background-color: #1D1E22;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: px2rem(23px);
  &__img1, &__img2, &__img3 {
    margin-top: px2rem(30px);
    width: px2rem(345px);
  }
}