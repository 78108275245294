@import '~@/common.scss';

.cmp-anchorman-wallet-detail-card-item {
    width: 100%;
    background-repeat: no-repeat;
    // background-size: cover;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 0 px2rem(14px);
    padding-top: px2rem(13px);

    .title {
        display: flex;
        align-items: center;

        .num {
            width: px2rem(16px);
            height: px2rem(16px);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: px2rem(11px);
            font-weight: 500;
            color: #FFFFFF;
            user-select: none;
        }

        .num1,
        .num3 {
            background: rgba(30, 111, 185, 1);
        }

        .num2,
        .num4 {
            background: rgba(13, 155, 107, 1);
        }

        span {
            font-size: px2rem(13px);
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 px2rem(10px);
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: px2rem(15px);

        .left {
            display: flex;
            align-items: center;

            img {
                width: px2rem(40px);
                height: px2rem(40px);
                object-fit: cover;
            }

            span {
                font-size: px2rem(25px);
                font-weight: bold;
                color: #FFFFFF;
                margin: 0 px2rem(10px);
                text-shadow: #3270D4 px2rem(1px) 0 0, #3270D4 0 px2rem(1px) 0, #3270D4 px2rem(-1px) 0 0, #3270D4 0 px2rem(-1px) 0;
            }
        }

        .right {
            width: px2rem(60px);
            height: px2rem(26px);
            background-color: #fff;
            border-radius: px2rem(18px);
            text-align: center;
            line-height: px2rem(26px);
            color: #3AC1FD;
            font-size: px2rem(12px);
            font-weight: 500;
        }
    }
}

.mirror .cmp-anchorman-wallet-detail-card-item {
    direction: rtl;
}