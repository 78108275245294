@import '~@/common.scss';

.cmp-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__img {
    width: px2rem(163px);
    height: px2rem(146px);
    object-fit: contain;
  }

  &__description {
    color: #B7B7B7;
  }
}