@import '../../../common.scss';

.cmp-number-card {
  width: 100%;
  box-sizing: border-box;
  padding: 0 px2rem(15px);
  display: flex;
  justify-content: space-between;

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: px2rem(73px);
    background-color: rgba($color: #ffffff, $alpha: 0.1);
    border-radius: px2rem(6px);

    &-val {
      font-size: px2rem(14px);
      color: white;
      height: px2rem(36px);
      display: flex;
      align-items: center;

      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: px2rem(60px);
        border-bottom: rgba($color: #ffffff, $alpha: 0.2) px2rem(1px) solid;
        top: px2rem(36px);
        left: 50%;
        transform: translateX(-50%);
      }

      img {
        width: px2rem(17px);
        height: px2rem(17px);
      }
    }

    &-key {
      font-size: px2rem(12px);
      color: rgba($color: #ffffff, $alpha: 0.6);
      height: px2rem(36px);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &:not(:last-child) {
      margin-right: px2rem(8px);
    }
  }
}

.cmp-number-card2 {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right,
      transparent,
      rgba(#38223f, 0.5),
      transparent);

  &__item {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: px2rem(110px);
    height: px2rem(73px);
    border-radius: px2rem(6px);

    &-val {
      position: relative;
      font-size: px2rem(14px);
      color: white;
      height: px2rem(36px);
      display: flex;
      align-items: center;

      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: px2rem(60px);
        border-bottom: rgba($color: #ffffff, $alpha: 0.2) px2rem(1px) solid;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      img {
        width: px2rem(17px);
        height: px2rem(17px);
      }
    }

    &-key {
      font-size: px2rem(12px);
      color: rgba($color: #ffffff, $alpha: 0.6);
      height: px2rem(36px);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:not(:last-child) {
      margin-right: px2rem(8px);

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: px2rem(25px);
        border-right: rgba($color: #ffffff, $alpha: 0.2) px2rem(1px) solid;
        top: px2rem(24px);
        right: 0;
      }
    }
  }
}

.mirror {
  .cmp-number-card {
    flex-direction: row-reverse;

    &__item {
      &:not(:last-child) {
        margin-right: 0;
        margin-left: px2rem(8px);
      }
    }
  }

  .cmp-number-card2 {
    flex-direction: row-reverse;

    &__item {
      &::after {
        right: unset;
        left: 0;
      }
    }
  }
}