@import '../../common.scss';

.pg-help {
  background-color: #FAFAFA;
  padding-top: px2rem(15px);
  box-sizing: border-box;
  height: 100vh;
  .cmp-header {
    background: linear-gradient(to right, #1FF5A9, #00E0D5);
    &__title {
      color: #FFFFFF;
      font-size: px2rem(18px);
    }
  }
  &__content {
    .cmp-pane {
      margin-bottom: px2rem(30px);
      &:not(:last-child) {
        margin-top: px2rem(15px);
      }
      &__title {
        color: #1A1A1A;
        font-weight: normal;
      }
    }
  }
}