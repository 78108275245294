@import '../../../common.scss';

.cmp-announce {
  width: 100%;
  padding: 0 px2rem(15px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &__line {
    &-top, &-bottom {
      width: 100%;
      height: px2rem(1px);
      background: linear-gradient(to right, rgba(#FCC301, 0), rgba(#FCC301, .27), rgba(#FCC301, .36), rgba(#FFFFFF, 0));
    }
  }
  &__content {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__icon {
    width: px2rem(40px);
    height: px2rem(40px);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: px2rem(20px);
      height: px2rem(20px);
    }
  }
  &__text {
    flex: 1;
    color: white;
    font-size: px2rem(13px);
  }
}

.mirror {
  .cmp-announce {
    &__content {
      flex-direction: row-reverse;
    }
    &__icon {
      transform: rotateZ(180deg);
    }
    &__text {
      text-align: right;
    }
  }
}