@import '../common.scss';

.cmp-safe-area {
  display: block;
  width: 100%;
  &.top {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-top: env(safe-area-inset-top);
  }
  &.bottom {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}