@import '../common.scss';

.cmp-scroll-list {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &__empty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-img {
      display: block;
      width: px2rem(163px);
      height: px2rem(146px);
    }

    &-text {
      margin-top: px2rem(22px);
      color: #B7B7B7;
      font-size: px2rem(13px);
    }
  }

  &__antd-loading {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}