@import '~@/common.scss';

.pg-agent-recharge-coins-transfer {
  background-color: #29005D;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  .wallet-header-root {
    .wallet-header {
      background: #29005D;
    }
  }

  .cmp-pane {
    margin: px2rem(15px) px2rem(15px) 0;

    &__title {
      margin-left: 0;
      margin-right: 0;
      font-size: px2rem(14px);
      font-weight: 500;
      color: #fff;
    }
  }

  .cmp-pane:first-child {
    margin: px2rem(15px) px2rem(15px) 0;
  }

  .cmp-scroll-tabs {
    color: rgba($color: #fff, $alpha: 0.6);
    padding: 0 px2rem(50px);

    .selected {
      color: #fff;

      &::after {
        background-color: #fff;
      }
    }
  }

  .cmp-transfer-content {
    &__bottom-confirm {
      background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
      border: px2rem(1px) solid #F9AF4A;
      box-sizing: border-box;
    }

    &__input {
      background-color: rgba($color: #F3F3F3, $alpha: 0.2);
      color: #fff;
    }

    &__user-info {
      color: #fff;
    }

    &__amount {
      background-color: rgba($color: #F3F3F3, $alpha: 0.2);
      color: #fff;
    }

    &__confirm {
      background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
      border: px2rem(1px) solid #F9AF4A;
      box-sizing: border-box;
    }
  }

  &__balance {
    width: 100%;
    height: px2rem(91px);
    display: flex;
    align-items: center;
    position: relative;

    .bg {
      position: absolute;
      width: 100%;
      height: px2rem(91px);
      background-image: url('../../images/wallet/coins-balance-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .icon {
      z-index: 2;
      width: px2rem(40px);
      height: px2rem(40px);
      object-fit: cover;
      margin-left: px2rem(20px);
      margin-right: px2rem(10px);
    }

    .num {
      z-index: 2;
      font-size: px2rem(25px);
      font-weight: bold;
      color: #fff;
      text-shadow: #BF9400 px2rem(1px) 0 0, #BF9400 0 px2rem(1px) 0, #BF9400 px2rem(-1px) 0 0, #BF9400 0 px2rem(-1px) 0;
    }
  }

  &-top-up-popup__popup {

    &-header {
      height: px2rem(55px);
      line-height: px2rem(55px);
      position: relative;
      border-bottom: px2rem(1px) solid rgba($color: #999, $alpha: 0.4);
      border-top-left-radius: px2rem(12px);
    }

    &-title {
      width: 100%;
      line-height: px2rem(55px);
      font-size: px2rem(18px);
      text-align: center;
      color: #fff;
    }

    &-cancel {
      position: absolute;
      font-size: px2rem(15px);
      color: #F9AE49;
      right: px2rem(15px);
      top: 0;
    }
  }

  &__shipping {
    margin: 0 px2rem(14px);
    margin-top: px2rem(14px);

    &-item {
      display: flex;
      flex-direction: column;
      border-radius: px2rem(6px);
      border: px2rem(1px) solid rgba($color: #FFAD49, $alpha: 0.9);
      padding: px2rem(11px);
      box-sizing: border-box;
      margin-bottom: px2rem(13px);
    }

    &-item__top {
      display: flex;
      align-items: center;

      &>div {
        display: flex;
        flex-direction: column;
        color: #fff;

        &>span:first-child {
          font-weight: bold;
          font-size: px2rem(13px);
          margin-bottom: px2rem(8px);
        }

        &>span:last-child {
          font-size: px2rem(11px);
        }
      }
    }

    &-item__avatar {
      width: px2rem(55px);
      height: px2rem(55px);
      border-radius: 50%;
      object-fit: cover;
      margin-right: px2rem(11px);
    }

    &-item__arrow {
      width: px2rem(16px);
      height: px2rem(16px);
      margin-left: auto;
      transform: scaleX(-1);
    }

    &-item__bottom {
      margin-top: px2rem(10px);
      display: flex;
      flex-wrap: wrap;

      &>span {
        font-size: px2rem(13px);
        color: #FFFFFF;
        margin-right: px2rem(6px);
      }
    }

    &-item__country {
      font-size: px2rem(13px);
      color: rgba($color: #FFFFFF, $alpha: 0.6);
      margin-right: px2rem(10px);
      margin-bottom: px2rem(11px);
      display: flex;
      align-items: center;

      &>img {
        width: px2rem(21px);
        height: px2rem(14px);
        object-fit: contain;
        margin-right: px2rem(4px);
      }
    }
  }

  &__recycle {
    margin: 0 px2rem(14px);
    padding-top: px2rem(14px);

    &-item {
      display: flex;
      flex-direction: column;
      border-radius: px2rem(6px);
      border: px2rem(1px) solid rgba($color: #FFAD49, $alpha: 0.9);
      padding: px2rem(11px);
      box-sizing: border-box;
      margin-bottom: px2rem(13px);
    }

    &-item__top {
      display: flex;
      align-items: center;


    }

    &-item__user {
      display: flex;
      flex-direction: column;
      color: #fff;

      &>span:first-child {
        font-weight: bold;
        font-size: px2rem(13px);
        margin-bottom: px2rem(8px);
      }

      &>span:last-child {
        font-size: px2rem(11px);
      }
    }

    &-item__avatar {
      width: px2rem(55px);
      height: px2rem(55px);
      border-radius: 50%;
      object-fit: contain;
      margin-right: px2rem(11px);
    }

    &-item__money {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 15px;
      color: #FFFFFF;
      margin-left: auto;

      img {
        width: px2rem(18px);
        height: px2rem(18px);
        object-fit: contain;
        margin: 0 px2rem(6px);
      }
    }

    &-item__bottom {
      margin-top: px2rem(10px);
      display: flex;
      align-items: center;

    }

    &-item__country {
      font-size: px2rem(13px);
      color: #FFFFFF;
      display: flex;
      align-items: center;

      &>img {
        width: px2rem(21px);
        height: px2rem(14px);
        object-fit: contain;
        margin: 0 px2rem(4px);
      }

      &>.name {
        color: rgba($color: #FFFFFF, $alpha: 0.6);
      }
    }

    &-item__btn {
      width: px2rem(91px);
      height: px2rem(24px);
      background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
      border: px2rem(1px) solid #F9AF4A;
      box-sizing: border-box;
      border-radius: px2rem(12px);
      color: #fff;
      font-size: px2rem(10px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;

      &.disabled {
        background: #BDC3C7;
        color: #888888;
        border: unset;
      }
    }
  }
}



.mirror {
  .pg-agent-recharge-coins-transfer {
    &__balance {
      flex-direction: row-reverse;

      .bg {
        transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
      }

      .icon {
        margin-right: px2rem(20px);
        margin-left: px2rem(10px);
      }
    }

    &__shipping {
      direction: rtl;

      &-item__avatar {
        margin-right: unset;
        margin-left: px2rem(11px);
      }

      &-item__arrow {
        margin-left: unset;
        margin-right: auto;
        transform: scaleX(1);
      }

      &-item__country {
        margin-right: unset;
        margin-left: px2rem(10px);

        &>img {
          margin-right: unset;
          margin-left: px2rem(4px);
        }
      }

      &-item__bottom {

        &>span {
          margin-right: unset;
          margin-left: px2rem(6px);
        }
      }
    }

    &__recycle {
      direction: rtl;

      &-item__avatar {
        margin-right: unset;
        margin-left: px2rem(11px);
      }

      &-item__money {
        margin-left: unset;
        margin-right: auto;
      }


      &-item__btn {
        margin-left: unset;
        margin-right: auto;
      }
    }
  }
}