@import '~@/common.scss';

.cmp-scroll-table {
    width: 100%;
    box-sizing: border-box;

    &__content {
        background-color: rgba($color: #ffffff, $alpha: 0.1);
        border-radius: px2rem(6px);
        padding: 0 px2rem(10px);
        overflow-x: auto;
        position: relative;
    }

    &>.scroll {
        font-size: px2rem(12px);

        // 显示滚动条，设置滚动条样式（部分手机无效）
        &::-webkit-scrollbar {
            display: block;
            height: px2rem(5px);
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: px2rem(99999px);
            overflow: hidden;
            z-index: 999999;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #fff, $alpha: 0.5);
            border-radius: px2rem(99999px);
        }

        th,
        td {
            text-align: start;

            &:last-child {
                /* 最后一列固定 */
                position: sticky;
                right: px2rem(-10px);
                z-index: 2;
                background: linear-gradient(90deg, #472949 0%, #2F1B34 100%);

                &:has(.cmp-scroll-table__empty) {
                    background: unset;
                }
            }
        }
    }

    &__empty {
        width: var(--w);
        height: px2rem(176px);
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-direction: column;
        position: sticky;
        left: 0;
        box-sizing: border-box;

        img {
            width: px2rem(114px);
            height: px2rem(106px);
            object-fit: cover;
        }

        span {
            font-size: px2rem(13px) !important;
            color: rgba($color: #FFFFFF, $alpha: 0.6) !important;
        }
    }

    table {
        min-width: 100%;
        color: rgba($color: #ffffff, $alpha: 0.6);
        border-collapse: collapse;
        table-layout: fixed; //使用 fixed 表示列宽度由表头列决定

        tr {
            height: px2rem(45px);

            th,
            td {
                text-align: center;
                overflow-wrap: break-word;
                min-width: px2rem(70px); // 75px完整盖住
                max-width: px2rem(100px);
                padding: px2rem(16px);
            }

        }


        thead tr,
        tbody tr:not(:last-child) {
            border-bottom: px2rem(1px) solid rgba($color: #ffffff, $alpha: 0.1);
        }


    }
}

.mirror .cmp-scroll-table {
    direction: rtl;

    table {
        tr {

            th:last-child,
            td:last-child {
                right: unset;
                left: px2rem(-10px);
            }
        }
    }
}