@import '~@/common.scss';

.cmp-scroll-tabs {
    margin-top: px2rem(17px);
    width: 100%;
    font-size: px2rem(14px);
    font-weight: 600;
    color: #B7B7B7;
    box-sizing: border-box;
    overflow-x: auto;
    /* 可以使窗口平稳滚动 */
    scroll-behavior: smooth;
    display: flex;
    flex-wrap: nowrap;
    height: px2rem(30px);

    &>.tab-item {
        flex-shrink: 0;
        margin: 0 auto;
        padding: 0 px2rem(11px);
        box-sizing: border-box;
    }

    .selected {
        color: #000;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: px2rem(15px);
            height: px2rem(3px);
            background-color: black;
            border-radius: px2rem(2px);
        }
    }
}

.mirror .cmp-scroll-tabs {
    direction: rtl;
}