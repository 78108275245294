@import '~@/common.scss';

.cmp-record {
  &__list {
    padding: px2rem(15px);
    flex: 1;
    overflow: hidden;

    &-selector {

      &-time {
        width: px2rem(122px);
        height: px2rem(24px);
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: px2rem(12px);
        background-color: white;
        padding: 0 px2rem(10px);
        border: solid px2rem(1px) #EEEEEE;
        border-radius: px2rem(6px);

        &-icon {
          width: px2rem(15px);
          height: px2rem(16px);
          object-fit: cover;
        }

        &-arrow {
          transform: rotate(90deg);
          width: px2rem(6px);
          height: px2rem(5px);
        }
      }
    }

    &-tips {
      font-size: px2rem(13px);
      color: #B7B7B7;
      width: 100%;
      text-align: center;
      margin-top: px2rem(10px);
    }
  }

  &__item {
    padding: px2rem(16px) 0;
    border-bottom: solid px2rem(1px) #EEEEEE;

    &-main {
      color: #121212;
      display: flex;
      justify-content: space-between;
      font-size: px2rem(16px);
    }

    &-sub {
      margin-top: px2rem(8px);
      color: #999999;
      display: flex;
      justify-content: space-between;
    }

    &-coins {
      display: flex;
      align-items: center;
      font-size: px2rem(13px);

      img {
        width: px2rem(22px);
        height: px2rem(22px);
        object-fit: cover;
        margin-left: px2rem(6px);
      }
    }
  }
}

.pg-lovecard-detail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: px2rem(88px);
  background-color: #FAFAFA;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .cmp-lovecard-item {
    margin: px2rem(15px) px2rem(15px) 0;
  }

  &__limit {
    margin: px2rem(15px) px2rem(15px) 0;
    width: px2rem(345px);
    height: px2rem(46px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    font-size: px2rem(13px);
    background-color: #F3F3F3;
    border-radius: px2rem(6px);
    padding: 0 px2rem(15px);
    box-sizing: border-box;

    &-info {
      padding-right: px2rem(15px);
      position: relative;

      &:after {
        content: '';
        width: px2rem(11px);
        height: px2rem(11px);
        background-image: url('./Images/arrow.png');
        background-position: center;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .adm-tabs {
    margin: px2rem(15px) px2rem(15px) 0;

    &-header {
      border-top: solid 1px var(--adm-color-border);
      border-bottom: none;
    }

    &-tab {
      color: #B7B7B7;
      font-size: px2rem(14px);
      margin: 0;

      &-wrapper {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-active {
        color: #333333;
      }

      &-line {
        background-color: #333333;
      }
    }
  }

  .adm-popup-body {
    border-radius: px2rem(12px) px2rem(12px) 0 0;
    overflow: hidden;
  }
}

.cmp-unbind {
  &__title {
    width: 100%;
    height: px2rem(53px);
    border-bottom: solid px2rem(1px) rgba($color: #999999, $alpha: .4);
    line-height: px2rem(53px);
    text-align: center;
    position: relative;
    font-size: px2rem(18px);
    color: black;

    span {
      position: absolute;
      right: px2rem(15px);
      font-size: px2rem(15px);
      color: #999999;
    }
  }

  &__bottom {
    padding-bottom: px2rem(49px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__btn-unbind,
  &__btn-confirm {
    width: px2rem(345px);
    height: px2rem(38px);
    border-radius: px2rem(20px);
    line-height: px2rem(38px);
    text-align: center;
  }

  &__btn-unbind {
    background-color: #F0F0F0;
    color: #B7B7B7;
    margin-bottom: px2rem(13px);
  }

  &__btn-confirm {
    background: linear-gradient(to right, #1FF5A9, #00E0D5);
    color: #FFFFFF;
  }

  .cmp-pane {
    height: px2rem(340px);
    margin: px2rem(15px);

    &__title {
      margin-left: 0;
      color: #333333;
    }
  }

}

.mirror {
  .pg-lovecard-detail {
    &__limit {
      flex-direction: row-reverse;

      &-info {
        padding-left: px2rem(15px);
        padding-right: unset;

        &:after {
          right: unset;
          left: 0;
          transform: translateY(-50%) rotateZ(180deg);
        }
      }
    }
  }

  .cmp-record {
    &__list {
      &-selector {
        display: flex;
        justify-content: flex-end;

        &-time {
          flex-direction: row-reverse;
        }
      }
    }
  }
}