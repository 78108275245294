@import '~@/common.scss';

.pg-private-wallet {
    background: #29005D;
    min-height: 100vh;
    padding: 0 px2rem(15px);

    .cmp-pane {
        &__title {
            color: #fff;
            margin: px2rem(15px) 0;

            &::before {
                background: linear-gradient(to bottom, #F5FF00, #F7652F);
            }
        }
    }

    .wallet-header-root {
        .wallet-header {
            background: #29005D;
        }
    }

    &-content {
        &__notices {
            height: px2rem(303px);
            background: rgba($color: #fff, $alpha: 0.2);
            border-radius: px2rem(6px);
            padding: px2rem(15px);
            box-sizing: border-box;
            color: #fff;
            display: flex;
            flex-direction: column;
            margin-bottom: px2rem(30px);

            &-fee {
                font-weight: bold;
                font-size: px2rem(18px);
                color: #FFAF00;
                margin: px2rem(10px) 0;
            }

            &-line {
                width: 100%;
                height: px2rem(1px);
                background: #D8D8D8;
                opacity: 0.1;
                margin: px2rem(15px) 0;
            }

            &-tips {
                font-size: px2rem(12px);
                color: #999999;
            }
        }

        &__shipping {
            padding-bottom: px2rem(13px);

            &-item {
                display: flex;
                flex-direction: column;
                border-radius: px2rem(6px);
                border: px2rem(1px) solid rgba($color: #FFAD49, $alpha: 0.9);
                padding: px2rem(11px);
                box-sizing: border-box;
                margin-bottom: px2rem(13px);
            }

            &-item__top {
                display: flex;
                align-items: center;

                &>div {
                    display: flex;
                    flex-direction: column;
                    color: #fff;

                    &>span:first-child {
                        font-weight: bold;
                        font-size: px2rem(13px);
                        margin-bottom: px2rem(8px);
                    }

                    &>span:last-child {
                        font-size: px2rem(11px);
                    }
                }
            }

            &-item__avatar {
                width: px2rem(55px);
                height: px2rem(55px);
                border-radius: 50%;
                object-fit: contain;
                margin-right: px2rem(11px);
            }

            &-item__arrow {
                width: px2rem(16px);
                height: px2rem(16px);
                margin-left: auto;
                transform: scaleX(-1);
            }

            &-item__bottom {
                margin-top: px2rem(10px);
                display: flex;
                flex-wrap: wrap;

                &>span {
                    font-size: px2rem(13px);
                    color: #FFFFFF;
                    margin-right: px2rem(6px);
                }
            }

            &-item__country {
                font-size: px2rem(13px);
                color: rgba($color: #FFFFFF, $alpha: 0.6);
                margin-right: px2rem(10px);
                margin-bottom: px2rem(11px);
                display: flex;
                align-items: center;

                &>img {
                    width: px2rem(21px);
                    height: px2rem(14px);
                    object-fit: contain;
                    margin-right: px2rem(4px);
                }
            }
        }
    }
}

.mirror .pg-private-wallet {
    direction: rtl;

    &-content {
        &__shipping {

            &-item__avatar {
                margin-right: unset;
                margin-left: px2rem(11px);
            }

            &-item__arrow {
                margin-left: unset;
                margin-right: auto;
                transform: scaleX(1);
            }

            &-item__country {
                margin-right: unset;
                margin-left: px2rem(10px);

                &>img {
                    margin-right: unset;
                    margin-left: px2rem(4px);
                }
            }

            &-item__bottom {

                &>span {
                    margin-right: unset;
                    margin-left: px2rem(6px);
                }
            }
        }
    }
}