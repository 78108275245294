@import '../../../common.scss';

.cmp-member-historical-card {
  position: relative;
  padding-bottom: px2rem(15px);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: px2rem(6px);
  margin: 0 px2rem(15px);

  &::after {
    content: '';
    width: px2rem(340px);
    height: px2rem(1px);
    background-color: rgba($color: #999999, $alpha: .2);
    position: absolute;
    bottom: 0;
    left: px2rem(18px);
    z-index: 1;
  }

  .cmp-member-card {
    margin: 0 px2rem(10px);

    &__info {
      padding-left: unset;

      &-avatar {

        width: px2rem(50px);
        height: px2rem(50px);

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-name {
        margin-bottom: px2rem(8px);
      }

      &-No {
        font-size: px2rem(12px);
      }
    }
  }

  .cmp-number-card {
    padding: 0 px2rem(10px);

    &__item {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}