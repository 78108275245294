@import '~@/common.scss';

$prefix-cls: 'cmp-guild-create-advocacy-first';

.#{$prefix-cls} {
    padding-bottom: px2rem(20px);
    height: 100%;

    &>main {
        padding: 0 px2rem(15px);
    }

    .cmp-pane {
        &__title {
            color: #fff;
            margin: px2rem(15px) 0;

            &::before {
                background: linear-gradient(to bottom, #F5FF00, #F7652F);
            }
        }
    }

    &__type {
        height: px2rem(170px);
        background: rgba($color: #FFFFFF, $alpha: 0.16);
        border-radius: px2rem(6px);
        display: flex;
        flex-direction: column;
        padding: px2rem(15px);
        box-sizing: border-box;

        &-title {
            color: #FFFFFF;
            margin-bottom: px2rem(10px);
        }

        &-title-info {
            color: #A999BE;
        }

        &-line {
            width: 100%;
            margin: px2rem(15px) 0;
            height: px2rem(1px);
            background: rgba($color: #D8D8D8, $alpha: 0.1);
        }
    }

    &__how {
        display: flex;
        flex-direction: column;

        &-title {
            color: #A999BE;
        }

        &-box {
            width: 100%;
            height: px2rem(208px);
            background: rgba($color: #FFFFFF, $alpha: 0.16);
            position: relative;
            margin: px2rem(10px) 0;

            &>img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-text1 {
            position: absolute;
            top: px2rem(15px);
            left: px2rem(15px);
            color: #FFFFFF;
        }

        &-text2 {
            position: absolute;
            top: px2rem(15px);
            left: px2rem(192px);
            color: #FFFFFF;
        }
    }

    &__btn {
        background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
        border: px2rem(1px) solid #F9AF4A;
        box-sizing: border-box;
        border-radius: px2rem(22px);
        height: px2rem(46px);
        margin: 0 px2rem(15px);
        color: #FFFFFF;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: px2rem(50px);
    }
}

.mirror .#{$prefix-cls} {
    direction: rtl;
}