@import '../common.scss';

.cmp-pane {
  &__title {
    font-size: px2rem(15px);
    margin: 0 px2rem(15px) px2rem(15px);
    color: white;
    font-weight: bold;
    text-align: left;
    display: flex;
    align-items: center;
  }
}

.pane-prefix {

  .cmp-pane__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #333333;
    font-size: px2rem(16px);

    &::before {
      content: '';
      width: px2rem(3px);
      height: px2rem(15px);
      border-radius: px2rem(2px);
      background: linear-gradient(to bottom, rgba(31, 245, 169, 1), rgba(0, 224, 213, 1));
    }

    &>span {
      margin: 0 px2rem(7px);
    }
  }
}

.mirror {
  .cmp-pane {
    &__title {
      direction: rtl;
    }
  }
}