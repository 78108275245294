@import '~@/common.scss';

.cmp-coins-transfer-country {
  width: 100%;
  box-sizing: border-box;
  color: #FFFFFF;

  &-list {
    height: px2rem(405px);
    overflow-y: auto;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px2rem(15px) 0;
    border-bottom: px2rem(1px) solid rgba($color: #EEEEEE, $alpha: 0.2);

    &-left {
      display: flex;

      span {
        margin-left: px2rem(9px);
        vertical-align: middle;
      }

      img {
        width: px2rem(30px);
        height: px2rem(20px);
      }
    }

    &-select {
      height: px2rem(17px);
      width: px2rem(17px);
    }
  }

  &-title {
    font-size: px2rem(18px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: px2rem(60px);
    border-bottom: px2rem(1px) solid rgba($color: #EEEEEE, $alpha: 0.2);
    position: relative;
  }

  &-close-down {
    width: px2rem(15px);
    height: px2rem(9px);
    object-fit: contain;
  }

  &-confirm {
    font-weight: 400;
    font-size: px2rem(15px);
    color: #F9AE49;
  }

  &-title-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .adm-search-bar {
    &-input-box {
      background: rgba($color: #F3F3F3, $alpha: 0.2);

      .adm-input-element {
        font-size: px2rem(13px);
        color: rgba($color: #fff, $alpha: 0.3);
      }
    }
  }

}

.mirror {
  & .cmp-coins-transfer-country {
    padding-right: px2rem(15px);

    &-item {
      flex-direction: row-reverse;
      margin-right: unset;

      &-left {
        flex-direction: row-reverse;

        span {
          margin-right: px2rem(9px);
        }
      }
    }
  }
}