@import '~@/common.scss';

.cmp-guild-invite-data-my-list-item {
    width: 100%;
    max-height: px2rem(436px);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: px2rem(6px);
    padding: 0 px2rem(15px);
    box-sizing: border-box;
    overflow-y: auto;

    &__item {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: px2rem(16px);
        color: #fff;
        position: relative;
        padding: px2rem(15px) 0;

        &:not(&:last-child) {
            border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.1);
        }

        &-avatar {
            width: px2rem(45px);
            height: px2rem(45px);
            border: px2rem(1px) solid rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;

            &>img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-user-info {
            display: flex;
            flex-direction: column;
            margin: 0 px2rem(15px);

            &>span:first-child {
                font-size: px2rem(16px);
                color: #FFFFFF;
                margin-bottom: px2rem(6px);
            }

            &>span:last-child {
                font-size: px2rem(12px);
                color: rgba($color: #FFFFFF, $alpha: 0.6);
            }
        }

        &-left {
            display: flex;
            align-items: center;
        }

        &-right {
            display: flex;
            flex-direction: column;
            color: #fff;
            font-size: px2rem(12px);
            align-items: flex-end;
        }

        &-num {
            display: flex;
            align-items: center;
            margin-bottom: px2rem(6px);

            &>span {
                margin: 0 px2rem(4px);
            }

            &>img {
                width: px2rem(13px);
                height: px2rem(13px);
                object-fit: cover;
            }
        }

        &-pay {
            margin-bottom: px2rem(15px);
        }

        &-time {
            font-size: px2rem(11px);
            color: rgba($color: #FFFFFF, $alpha: 0.6);
        }

    }
}

.mirror .cmp-guild-invite-data-my-list-item {
    direction: rtl;

    &__item {
        &-num {
            margin-left: unset;
            margin-right: auto;
        }
    }
}