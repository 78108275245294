@import '~@/common.scss';

.pg-guild-create-agency {
    background: #FAFAFA;
    box-sizing: border-box;
    padding: 0 px2rem(15px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &>.content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        flex-grow: 1;
    }

    .cmp-pane {
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: px2rem(30px);
        margin-bottom: px2rem(15px);

        .cmp-pane__title {
            font-size: px2rem(16px);
            font-weight: 500;
            color: #333333;
            position: relative;
            margin: 0;
            margin-bottom: px2rem(10px);
        }
    }

    .cmp-pane:first-child {
        margin-top: px2rem(18px);
    }

    .input-tips,
    .input-tips-check,
    .tips-please-contact,
    .tips-contact-info {
        font-size: px2rem(13px);
        font-weight: 500;
        color: #999999;
    }

    .box {
        width: 100%;
        height: px2rem(70px);
        background: #F3F3F3;
        border-radius: px2rem(6px);
        box-sizing: border-box;
        padding: 0 px2rem(10px);
        margin-top: px2rem(15px);
        display: flex;
        align-items: center;

        .input-box {
            display: flex;
            align-items: center;
            width: 100%;

            input {
                all: unset;
                flex-grow: 1;
                font-size: px2rem(13px);
            }

            .confirm {
                width: px2rem(80px);
                height: px2rem(30px);
                background: linear-gradient(to bottom, rgba(31, 245, 169, 1), rgba(0, 224, 213, 1));
                border-radius: px2rem(30px);
                text-align: center;
                line-height: px2rem(30px);
                font-size: px2rem(13px);
                font-weight: 500;
                color: #FFFFFF;
            }
        }

        .input-user {
            display: flex;
            align-items: center;
            width: 100%;

            .avatar {
                width: px2rem(35px);
                height: px2rem(35px);
                object-fit: cover;
                border-radius: 50%;
            }

            .info {
                display: flex;
                flex-direction: column;
                margin: 0 px2rem(10px);
                flex-grow: 1;

                &>span:first-child {
                    font-size: px2rem(13px);
                    color: #000000;
                    font-weight: bold;
                    margin-bottom: px2rem(4px);
                }

                &>span:last-child {
                    font-size: px2rem(11px);
                    color: #B7B7B7;
                    max-width: px2rem(260px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .middle {
                    display: flex;
                    align-items: center;
                    margin-bottom: px2rem(4px);

                    &>span:first-child {
                        font-size: px2rem(11px);
                        color: #B7B7B7;
                    }

                    .people-icon {
                        margin: 0 px2rem(20px);

                        img {
                            width: px2rem(10px);
                            height: px2rem(10px);
                            object-fit: cover;
                        }

                        span {
                            font-size: px2rem(11px);
                            color: #B7B7B7;
                            margin: 0 px2rem(2px);
                        }
                    }
                }
            }

            .cancel {
                width: px2rem(22px);
                height: px2rem(22px);
                background: transparent url('~@/images/close.png') no-repeat;
                background-size: cover;
            }
        }
    }


    .input-tips-check {
        margin-top: px2rem(15px);
        font-size: px2rem(14px);
    }

    .tips-please-contact {
        font-size: px2rem(13px);
        margin-bottom: px2rem(30px);
    }

    .tips-whatapp {
        margin-top: px2rem(30px);
        font-size: px2rem(15px);
        font-weight: 500;
        color: #000000;

        &:last-child {
            margin-top: px2rem(10px);
        }
    }

    .confirm-btn {
        width: px2rem(345px);
        height: px2rem(38px);
        border-radius: px2rem(20px);
        background-color: rgba(240, 240, 240, 1);
        border: unset;
        font-size: px2rem(14px);
        font-weight: 500;
        color: #B7B7B7;
        margin-top: auto;
        margin-bottom: px2rem(30px);
    }

    .btn-blue {
        color: #fff;
        background: linear-gradient(to bottom, rgba(31, 245, 169, 1), rgba(0, 224, 213, 1));
    }
}

.mirror .pg-guild-create-agency {
    &>.content {
        direction: rtl;
    }
}