@import '~@/common.scss';

.pg-guild-invite-data {
    &>main {
        padding: 0 px2rem(15px);
        display: flex;
        flex-direction: column;
        align-items: center;

        &>.hide-div {
            width: 100%;
        }
    }

    .cmp-guild-tab-buttons {
        margin: px2rem(15px) 0;

        &__tab {
            &-item {
                padding: px2rem(8px) px2rem(12px);
            }
        }
    }

    .cmp-scroll-tabs {
        font-size: px2rem(16px);
        height: px2rem(40px);
        color: rgba($color: #fff, $alpha: 0.6);

        &>.selected {
            color: rgba($color: #fff, $alpha: 1);

            &::after {
                width: px2rem(20px);
                height: px2rem(3px);
                background-color: #fff;
                bottom: px2rem(10px);
            }
        }
    }

    .cmp-number-card {
        padding: unset;
        margin-bottom: px2rem(15px);
    }

    &__header-img {
        position: absolute;
        bottom: 0;
        width: px2rem(45px);
        height: px2rem(45px);
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;

        img {
            width: px2rem(16px);
            height: px2rem(16px);
            object-fit: cover;
        }
    }
}

.mirror .pg-guild-invite-data {}