@import '~@/common.scss';

.cmp-anchorman-wallet-detail-popup-swiper {
    .exchange-pane {
        .cmp-pane__title {
            font-size: px2rem(16px);
            font-weight: 500;
            color: #333333;
        }
    }
}