@import '~@/common.scss';

.cmp-wallet-withdraw-card {
    &__main {
        width: 100%;
        height: px2rem(248px);
        background: url('~@/images/wallet/withdraw-card-black.webp') no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        flex-direction: column;

        &>.top {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            box-sizing: border-box;
            padding: px2rem(13px) px2rem(15px) px2rem(14px) px2rem(52px);

            &>.title {
                font-size: px2rem(14px);
                color: #BEBEBE;
            }

            &>.edit {
                width: px2rem(60px);
                height: px2rem(26px);
                border-radius: px2rem(18px);
                background: linear-gradient(to bottom, rgba(255, 192, 109, 1), rgba(170, 113, 57, 1));
                font-size: px2rem(12px);
                font-weight: 500;
                color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &>.content {
            flex-grow: 1;
            box-sizing: border-box;
            padding: 0 px2rem(15px);

            &>.by-myself {
                &>.item {
                    margin-top: px2rem(15px);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &>.title {
                        font-size: px2rem(14px);
                        color: #FFA91E;
                    }

                    &>.info {
                        width: px2rem(243px);
                        height: px2rem(30px);
                        background: #FFFFFF;
                        border-radius: px2rem(5px);
                        background-color: rgba(255, 255, 255, 0.1);
                        font-size: px2rem(12px);
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        padding: 0 px2rem(10px);
                        box-sizing: border-box;

                        &>img {
                            width: px2rem(20px);
                            height: px2rem(20px);
                            border-radius: 50%;
                            object-fit: cover;
                            margin-right: px2rem(5px);
                        }
                    }
                }
            }

            &>.by-agency,
            &>.dont-have {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: px2rem(16px);
                color: #FFFFFF;

                span {
                    margin-bottom: px2rem(30px);
                }
            }
        }
    }

    &>.main2 {
        background-image: url('~@/images/wallet/withdraw-card-green.webp');

        &>.top {

            &>.title {
                color: #fff;
            }
        }
    }
}

.mirror .cmp-wallet-withdraw-card {
    &__main {
        transform: scaleX(-1);

        &>.top {
            transform: scaleX(-1);
            padding: px2rem(13px) px2rem(52px) px2rem(14px) px2rem(15px);
            direction: rtl;
        }

        &>.content {
            transform: scaleX(-1);

            &>.by-myself {
                direction: rtl;

                &>.item {
                    &>.info {
                        &>img {
                            margin-right: unset;
                            margin-left: px2rem(5px);
                        }
                    }
                }
            }
        }
    }
}