@import '~@/common.scss';

.cmp-lovecard-tabs {
  color: #B7B7B7;
  padding: px2rem(8px) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  &__tab {
    &:not(:first-child) {
      margin-left: px2rem(44px);
    }

    &.selected {
      color: #333333;
      font-weight: bold;
      position: relative;

      &::after {
        content: '';
        width: px2rem(15px);
        height: px2rem(3px);
        background-color: #333333;
        position: absolute;
        bottom: px2rem(-6px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.pg-lovecard {
  padding-top: px2rem(88px);

  .adm-tabs {
    &-tab {
      color: #B7B7B7;
      font-size: px2rem(16px);

      &-active {
        color: #333333;
      }

      &-line {
        background-color: #333333;
      }
    }
  }
}