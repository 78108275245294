@import '~@/common.scss';

.share-content {
  width: px2rem(375px);
  height: px2rem(196px);
  background: linear-gradient(to bottom, rgba(#1c5319, 1), rgba(#0e2e02, 1));
  border-radius: px2rem(8px) px2rem(8px) 0 0;
  border-top: px2rem(2px) #f4d200 solid;

  & .title {
    font-size: px2rem(18px);
    font-weight: 400;
    color: #f0ff00;
    width: 100%;
    text-align: center;
    margin: px2rem(15px) 0 px2rem(30px) 0;
  }

  .share-list {
    display: flex;
    justify-content: space-around;

    &-item {
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: px2rem(50px);
        height: px2rem(50px);
        margin-bottom: px2rem(6px);
      }

      span {
        font-size: px2rem(14px);
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}