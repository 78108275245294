@import '../../../common.scss';

.pg-historical-data .search-root {
    width: 100%;
    height: px2rem(36px);
    box-sizing: border-box;
    padding: 0 px2rem(15px);
    display: flex;
    justify-content: center;

    .content {
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: px2rem(6px);
        display: flex;
        align-items: center;
        padding: 0 px2rem(10px);
    }

    .icon {
        width: px2rem(16px);
        height: px2rem(16px);
        margin-right: px2rem(6px);
    }

    .inp {
        width: 100%;
        background: none;
        border: none;
        outline: none;
        color: rgba(183, 183, 183, 1);
        font-size: px2rem(12px);
    }
}

.mirror .pg-historical-data .search-root {
    direction: rtl;

    .icon {
        margin-right: unset;
        margin-left: px2rem(6px);
    }
}