@import '~@/common.scss';

.wallet-gem-rule-table {

    .table-head,
    .table-item {
        div {
            width: px2rem(86px);
            height: px2rem(35px);
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba($color: #999, $alpha: 0.2);
        }
    }

    .table-head {
        width: 100%;
        font-size: px2rem(12px);
        text-align: center;
        color: rgba($color: #000, $alpha: 0.6);
        display: flex;

        div {

            &:not(:last-child) {
                border-right: none;
            }

            &:first-child {
                border-top-left-radius: px2rem(6px);
            }

            &:last-child {
                border-top-right-radius: px2rem(6px);
            }
        }
    }

    .table-item {
        color: #000;
        display: flex;

        div {
            &:not(:last-child) {
                border-right: none;
            }
        }

        &:not(:last-child) {
            div {
                border-bottom: none;
            }
        }

        &:nth-child(2) {
            div {
                border-top: none;
            }
        }

        &:last-child {
            div {
                &:not(:last-child) {
                    border-right: none;
                }

                &:first-child {
                    border-bottom-left-radius: px2rem(6px);
                }

                &:last-child {
                    border-bottom-right-radius: px2rem(6px);
                }
            }
        }


        img {
            width: px2rem(20px);
            height: px2rem(20px);
            object-fit: cover;
        }
    }
}

.mirror .wallet-gem-rule-table {
    direction: rtl;

    .table-head {
        div {

            &:not(:last-child) {
                border-right: 1px solid rgba($color: #999, $alpha: 0.2);
                border-left: unset;
            }

            &:first-child {
                border-radius: unset;
                border-top-right-radius: px2rem(6px);
            }

            &:last-child {
                border-radius: unset;
                border-top-left-radius: px2rem(6px);
            }
        }
    }

    .table-item {

        div {
            &:not(:last-child) {
                border-right: 1px solid rgba($color: #999, $alpha: 0.2);
                border-left: unset;
            }
        }

        &:last-child {
            div {
                &:not(:last-child) {
                    border-right: 1px solid rgba($color: #999, $alpha: 0.2);
                    border-left: unset;
                }

                &:first-child {
                    border-radius: unset;
                    border-bottom-right-radius: px2rem(6px);
                }

                &:last-child {
                    border-radius: unset;
                    border-bottom-left-radius: px2rem(6px);
                }
            }
        }


        img {
            width: px2rem(20px);
            height: px2rem(20px);
            object-fit: cover;
        }
    }
}