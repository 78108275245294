@import '~@/common.scss';

.cmp-select-time-range {
  width: px2rem(193px);
  height: px2rem(24px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #FFFFFF, $alpha: .1);
  border-radius: px2rem(6px);
  padding: 0 px2rem(10px);

  &__calendar {
    width: px2rem(15px);
    height: px2rem(16px);
    margin-right: px2rem(4px);
  }

  &__time {
    color: rgba($color: #FFFFFF, $alpha: .6);
    font-size: px2rem(12px);
    flex: 1;
    text-align: center;
  }

  &__delta {
    width: px2rem(6px);
    height: px2rem(5px);
    margin-left: px2rem(4px);
  }
}