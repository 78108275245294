@import '~@/common.scss';

.cmp-guild-task-progress {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: px2rem(6px);
    padding: px2rem(15px);
    box-sizing: border-box;

    .cmp-scroll-tabs {
        font-size: px2rem(12px);
        height: px2rem(40px);
        color: rgba($color: #fff, $alpha: 0.6);
        margin: 0;

        &>.selected {
            color: rgba($color: #fff, $alpha: 1);

            &::after {
                width: px2rem(20px);
                height: px2rem(3px);
                background-color: #fff;
                bottom: px2rem(14px);
            }
        }
    }

    &>.progress-item {
        padding: px2rem(15px) 0;
        border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.1);

        &:last-child {
            border-bottom: none;
        }

        &>.top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
                font-size: px2rem(13px);
                font-weight: 500;
                color: #FFFFFF;
            }

            &>.left {
                display: flex;
                align-items: center;
                word-break: break-word;
            }

            &>.right {
                display: flex;
                align-items: center;

                &>.name {
                    margin: 0 px2rem(4px);
                    white-space: nowrap;
                }

                &>.img {
                    width: px2rem(13px);
                    height: px2rem(13px);
                    object-fit: cover;
                    // background: url('~@/images/gem.png') no-repeat;
                    // background-size: cover;
                }
            }
        }

        &>.middle {
            // margin: px2rem(10px) 0;
            padding-right: px2rem(20px);
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;

            .adm-progress-bar-trail {
                background: rgba(255, 255, 255, 0.20);

                .adm-progress-bar-fill {
                    background: linear-gradient(to right, #1FF5A9, #00E0D5);
                }
            }
        }

        &>.bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: px2rem(13px);
            color: #B7B7B7;
            flex-wrap: wrap;
            word-break: break-word;
        }
    }
}

.mirror .cmp-guild-task-progress {
    direction: rtl;

    &>.progress-item {
        &>.middle {
            padding-right: unset;
            padding-left: px2rem(20px);
        }
    }
}