@import '~@/common.scss';

.wallet-coin-exchange-select {
    &__coin {
        margin-top: px2rem(15px);
        display: grid;
        width: 100%;
        grid-template-columns: px2rem(165px) px2rem(165px);
        grid-gap: px2rem(10px) px2rem(11px);

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: px2rem(80px);
            border-radius: px2rem(10px);
            border: solid px2rem(1px) #E8E8E8;
            background-color: white;
            box-sizing: border-box;

            &.selected {
                border-color: #10EBBE;
                background-color: #E1FFF9;
            }
        }

        &-box {
            display: flex;
            align-items: center;
        }

        &-icon {
            width: px2rem(18px);
            height: px2rem(18px);
        }

        &-num {
            font-size: px2rem(15px);
            font-weight: bold;
            color: #000000;
            margin: 0 px2rem(7px);
        }

        &-price {
            font-size: px2rem(12px);
            color: #B7B7B7;
            margin-top: px2rem(15px);
        }

        &-custom-text {
            font-size: px2rem(15px);
            color: #B7B7B7;
        }
    }
}

.mirror .wallet-coin-exchange-select {
    direction: rtl;
}