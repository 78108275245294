@import '../../../common.scss';

.cmp-vip-progress {
  position: relative;
  align-items: center;
  padding-left: px2rem(60px);
  &__icon {
    position: absolute;
    z-index: 1;
    left: 0;
    top: px2rem(-10px);
    width: px2rem(65px);
    height: px2rem(30px);
  }
  &__content {
    position: relative;
    display: flex;
    width: px2rem(131px);
    height: px2rem(13px);
    background-image: url('../../../images/guild-lv1-progress-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    &-val {
      background: linear-gradient(to bottom, #D8D8FF, #4192FF);
      height: px2rem(7px);
      position: absolute;
      left: px2rem(3px);
      top: px2rem(3px);
    }
    &.lv2 {
      background-image: url('../../../images/guild-lv2-progress-bg.png');
      &-val {
        background: linear-gradient(to bottom, #FF8590, #C21B1B);
      }
    }
    &.lv3 {
      background-image: url('../../../images/guild-lv3-progress-bg.png');
      &-val {
        background: linear-gradient(to bottom, #C6E0FF, #0068FF);
      }
    }
    &.lv4 {
      background-image: url('../../../images/guild-lv4-progress-bg.png');
      &-val {
        background: linear-gradient(to bottom, #FFB600, #FFFDE2);
      }
    }
    &.lv5 {
      background-image: url('../../../images/guild-lv5-progress-bg.png');
      &-val {
        background: linear-gradient(to bottom, #FFAB00, #FFEF00);
      }
    }
  }
}

.mirror {
  .cmp-vip-progress {
    padding-left: 0;
    padding-right: px2rem(60px);
    &__icon {
      left: unset;
      right: 0;
    }
  }
}