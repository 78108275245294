@import '~@/common.scss';

.wallet-convertible-gem-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .gem-item {
        width: px2rem(165px);
        height: px2rem(80px);
        border-radius: px2rem(10px);
        background-color: #fff;
        border: px2rem(1px) solid rgba(232, 232, 232, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: px2rem(15px);

        .coin {
            display: flex;
            align-items: center;

            img {
                width: px2rem(18px);
                height: px2rem(18px);
                object-fit: cover;
            }

            span {
                font-size: px2rem(15px);
                font-weight: bold;
                color: #FFAF00;
                margin: 0 px2rem(6px);
            }
        }

        .gem {
            display: flex;
            align-items: center;
            margin-top: px2rem(10px);

            img {
                width: px2rem(14px);
                height: px2rem(14px);
                object-fit: cover;
            }

            span {
                font-size: px2rem(12px);
                font-weight: 400;
                color: #B7B7B7;
                margin: 0 px2rem(4px);
            }
        }
    }
}

.mirror .wallet-convertible-gem-list {
    direction: rtl;
}