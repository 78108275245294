@import '~@/common.scss';

.agent-recharge-cash-transfer-warning {
    .cmp-agent-recharge-status-user-info {
        &>.info {
            .coin {
                .img {
                    background-image: url('~@/images/money.webp');
                }
            }
        }
    }
}