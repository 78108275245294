@import '../../../common.scss';

// .cmp-update-avatar {
//   position: relative;
//   width: px2rem(97px);
//   height: px2rem(97px);
//   border-radius: px2rem(12px);
//   overflow: hidden;
//   &__pic {
//     width: 100%;
//     height: 100%; 
//   }
//   &__camera {
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     width: px2rem(23px);
//     height: px2rem(23px);
//     z-index: 1;
//   }
//   &__uploader {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     z-index: 2;
//     opacity: 1;
//   }
// }
.cmp-update-avatar {
  position: relative;
  border-radius: px2rem(12px);
  overflow: hidden;
  width: px2rem(97px);
  height: px2rem(97px);
  &__pic {
    width: 100%;
    height: 100%;
    display: block;
  }
  &__camera {
    position: absolute;
    bottom: 0;
    right: 0;
    width: px2rem(23px);
    height: px2rem(23px);
    z-index: 1;
  }
}