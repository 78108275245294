@import '~@/common.scss';

.cmp-status-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: px2rem(26px) 0;

    &>img {
        width: px2rem(124px);
        height: px2rem(92px);
        object-fit: cover;
    }

    &>span {
        font-size: px2rem(16px);
        font-weight: bold;
        margin-top: px2rem(6px);
    }
}