@import '~@/common.scss';

.cmp-identity-card {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: px2rem(6px);
    padding: px2rem(18px) px2rem(10px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-user {
        display: flex;
        align-items: center;

        &__avatar {
            width: px2rem(63px);
            height: px2rem(63px);
            border-radius: 50%;
            object-fit: cover;
        }

        &__info {
            display: flex;
            flex-direction: column;
            margin: 0 px2rem(10px);
        }

        &__nick {
            font-size: px2rem(13px);
            color: #FFFFFF;
            margin-bottom: px2rem(6px);
        }

        &__id {
            font-size: px2rem(11px);
            color: rgba($color: #fff, $alpha: 0.6);
            margin-bottom: px2rem(6px);
        }

        &__identity {
            border: px2rem(1px) solid rgba(16, 235, 190, 1);
            border-radius: px2rem(999999px);
            font-size: px2rem(11px);
            color: #FFFFFF;
            padding: px2rem(3px) px2rem(10px);
        }
    }

    &-switch {
        font-size: px2rem(11px);
        color: #fff;
        font-weight: 500;
        border: px2rem(1px) solid rgba(255, 220, 0, 1);
        background: linear-gradient(to bottom, rgba(53, 184, 56, 1), rgba(9, 100, 5, 1));
        border-radius: px2rem(6px);
        padding: px2rem(6px) px2rem(10px);
        display: flex;
        align-items: center;

        &>img {
            width: px2rem(13px);
            height: px2rem(10px);
            object-fit: cover;
            margin-right: px2rem(6px);
        }

    }
}

.mirror .cmp-identity-card {
    direction: rtl;

    &-switch {
        &>img {
            margin-right: unset;
            margin-left: px2rem(6px);
        }
    }
}