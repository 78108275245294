@import '~@/common.scss';

.cmp-guild-tab-buttons {
    width: 100%;
    position: relative;
    z-index: 20;

    &__tab {
        font-size: px2rem(16px);
        display: flex;
        width: 100%;
        overflow-x: auto;

        &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: px2rem(6px) px2rem(38px);
            flex-shrink: 0;
            box-sizing: border-box;
            margin: 0 auto;

            .tab-item {
                &__name {
                    // padding: px2rem(6px) px2rem(14px);
                    color: rgba($color: #FFFFFF, $alpha: .6);
                }
            }

            &.selected {
                color: #10EBBE;
                border: solid px2rem(2px) #10EBBE;
                border-radius: px2rem(999999px);

                .tab-item {
                    &__name {
                        color: #10EBBE;
                    }
                }
            }
        }

        .scroll {
            &:first-child {
                margin-left: unset;
            }

            &:last-child {
                margin-right: unset;
            }
        }
    }
}

.mirror .cmp-guild-tab-buttons {
    direction: rtl;

    &__tab {
        .scroll {
            &:first-child {
                margin-left: auto;
                margin-right: unset;
            }

            &:last-child {
                margin-right: auto;
                margin-left: unset;
            }
        }
    }
}