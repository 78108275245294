@import '~@/common.scss';

.cmp-collection-information-target-org {
  width: 100%;
  box-sizing: border-box;
  color: #FFFFFF;

  &-list {
    height: px2rem(405px);
    overflow-y: auto;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px2rem(15px);
    background-color: rgba(240, 240, 240, 0.1);
    border-radius: px2rem(6px);
    margin-bottom: px2rem(10px);

    &-select {
      height: px2rem(17px);
      width: px2rem(17px);
    }
  }

  &-title {
    font-size: px2rem(18px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: px2rem(60px);
    border-bottom: px2rem(1px) solid rgba($color: #EEEEEE, $alpha: 0.2);
    margin-bottom: px2rem(6px);
  }

}

.mirror {
  & .cmp-collection-information-target-org {
    direction: rtl;
  }
}