@import '~@/common.scss';

.pg-lovecard-friends {
  padding-top: px2rem(88px);

  &__item {
    padding: px2rem(15px);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: px2rem(345px);
      height: px2rem(1px);
      background-color: #EEEEEE;
      bottom: 0;
      right: px2rem(15px);
    }
  }

  .cmp-pane {
    margin-top: px2rem(15px);

    &__title {
      color: #333333;
      margin-bottom: 0;
    }
  }

  .cmp-user {
    &__avatar {
      width: px2rem(55px);
      height: px2rem(55px);
    }

    &__info {
      font-size: px2rem(13px);
    }

    &__nick {
      max-width: px2rem(120px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__id {
      color: #B7B7B7;
    }

    &__role {
      background: linear-gradient(to right, #1FF5A9, #00E0D5);
      font-size: px2rem(12px);
    }
  }
}