@import '~@/common.scss';

.pg-wallet-withdraw-success {
    padding-bottom: px2rem(80px);

    &>.cmp-wallet-status {
        &>.cmp-pane {
            &>.by-myself {
                &>.money-info {
                    width: 100%;
                    height: px2rem(55px);
                    background: #F3F3F3;
                    border-radius: px2rem(6px);
                    margin: px2rem(15px) 0;
                    display: flex;
                    align-items: center;
                    padding: 0 px2rem(15px);
                    box-sizing: border-box;

                    img {
                        width: px2rem(16px);
                        height: px2rem(16px);
                        object-fit: cover;
                    }

                    span {
                        font-size: px2rem(13px);
                        color: #000000;
                        margin: 0 px2rem(6px);
                    }
                }

                &>.fee {
                    display: flex;
                    margin-top: px2rem(11px);

                    &>div {
                        width: px2rem(117px);
                        height: px2rem(30px);
                        background: url('~@/images/wallet/withdraw-fee-bg2.webp') no-repeat;
                        background-size: cover;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: px2rem(13px);
                        font-weight: 500;
                        color: #FFFFFF;
                    }

                    &>div:last-child {
                        width: px2rem(170px);
                        background-image: url('~@/images/wallet/withdraw-fee-bg1.webp');
                        margin: 0 px2rem(10px);
                    }
                }
            }
        }
    }
}

.mirror .pg-wallet-withdraw-success {
    direction: rtl;
}