@import '~@/common.scss';

.pg-guild-create-agency-submit {
    background: #FAFAFA;
    box-sizing: border-box;
    padding: 0 px2rem(15px);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;

    .wallet-header-root {
        .wallet-header {
            background: #29005D;
        }
    }

    &>.content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        min-height: 100vh;
        margin-top: px2rem(30px);
        flex-grow: 1;

        .upload {
            width: 100%;
            display: flex;
            justify-content: center;

            .btn {
                width: px2rem(97px);
                height: px2rem(97px);
                background: #EEEEEE;
                border-radius: px2rem(12px);
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: px2rem(35px);
                    height: px2rem(35px);
                    object-fit: cover;
                }
            }

            .img {
                width: px2rem(97px);
                height: px2rem(97px);
                border-radius: px2rem(12px);
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .cmp-pane {
            margin-top: px2rem(30px);

            &__title {
                margin: 0;
                font-size: px2rem(14px);
                font-weight: 600;
                color: #333333;
                margin-bottom: px2rem(10px);
            }
        }

        .input-name {
            width: 100%;
            height: px2rem(43px);
            background: #F0F0F0;
            border-radius: px2rem(6px);
            display: flex;
            align-items: center;
            padding: 0 px2rem(12px);
            box-sizing: border-box;

            input {
                all: unset;
                font-size: px2rem(13px);
                color: #333333;
            }
        }

        .input-slogan {
            .adm-text-area-element {
                color: #333333;
                background: #F0F0F0;
                height: px2rem(180px);
                border-radius: px2rem(6px);
                padding: px2rem(12px);
                font-size: px2rem(13px);

                &::placeholder {
                    color: #B7B7B7;
                }
            }
        }

        .user-info {
            width: 100%;
            height: px2rem(70px);
            background: #F3F3F3;
            border-radius: px2rem(6px);
            box-sizing: border-box;
            padding: 0 px2rem(10px);
            margin-top: px2rem(15px);
            display: flex;
            align-items: center;

            .avatar {
                width: px2rem(35px);
                height: px2rem(35px);
                object-fit: cover;
                border-radius: 50%;
            }

            .info {
                display: flex;
                flex-direction: column;
                margin: 0 px2rem(10px);
                flex-grow: 1;

                &>span:first-child {
                    font-size: px2rem(13px);
                    color: #000000;
                    font-weight: bold;
                    margin-bottom: px2rem(4px);
                }

                &>span:last-child {
                    font-size: px2rem(11px);
                    color: #B7B7B7;
                    max-width: px2rem(260px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .middle {
                    display: flex;
                    align-items: center;
                    margin-bottom: px2rem(4px);

                    &>span:first-child {
                        font-size: px2rem(11px);
                        color: #B7B7B7;
                    }

                    .people-icon {
                        margin: 0 px2rem(20px);

                        img {
                            width: px2rem(10px);
                            height: px2rem(10px);
                            object-fit: cover;
                        }

                        span {
                            font-size: px2rem(11px);
                            color: #B7B7B7;
                            margin: 0 px2rem(2px);
                        }
                    }
                }
            }

            .cancel {
                width: px2rem(22px);
                height: px2rem(22px);
                background: transparent url('~@/images/close.png') no-repeat;
                background-size: cover;
            }

            &.upload {
                background-color: unset;
                justify-content: unset;
                padding: unset;

                &>.btn {
                    width: px2rem(69px);
                    height: px2rem(70px);

                    img {
                        width: px2rem(30px);
                        height: px2rem(30px);
                    }
                }
            }
        }

        .create-btn {
            border: unset;
            height: px2rem(38px);
            border-radius: px2rem(20px);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(240, 240, 240, 1);
            margin-top: auto;
            margin-bottom: px2rem(20px);
            font-size: px2rem(14px);
            font-weight: 500;
            color: #B7B7B7;

            img {
                width: px2rem(16px);
                height: px2rem(16px);
                object-fit: cover;
                margin: 0 px2rem(4px);
            }
        }

        .create-activate-btn {
            background: linear-gradient(to bottom, #9036DB, #4B1085, #561994, #B67AFF);
            border: px2rem(1px) solid #F9AF4A;
            box-sizing: border-box;
            color: #fff;
        }
    }
}

.mirror .pg-guild-create-agency-submit {
    &>.content {
        direction: rtl;
    }

    .input-slogan {
        .adm-text-area-element {
            text-align: right;
        }
    }
}