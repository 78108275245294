@import '~@/common.scss';

.cmp-guild-invite-data-expected-pending-content {
    width: 100%;

    .cmp-scroll-table {
        .special-content {
            &__user {
                display: flex;
                flex-direction: column;

                &>span:first-child {
                    color: #fff;
                }
            }

            &__topup {
                display: flex;
                align-items: center;

                &>span {
                    margin: 0 px2rem(5px);
                    color: #fff;
                }

                &>img {
                    width: px2rem(13px);
                    height: px2rem(13px);
                }
            }
        }
    }

    .cmp-guild-wallet-data-calculate-data {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: px2rem(6px);
        margin-bottom: px2rem(15px);

        &::after,
        &::before {
            all: unset;
        }

        &>.content {
            width: 100%;
            min-height: px2rem(60px);
            justify-content: space-around;

            &>.item {
                min-height: px2rem(60px);
                border-radius: px2rem(4px);
                width: px2rem(65px);
                flex: unset;
                padding: 0;
                box-sizing: unset;
                padding-top: px2rem(5px);

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    &::after {
                        content: '';
                        position: absolute;
                        right: px2rem(-13px);
                        top: px2rem(5px);
                        font-size: px2rem(16px);
                    }
                }

                &:nth-child(1) {
                    &::after {
                        content: '=';
                    }
                }

                &:nth-child(2) {
                    &::after {
                        content: 'x';
                    }
                }

                &:nth-child(3) {
                    &::after {
                        content: '+';
                    }
                }
            }

            &>.item:not(:first-child) {
                background-color: rgba(0, 0, 0, 0.20);
            }

            &>.item:last-child {
                width: px2rem(85px);
            }
        }
    }

    .cmp-number-card {
        padding: unset;
    }

    .cmp-pane {
        &__title {
            margin: 0;
            margin-bottom: px2rem(15px);
        }

        .cmp-guild-invite-data-rule-table {
            margin-top: px2rem(15px);
        }
    }
}

.mirror .cmp-guild-invite-data-expected-pending-content {
    .cmp-guild-wallet-data-calculate-data {
        &>.content {
            &>.item {

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    &::after {
                        right: unset;
                        left: px2rem(-13px);
                    }
                }
            }

        }
    }
}