@import '~@/common.scss';

.cmp-wallet-banner {
    position: relative;
    width: px2rem(345px);
    height: px2rem(91px);
    display: flex;
    align-items: center;
    background: url('~@/images/wallet-gem-banner.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .info {
        z-index: 2;
        display: flex;
        align-items: center;
        position: absolute;
        left: px2rem(20px);

        .img {
            width: px2rem(40px);
            height: px2rem(40px);
            object-fit: cover;
            background: url('~@/images/gem.png') no-repeat;
            background-size: cover;
        }

        span {
            margin: 0 px2rem(10px);
            font-size: px2rem(25px);
            font-weight: bold;
            color: #FFFFFF;
            @include text-stroke('#3270D4', px2rem(1px));
        }
    }
}

.mirror .cmp-wallet-banner {
    direction: rtl;
    transform: scaleX(-1);

    .info {
        transform: scaleX(-1);
    }

}