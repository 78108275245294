@import '../../common.scss';

.pg-rule-vip {
  font-size: px2rem(13px);
  font-weight: 500;
  background: #0b0b0b;
  padding: 0 px2rem(15px);
  height: px2rem(812px);
  &-item {
    padding-top: px2rem(30px);
    &-title {
      font-size: px2rem(16px);
      display: flex;
      align-items: baseline;
      span {
        color: #edb63f;
      }
      &-icon {
        width: px2rem(3px);
        height: px2rem(12px);
        border-radius: px2rem(2px);
        background-color: #edb63f;
        margin-right: px2rem(7px);
      }
    }
    &-content {
      height: px2rem(56px);
      font-size: px2rem(13px);
      font-weight: 500;
      color: #b7b7b7;
      line-height: px2rem(19px);
    }
  }
  &-tip {
    margin-top: px2rem(30px);
    height: px2rem(56px);
    font-size: px2rem(13px);
    font-weight: 500;
    color: #b7b7b7;
    line-height: px2rem(19px);
  }
}

.mirror {
  .pg-rule-vip {
    direction: rtl;
    &-item {
      &-title {
        &-icon {
          margin-left: px2rem(7px);
        }
      }
    }
  }
}
