@import '../../../common.scss';

.cmp-member-card {
  background-color: rgba($color: #ffffff, $alpha: 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: px2rem(6px);
  margin: 0 px2rem(15px);
  height: px2rem(70px);

  &__manager {
    height: 0.8rem;
    padding: 0 px2rem(10px);
    align-items: center;
    border-radius: 0.6133333333rem;
    display: flex;
    font-size: .32rem;
    justify-content: center;
    background: linear-gradient(180deg, #1ff5a9, #00e0d5);
    color: #fff;
    font-weight: 500;
    white-space: nowrap;

    &.cancel {
      background: rgba(255, 92, 92, 1);
    }
  }

  &__info {
    flex: 1;
    padding: px2rem(10px);
    display: flex;
    align-items: center;

    &-avatar {
      margin-right: px2rem(10px);

      img {
        width: px2rem(50px);
        height: px2rem(50px);
        border-radius: 50%;
        display: block;
        object-fit: cover;
      }
    }

    &-basic {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
    }

    &-name {
      width: 100%;
      font-size: px2rem(13px);
      color: #ffffff;

      &.withIcons {
        margin-bottom: px2rem(7px);
      }
    }

    &-icons {
      display: flex;

      img {
        margin-right: px2rem(6px);
        height: px2rem(18px);
      }
    }

    &-No {
      width: 100%;
      color: rgba($color: #ffffff, $alpha: 0.6);
    }
  }

  &__role {
    width: px2rem(36px);
    height: px2rem(36px);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: px2rem(20px);
      height: px2rem(20px);
    }
  }

  &__arrow {
    width: px2rem(36px);
    height: px2rem(36px);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transform: rotateZ(180deg);
      width: px2rem(16px);
      height: px2rem(16px);
    }
  }

  &__time {
    font-size: px2rem(13px);
    color: rgba($color: #ffffff, $alpha: 0.6);
  }

  &__invite {
    background: linear-gradient(to bottom, #1ff5a9, #00e0d5);
    width: px2rem(68px);
    height: px2rem(30px);
    border-radius: px2rem(23px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    &.disabled {
      background: unset;
      background-color: rgba($color: #f0f0f0, $alpha: 0.4);
      color: #b7b7b7;
    }
  }

  &__remove {
    width: px2rem(68px);
    height: px2rem(32px);
    border: solid px2rem(2px) #10ebbe;
    border-radius: px2rem(23px);
    color: #10ebbe;
    font-size: px2rem(12px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: px2rem(9px);
  }

  &__right-btn {
    background: linear-gradient(to bottom, #1ff5a9, #00e0d5);
    width: px2rem(68px);
    height: px2rem(30px);
    border-radius: px2rem(23px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: px2rem(12px);
    font-weight: 500;
  }
}

.mirror {
  .cmp-member-card {
    flex-direction: row-reverse;

    &__info {
      flex-direction: row-reverse;

      &-avatar {
        margin-right: 0;
        margin-left: px2rem(10px);
      }

      &-name {
        text-align: right;
      }

      &-No {
        text-align: right;
      }
    }

    &__arrow {
      img {
        transform: none;
      }
    }
  }
}