@import '../../common.scss';

.pg-rule-pk {
    margin: 0 auto;
    box-sizing: border-box;
    width: px2rem(345px);
    height: px2rem(605px);
    border: px2rem(2px) solid rgba(235, 215, 137, 1);
    padding: px2rem(10px);

    img {
        width: 100%;
        height: 100%;
    }
}