@import '~@/common.scss';

.cmp-collection-information-country {
  width: 100%;
  box-sizing: border-box;
  color: #FFFFFF;

  &-list {
    height: px2rem(405px);
    overflow-y: auto;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px2rem(15px) 0;
    border-bottom: px2rem(1px) solid rgba($color: #EEEEEE, $alpha: 0.2);

    &-left {
      display: flex;

      span {
        margin-left: px2rem(9px);
        vertical-align: middle;
      }

      img {
        width: px2rem(30px);
        height: px2rem(20px);
      }
    }

    &-select {
      height: px2rem(17px);
      width: px2rem(17px);
    }
  }

  &-title {
    font-size: px2rem(18px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: px2rem(60px);
    border-bottom: px2rem(1px) solid rgba($color: #EEEEEE, $alpha: 0.2);
  }

  &-option {
    font-size: px2rem(13px);
    line-height: px2rem(19px);
    margin-top: px2rem(16px);
  }
}

.mirror {
  & .cmp-collection-information-country {
    padding-right: px2rem(15px);

    &-option {
      text-align: right;
    }

    &-item {
      flex-direction: row-reverse;
      margin-right: unset;

      &-left {
        flex-direction: row-reverse;

        span {
          margin-right: px2rem(9px);
        }
      }
    }
  }
}