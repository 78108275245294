@import '~@/common.scss';

.wallet-convertible-gem-dialog {
    margin-top: px2rem(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__content {
        display: flex;
        justify-content: center;

        .left,
        .right {
            display: flex;
            flex-direction: column;
            align-items: center;

            .img {
                width: px2rem(90px);
                height: px2rem(90px);
                background: #FFFFFF;
                border-radius: px2rem(10px);
                border: 1px solid #E8E8E8;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: px2rem(50px);
                    height: px2rem(50px);
                    object-fit: cover;
                }
            }

            .gem,
            .coin {
                display: flex;
                align-items: center;
                margin-top: px2rem(10px);

                img {
                    width: px2rem(12px);
                    height: px2rem(12px);
                    object-fit: cover;
                    margin: 0 px2rem(6px);
                }

                span {
                    font-size: px2rem(12px);
                    color: #000000;
                }
            }
        }

        .middle {
            width: px2rem(39px);
            height: px2rem(18px);
            object-fit: cover;
            margin: px2rem(36px) px2rem(10px);
        }
    }

    &__tips {
        font-size: px2rem(12px);
        font-weight: 400;
        color: #FF5555;
        text-align: center;
        margin-top: px2rem(15px);
    }

    &__btn {
        display: flex;
        justify-content: space-between;
        margin-top: px2rem(30px);

        .cancel,
        .top-up {
            width: px2rem(120px);
            height: px2rem(40px);
            border-radius: px2rem(23px);
            line-height: px2rem(40px);
            text-align: center;
            font-size: px2rem(14px);
            font-weight: 500;
        }

        .cancel {
            background: #F0F0F0;
            color: #B7B7B7;
        }

        .top-up {
            background: linear-gradient(to right, #1FF5A9, #00E0D5);
            color: #fff;
        }
    }
}

.mirror .wallet-convertible-gem-dialog {
    &__content {
        .middle {
            transform: scaleX(-1);
        }
    }
}