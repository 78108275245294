@import '../../common.scss';

.pg-guild-home {
  background-color: black;
  background-image: url('../../images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &__close {
    position: absolute;
    top: px2rem(40px);
    right: px2rem(5px);
    width: px2rem(41px);
    height: px2rem(41px);
    z-index: 1;
  }

  .cmp-pane {
    margin-top: px2rem(20px);

    &__title {
      &>.arrowhead {
        margin-left: auto;
        width: px2rem(16px);
        height: px2rem(16px);
        background: url('~@/images/arrow.png') no-repeat;
        background-size: cover;
        transform: scaleX(-1);
      }
    }
  }

  .cmp-info-card {
    height: px2rem(68px);
  }

  .cmp-guild-task-progress {
    margin: 0 px2rem(15px);

    .cmp-scroll-tabs {
      font-size: px2rem(14px);
    }
  }

  .wallet-data-card-last {
    margin-top: px2rem(8px);
  }

  .cmp-number-card {
    &__item {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .cmp-member-card {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .cmp-scroll-tabs {
    font-size: px2rem(15px);
    width: auto;
    height: px2rem(45px);
    border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.1);
    color: rgba($color: #fff, $alpha: 0.6);
    margin: 0 px2rem(15px);

    &>.selected {
      color: rgba($color: #fff, $alpha: 1);

      &::after {
        width: px2rem(20px);
        height: px2rem(3px);
        background-color: #fff;
        bottom: px2rem(14px);
      }
    }
  }

  .cmp-guild-task-progress {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &-content {
    background-color: rgba(255, 255, 255, 0.1);
    padding: px2rem(20px) 0;
    margin-top: px2rem(15px);
  }

  &__invite-btn {
    position: absolute;
    background-color: rgba(25, 20, 31, 1);
    border: px2rem(1px) solid rgba(16, 235, 190, 1);
    border-radius: px2rem(9999px);
    top: px2rem(280px);
    right: px2rem(15px);
    padding: px2rem(4px) px2rem(10px);
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
      font-size: px2rem(11px);
      color: #FFFFFF;
    }

    img {
      width: px2rem(9px);
      height: px2rem(9px);
      transform: scaleX(-1);
    }
  }

  &__rewards-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
      background-image: url(./images/exclusive-gifts-dialog.webp);
      background-repeat: no-repeat;
      background-size: cover;
      width: px2rem(315px);
      height: px2rem(446px);
      position: relative;

      &.success {
        background-image: url(./images/successfully-received-dialog.webp);
        width: px2rem(349px);
        height: px2rem(402px);

        .pg-guild-home__rewards-dialog-title {
          margin-top: px2rem(24px);
          margin-bottom: px2rem(55px);
        }

      }
    }

    &-title {
      margin-top: px2rem(120px);
      font-weight: bold;
      font-size: px2rem(17px);
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin: 0 px2rem(13px);
      }

      &::after {
        content: '';
        display: block;
        background: url('./images/exclusive-gifts-title-icon.webp') no-repeat center/cover;
        width: px2rem(12px);
        height: px2rem(7px);
        transform: scaleX(-1);
      }

      &::before {
        content: '';
        display: block;
        background: url('./images/exclusive-gifts-title-icon.webp') no-repeat center/cover;
        width: px2rem(12px);
        height: px2rem(7px);
      }
    }

    &-gifts {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 px2rem(30px);
      box-sizing: border-box;
      width: 100%;
      height: px2rem(230px);

      div {
        width: px2rem(100px);
        height: px2rem(101px);
        background: #FFFFFF;
        border-radius: px2rem(6px);
        border: px2rem(1px) solid #09A787;
        padding: px2rem(7px) px2rem(15px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
          width: px2rem(60px);
          height: px2rem(60px);
          object-fit: contain;
        }

        span {
          font-weight: 500;
          font-size: px2rem(18px);
          color: #000000;
          margin-top: px2rem(2px);
        }
      }
    }

    &-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: px2rem(25px);
      width: px2rem(221px);
      height: px2rem(44px);
      border-radius: px2rem(99999px);
      background-image: linear-gradient(to bottom, rgba(31, 245, 169, 1), rgba(0, 224, 213, 1));
      text-align: center;
      line-height: px2rem(44px);
      font-weight: bold;
      font-size: px2rem(14px);
      color: #FFFFFF;
      border: px2rem(1px) solid rgba(255, 255, 255, 1);
      box-sizing: border-box;
    }
  }

  &-agency-data {
    margin: 0 px2rem(15px);

    &__href {
      margin-top: px2rem(20px);

      &::after {
        content: '';
        display: block;
        width: px2rem(315px);
        height: px2rem(1px);
        background-color: rgba(255, 255, 255, 0.1);
        margin: 0 auto;
      }
    }

    &__rate {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>.left {
        display: flex;
        align-items: center;

        span {
          font-weight: 500;
          font-size: px2rem(13px);
          color: #FFFFFF;
        }

        div {
          border: px2rem(1px) solid rgba(16, 235, 190, 1);
          border-radius: px2rem(999999px);
          padding: 0 px2rem(14px);
          margin: 0 px2rem(6px);
          font-weight: 600;
          font-size: px2rem(12px);
          color: #10EBBE;
        }
      }

      &>.right {
        width: px2rem(16px);
        height: px2rem(16px);
        object-fit: cover;
        transform: scaleX(-1);
      }
    }

    &__progress {
      margin-top: px2rem(20px);

      &>.top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: px2rem(13px);
        color: #FFFFFF;
        margin-bottom: px2rem(10px);
      }

      &>.tip {
        font-weight: 400;
        font-size: px2rem(11px);
        color: rgba(183, 183, 183, 1);
        text-align: center;
        margin-top: px2rem(10px);
        margin-bottom: px2rem(20px);

        span {
          color: #10EBBE;
        }
      }

      .adm-progress-bar-trail {
        background: rgba(255, 255, 255, 0.20);

        .adm-progress-bar-fill {
          background: linear-gradient(to right, #1FF5A9, #00E0D5);
        }
      }
    }

    &__info {
      margin-top: px2rem(20px);
    }

    &__light {
      &-item {
        font-size: px2rem(12px);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:first-child {
          margin-bottom: px2rem(10px);
        }

        &:last-child {
          margin-bottom: px2rem(6px);
        }

        &>.gem {
          img {
            width: px2rem(13px);
            height: px2rem(13px);
            object-fit: cover;
            margin: 0 px2rem(6px);
          }

          span {
            font-size: px2rem(14px);
          }
        }
      }
    }

    &__dark {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: px2rem(8px);
      padding: px2rem(10px);
      box-sizing: border-box;
      margin-bottom: px2rem(10px);

      &-item {
        font-size: px2rem(12px);
        color: rgba($color: #FFFFFF, $alpha: 0.6);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &>.gem {
          img {
            width: px2rem(13px);
            height: px2rem(13px);
            object-fit: cover;
            margin: 0 px2rem(6px);
          }

          span {
            font-size: px2rem(14px);
          }
        }
      }
    }
  }

  &__member-list {
    &-header {
      height: px2rem(55px);
      position: relative;
      border-bottom: rgba(153, 153, 153, 0.2) px2rem(1px) solid;
    }

    &-title {
      width: 100%;
      height: px2rem(55px);
      line-height: px2rem(55px);
      text-align: center;
      font-size: px2rem(18px);
      color: white;
    }

    &-close {
      width: px2rem(40px);
      height: px2rem(55px);
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: px2rem(10px);
        height: px2rem(17px);
        transform: rotateZ(-90deg);
      }

      &-down {
        width: px2rem(17px) !important;
        height: px2rem(10px) !important;
        transform: rotateZ(0deg) !important;
      }
    }

    &-content {
      height: px2rem(405px);
      overflow-x: hidden;
      overflow-y: auto;

      .cmp-member-card {
        background-color: transparent;

        &__info {
          padding: px2rem(15px) 0;
          position: relative;
          word-break: break-word;

          &:after {
            content: '';
            position: absolute;
            width: px2rem(303px);
            height: px2rem(1px);
            background-color: rgba($color: #999999, $alpha: 0.2);
            left: px2rem(60px);
            bottom: 0;
          }
        }
      }
    }



    & .adm-popup-body.adm-popup-body-position-bottom {
      border-radius: px2rem(15px) px2rem(15px) 0 0;
    }
  }

  &__message-list-apply {
    .apply-content {

      .cmp-member-card {

        &__info {

          &:after {
            background: none;
          }
        }
      }
    }

  }

  &__message,
  &__fans {
    &-header {
      height: px2rem(55px);
      position: relative;
      border-bottom: rgba(153, 153, 153, 0.2) px2rem(1px) solid;
    }

    &-title {
      width: 100%;
      height: px2rem(55px);
      line-height: px2rem(55px);
      text-align: center;
      font-size: px2rem(18px);
      color: white;
    }

    &-close {
      width: px2rem(40px);
      height: px2rem(55px);
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: px2rem(17px);
        transform: rotateZ(-90deg);
      }
    }

    &-content {
      height: px2rem(405px);
      overflow-x: hidden;
      overflow-y: auto;

      .cmp-member-card {
        background-color: transparent;

        &__info {
          padding: px2rem(15px) 0;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: px2rem(303px);
            height: px2rem(1px);
            background-color: rgba($color: #999999, $alpha: 0.2);
            left: px2rem(57px);
            bottom: 0;
          }
        }
      }
    }
  }

  &__message {
    .cmp-member-card {
      &__info:after {
        bottom: px2rem(-46px);
      }
    }
  }

  &__my-info {
    padding: px2rem(10px);
    background-color: rgba(0, 0, 0, 0.3);
    margin: 0 px2rem(15px);
    border-radius: px2rem(6px);

    .cmp-member-card {
      height: unset;
      background-color: unset;
      margin: unset;
      margin-bottom: px2rem(12px);

      &__info {
        padding: unset;
      }
    }

    &>span {
      color: rgba($color: #fff, $alpha: 0.6);
      display: block;
    }
  }
}

.mirror {
  .pg-guild-home {
    &__my-info {
      &>span {
        direction: rtl;
      }
    }

    &__close {
      left: px2rem(5px);
      right: unset;
    }

    &__invite-btn {
      right: unset;
      left: px2rem(15px);
      direction: rtl;

      img {
        transform: scaleX(1);
      }
    }

    &-agency-data {
      direction: rtl;

      &__rate {

        &>.right {
          transform: scaleX(1);
        }
      }
    }

    &__fans {
      &-content {
        .cmp-member-card {
          &__info {
            &:after {
              content: '';
              left: unset;
              right: px2rem(57px);
            }

            &-icons {
              width: 100%;
              flex-direction: row-reverse;
            }
          }
        }
      }
    }

    .cmp-pane {
      &__title {
        &>.arrowhead {
          margin-left: unset;
          margin-right: auto;
          transform: scaleX(1);
        }
      }
    }
  }
}