@import '../../../common.scss';

.cmp-rdt {
  box-sizing: border-box;
  margin-top: px2rem(15px);
  width: px2rem(345px);
  background: #FFFFFF;
  border-radius: px2rem(6px);
  padding: 0 px2rem(10px);
  font-size: px2rem(12px);

  .title {
    height: px2rem(40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba($color: #000, $alpha: 0.6);
    padding: 0 px2rem(25px) 0 px2rem(65px);
    border-bottom: 1px solid rgba(153, 153, 153, 0.1);
    position: sticky;
    top: 0;
    background: #FFFFFF;

    span {
      min-width: px2rem(62px);
    }
  }

  .content {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 px2rem(45px);
      height: px2rem(45px);
      text-align: center;
      font-size: px2rem(12px);
      color: #000;
      border-bottom: 1px solid rgba(153, 153, 153, 0.1);

      span {
        min-width: px2rem(62px);
      }

      span:nth-child(2) {
        text-align: center;
      }

      &:last-child {
        border-bottom: unset;
      }
    }
  }
}

.mirror {
  .cmp-rdt {
    .title {
      flex-direction: row-reverse;
    }

    .content {
      .item {
        flex-direction: row-reverse;
      }
    }
  }
}