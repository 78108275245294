@import '~@/common.scss';

.cmp-lovecard-item {
  width: px2rem(345px);
  height: px2rem(216px);
  padding: px2rem(10px);
  box-sizing: border-box;
  color: white;
  position: relative;

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__header,
  &__bottom {
    position: relative;
    z-index: 1;
    border-radius: px2rem(6px);
    padding: px2rem(10px);
    background-color: rgba(#8E3D00, .2);
    width: 100%;
    box-sizing: border-box;
  }

  &__main {
    position: relative;
    z-index: 1;
    margin-top: px2rem(10px);

    &-info {
      margin: px2rem(20px) 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      &-icon {
        width: px2rem(40px);
        height: px2rem(40px);
      }

      &-coins {
        font-size: px2rem(30px);
        font-weight: bold;
        margin-left: px2rem(10px);
      }

      &-action {
        min-width: px2rem(90px);
        height: px2rem(30px);
        padding: px2rem(7px);
        box-sizing: border-box;
        background-color: #303030;
        border-radius: px2rem(18px);
        text-align: center;
        color: #FFE0B6;
        font-size: px2rem(14px);
        font-weight: bold;
        position: absolute;
        right: 0;
      }

    }

    &-title {
      font-size: px2rem(11px);
      line-height: px2rem(15px);
    }

    &-value {
      font-size: px2rem(16px);
      font-weight: bold;
      line-height: px2rem(19px);
      margin-top: px2rem(6px);
      margin-bottom: px2rem(7px);
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-time {
      flex: 1;
      text-overflow: clip;
      overflow: hidden;
      white-space: nowrap;
      text-align: right;
    }

    &-limit {
      flex: 1;
      display: flex;
      align-items: center;
      text-overflow: clip;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__coin {
    width: px2rem(10px);
    height: px2rem(10px);
    margin-left: px2rem(4px);
  }
}

.cmp-lovecard-create {
  padding: px2rem(10px);
  color: white;
  width: px2rem(345px);
  height: px2rem(91px);
  background-image: url('../Images/CardCreateBackground.png');
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &__title {
    font-size: px2rem(16px);
    font-weight: bold;
  }

  &__text {
    margin-top: px2rem(10px);
  }

  &__plus {
    position: absolute;
    right: px2rem(10px);
    width: px2rem(40px);
    height: px2rem(40px);
  }
}

.mirror {
  .cmp-lovecard-item {
    &__bg {
      transform: rotateY(180deg);
    }

    &__main {
      text-align: right;

      &-info {
        justify-content: flex-end;

        &-action {
          right: unset;
          left: 0;
        }
      }

    }

    &__bottom {
      flex-direction: row-reverse;

      &-time {
        text-align: left;
      }
    }
  }

  .cmp-lovecard-create {
    text-align: right;

    &__plus {
      left: px2rem(10px);
      right: unset;
    }
  }
}