@import '../../common.scss';

.pg-member-historical-data {
  background-color: black;
  background-image: url('../../images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: px2rem(30px);

  .cmp-select-time-range {
    margin-top: px2rem(34px);
    margin-bottom: px2rem(15px);
  }

  >.cmp-number-card {
    width: 100%;

    &.last {
      margin-top: px2rem(15px);
    }
  }

  .adm-infinite-scroll {
    display: none;
  }

  .cmp-mdt {
    margin: px2rem(15px) px2rem(15px) 0;
  }

  &__list {
    width: 100%;
  }

  &>.user-info {
    background-color: rgba($color: #FFFFFF, $alpha: .1);
    border-radius: px2rem(6px);
    width: px2rem(345px);
    margin-top: px2rem(30px);
    padding: 0 px2rem(10px);
    box-sizing: border-box;

    .cmp-member-card {
      width: 100%;
      background-color: unset;
      margin: 0;
      border-bottom: px2rem(1px) solid rgba($color: #FFFFFF, $alpha: .1);

      &__info {
        padding: 0;

        &-name {
          margin-bottom: px2rem(8px);
        }
      }
    }

    .cmp-number-card2 {
      width: 100%;
      background: unset;

      &__item {
        &-val {
          &::after {
            width: px2rem(100px);
          }
        }
      }
    }
  }

  // .cmp-pane {
  //   margin-bottom: px2rem(15px);
  //   &__title {
  //     color: rgba($color: #FFFFFF, $alpha: .6);
  //     font-weight: normal;
  //   }
  // }
}

.mirror .pg-member-historical-data {
  .tips {
    direction: rtl;
  }
}