@import '~@/common.scss';

.pg-guild-task-data-member-detail {
    background-color: #171526;
    background-image: url('~@/images/guild-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: px2rem(21px);

    .cmp-member-card {
        padding: 0;
        margin: 0;
        background-color: unset;
        height: px2rem(75px);
        border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.1);

        &__info {
            padding-left: unset;

            &-avatar {

                width: px2rem(50px);
                height: px2rem(50px);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &-name {
                margin-bottom: px2rem(8px);
            }

            &-No {
                font-size: px2rem(12px);
            }
        }

        &__right-btn {
            background: none;
            border: px2rem(2px) solid rgba(16, 235, 190, 1);
            font-size: px2rem(12px);
            font-weight: 500;
            color: #10EBBE;
        }
    }

    .cmp-number-card2 {
        background: none;
        width: 100%;

        &__item {
            &-val {
                &::after {
                    width: px2rem(100px);
                }
            }
        }
    }

    .cmp-guild-tab-buttons {
        margin: px2rem(15px) 0;
    }

    .cmp-select-day-time {
        margin-bottom: px2rem(15px);
        background-color: rgba(255, 255, 255, 0.1);
        border: none;

        &__time {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    .cmp-guild-task-progress {
        .cmp-scroll-tabs {
            font-size: px2rem(14px);
        }
    }

    &>.content {
        padding: 0 px2rem(15px);
        width: 100%;
        box-sizing: border-box;

        &>.top {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: px2rem(6px);
            margin-top: px2rem(15px);
            padding: 0 px2rem(10px);
            box-sizing: border-box;
        }

        &>.middle {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: px2rem(30px);

            &>.middle__card2 {
                width: 100%;
                position: relative;

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: px2rem(1px);
                    background: linear-gradient(to right, rgba(252, 195, 1, 0), rgba(253, 195, 0, 0.6), rgba(255, 255, 255, 1), rgba(253, 195, 0, 1), rgba(238, 188, 24, 0));
                    border-radius: px2rem(2px);
                }

                &::after {
                    bottom: 0;
                }

                &::before {
                    top: 0;
                }

                &>.cmp-number-card2 {

                    background: linear-gradient(to right, rgba(56, 34, 63, 0), rgba(56, 34, 63, 0.51), rgba(56, 34, 63, 0.51), rgba(56, 34, 63, 0.51), rgba(56, 34, 63, 0));

                    &>.cmp-number-card2__item {

                        &>.cmp-number-card2__item-val {
                            &::after {
                                width: px2rem(60px);
                            }
                        }
                    }
                }
            }
        }

        &>.bottom {
            margin-top: px2rem(30px);
        }
    }
}