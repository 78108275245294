@import '../../../common.scss';

.cmp-info-list {
  margin: 0 px2rem(15px);
  border-radius: px2rem(6px);
  background-color: rgba($color: #FFFFFF, $alpha: .1);
  &__item {
    position: relative;
    padding: px2rem(16px) px2rem(13px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: px2rem(14px);
    color: white;
    &:not(:last-child):after{
      content: '';
      position: absolute;
      bottom: 0;
      width: px2rem(319px);
      height: px2rem(1px);
      background-color: rgba($color: #EEEEEE, $alpha: .1);
    }
  }
}

.mirror {
  .cmp-info-list {
    &__item {
      flex-direction: row-reverse;
    }
  }
}