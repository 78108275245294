@import '../../../common.scss';

.pg-guild-detail {
    .detail__dialog-content {
        position: relative;
        padding-bottom: px2rem(40px);

        .dialog-item {
            font-size: px2rem(13px);
            font-weight: 400;
            color: #999999;
            margin-bottom: px2rem(19px);
        }

        .dialog-btn {
            width: 100%;
            display: flex;
            justify-content: space-around;
            position: fixed;
            bottom: px2rem(20px);
            left: 0;

            div {
                width: px2rem(120px);
                height: px2rem(40px);
                border-radius: px2rem(23px);
                text-align: center;
                line-height: px2rem(40px);
                font-size: px2rem(14px);
                font-weight: 500;
            }

            .cancel {
                background: #F0F0F0;
                color: #B7B7B7;
            }
        }
    }
}

.mirror .pg-guild-detail {
    .detail__dialog-content {
        direction: rtl;
    }
}