@import '../../common.scss';

.pg-dl {
  width: 100%;
  height: px2rem(812px);
  background-image: url(./images/background.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &__btn {
    position: absolute;
    left: px2rem(82px);
    bottom: px2rem(21px);
    width: px2rem(214px);
    height: px2rem(83px);
    background-image: url(./images/button.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}