@import '~@/common.scss';

.pg-lovecard-create {
  padding-top: px2rem(88px);
  background-color: #FAFAFA;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &__coins {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F3F3F3;
    padding: px2rem(15px);
    box-sizing: border-box;
    width: px2rem(345px);
    height: px2rem(55px);
    margin: px2rem(15px);

    &-icon {
      width: px2rem(16px);
      height: px2rem(16px);
    }

    input {
      background-color: transparent;
      border: none;
      text-align: right;
    }
  }

  &__bottom {
    position: fixed;
    bottom: 0;
    height: px2rem(167px);
    margin: 0 px2rem(15px);

    &-tips {
      width: 100%;
      height: px2rem(50px);
      color: #999999;
      font-size: px2rem(12px);
    }

    &-confirm {
      margin-top: px2rem(30px);
      background: linear-gradient(to right, #1FF5A9, #00E0D5);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: px2rem(345px);
      height: px2rem(38px);
      border-radius: px2rem(20px);
    }
  }

  .cmp-pane {
    margin-top: px2rem(15px);
    padding-bottom: px2rem(15px);

    &__title {
      color: #333333;
    }

  }

  .cmp-user {
    margin: px2rem(15px);
    background-color: #F3F3F3;
    border-radius: 6px;
    padding: px2rem(6px);
    color: black;

    &__nick {
      margin-bottom: px2rem(4px);
    }
  }
}

.mirror {
  .pg-lovecard-create {
    &__coins {
      flex-direction: row-reverse;
    }

    input {
      text-align: left;
    }

    &__bottom {
      text-align: right;
    }
  }
}