@import '~@/common.scss';

.pg-wallet-gem {
    min-height: 100vh;
    background: #FAFAFA;
    box-sizing: border-box;

    .cmp-number-card2 {
        background: none;
        margin-top: px2rem(10px);

        &__item {
            &::after {
                border-right-color: rgba(153, 153, 153, 0.2);
            }

            &-val {
                color: #000;

                &::after {
                    border-bottom-color: rgba(153, 153, 153, 0.2);
                }
            }

            &-key {
                color: #B7B7B7;
            }
        }
    }

    .cmp-wallet-banner {
        margin: px2rem(15px) px2rem(15px) 0;
    }

    .cmp-pane {
        margin: 0 px2rem(15px);
        margin-top: px2rem(20px);
        position: relative;

        &__title {
            font-size: px2rem(16px);
            font-weight: 500;
            color: #333333;
            position: relative;
            margin: 0 px2rem(10px);
            margin-bottom: px2rem(17px);

            &::before {
                content: '';
                position: absolute;
                width: px2rem(3px);
                height: px2rem(15px);
                border-radius: px2rem(2px);
                background: linear-gradient(to bottom, rgba(31, 245, 169, 1), rgba(0, 224, 213, 1));
                left: px2rem(-10px);
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .title-extra {
            display: flex;
            align-items: center;
            margin: 0 px2rem(6px);

            img {
                width: px2rem(19px);
                height: px2rem(19px);
                object-fit: cover;
            }

            span {
                font-size: px2rem(16px);
                font-weight: bold;
                color: #00C8F5;
                margin: 0 px2rem(4px);
            }
        }
    }
}

.mirror .pg-wallet-gem {
    .cmp-pane {
        &__title {
            &::before {
                left: unset;
                right: px2rem(-10px);
            }
        }
    }
}