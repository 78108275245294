@import '~@/common.scss';

.cmp-collection-information-input {
    display: flex;
    flex-direction: column;
    margin-bottom: px2rem(20px);

    &__title {
        font-size: px2rem(14px);
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: px2rem(10px);
    }

    &__input-box {
        width: 100%;
        height: px2rem(40px);
        position: relative;
    }

    &__input {
        all: unset;
        text-indent: px2rem(10px);
    }

    &__input,
    &__info {
        width: 100%;
        height: 100%;
        background: rgba($color: #F0F0F0, $alpha: 0.1);
        border-radius: px2rem(6px);
        font-size: px2rem(14px);
        color: #999999;
    }

    &__info {
        display: flex;
        align-items: center;
        padding: 0 px2rem(10px);
        box-sizing: border-box;

        &>img {
            width: px2rem(30px);
            height: px2rem(20px);
            object-fit: cover;

            &:nth-child(2n) {
                margin: 0 px2rem(15px);
            }
        }

        &>span {
            color: #FFFFFF;
            margin: 0 px2rem(10px);
        }
    }

    &__arrow {
        width: px2rem(8px);
        height: px2rem(10px);
        object-fit: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scaleX(-1);
        right: px2rem(11px);
    }
}

.mirror .cmp-collection-information-input {
    &__arrow {
        right: unset;
        left: px2rem(11px);
        transform: translateY(-50%) scaleX(1);
    }
}