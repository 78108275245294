@import '../../common.scss';

.pg-guild-detail {
  background-color: black;
  background-image: url('../../images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  .cmp-announce {
    margin-top: px2rem(20px);
  }
  .cmp-info-list {
    margin-top: px2rem(20px);
  }
  // .cmp-top3 {
    // margin-top: px2rem(20px);
  // }
  &__bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding-bottom: px2rem(4px);
    color: white;
    z-index: 10;
  }
  &__btn {
    &-apply {
      margin: 0 auto;
      width: px2rem(345px);
      height: px2rem(38px);
      background: linear-gradient(to bottom ,#FFF140, #C29900);
      border-radius: px2rem(20px);
      display: flex;
      justify-content: center;
      align-items: center;
      &-in {
        width: px2rem(341px);
        height: px2rem(34px);
        border-radius: px2rem(20px);
        background: linear-gradient(to bottom, #4C374E, #320037);
        text-align: center;
        line-height: px2rem(34px);
        font-size: px2rem(14px);
      }
    }
  }
}