@import '../../common.scss';

.pg-historical-data {
  background-color: black;
  background-image: url('../../images/guild-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: unset !important;
  height: 100vh;

  &__content {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .cmp-select-time-range {
    margin-top: px2rem(20px);
    margin-bottom: px2rem(4px);
  }

  .cmp-member-historical-card {
    margin-top: px2rem(20px);

    &::after {
      all: unset;
    }

    .cmp-member-card {
      background-color: transparent;
    }
  }

  .adm-search-bar {
    background-color: #000;
  }

  .search-root {
    margin-top: px2rem(30px);
  }

  .cmp-member-card__arrow {
    img {
      width: px2rem(13px);
      height: px2rem(13px);
    }
  }
}