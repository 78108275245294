@import '~@/common.scss';

.cmp-guild-gift-progress {
    width: 100%;
    position: relative;
    padding: px2rem(34px) 0;
    flex: 25%; //一行进度条展示多少个

    &__gift-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateX(50%);
        margin-left: auto;

        &-gray {
            width: px2rem(22px);
            height: px2rem(22px);
            background-image: url(~@/images/gift-gray.webp);
            background-repeat: no-repeat;
            background-size: cover;
        }

        .open {
            background-image: url(~@/images/gift-open.webp);
        }

        .open-target {
            color: #10EBBE;
        }

        .hide-reward {
            opacity: 0;
        }

        &-target {
            margin-top: px2rem(6px);
            font-size: px2rem(11px);
            color: #B7B7B7;
        }

        &-reward {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: px2rem(10px);
            color: #FFFFFF;
            background: #10EBBE;
            border-radius: px2rem(999999px);
            padding: 0 px2rem(10px);
            margin-bottom: px2rem(3px);
            position: relative;

            &::after {
                content: '';
                position: absolute;
                border: px2rem(3px) solid transparent;
                border-top-color: #10EBBE;
                bottom: px2rem(-6px);
                left: 50%;
                transform: translateX(-50%);
            }

            .img {
                width: px2rem(10px);
                height: px2rem(10px);
                background-image: url(~@/images/gem.png);
                background-repeat: no-repeat;
                background-size: cover;
                margin-left: px2rem(2px);
            }
        }
    }

    &__gift-content {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__bar {
        height: px2rem(5px);
        border-radius: px2rem(10px);
        background-color: rgba(255, 255, 255, 0.2);
        overflow: hidden;

        &-fill {
            border-radius: inherit;
            height: inherit;
            background-color: rgba(16, 235, 190, 1);
        }
    }

    &__curr {
        position: absolute;
        border-radius: px2rem(999999px);
        padding: 0 px2rem(10px);
        background-color: #4E4C55;
        top: 20%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        font-size: px2rem(10px);
        color: #FFFFFF;

        &::after {
            content: '';
            position: absolute;
            border: px2rem(3px) solid transparent;
            border-top-color: #4E4C55;
            bottom: px2rem(-6px);
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.mirror .cmp-guild-gift-progress {
    direction: rtl;

    &__gift-box {
        transform: translateX(-50%);
        margin-left: unset;
        margin-right: auto;

        &-reward {
            .img {
                margin-left: unset;
                margin-right: px2rem(2px);
            }
        }
    }
}