@import '~@/common.scss';

.pg-wallet-collection-information {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: #1A1426;
    position: relative;

    .place {
        min-height: px2rem(20px);
    }

    &__content {
        padding: 0 px2rem(15px);
        box-sizing: border-box;
        margin-top: px2rem(20px);

        &-currency {
            .cmp-collection-information-input {
                &__info {
                    &>span {
                        margin: 0;
                    }
                }
            }
        }
    }

    &__save {
        margin: 0 px2rem(15px);
        height: px2rem(38px);
        border-radius: px2rem(20px);
        margin-top: auto;
        margin-bottom: px2rem(10px);
    }
}

.mirror .pg-wallet-collection-information {
    direction: rtl;
}