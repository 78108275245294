@import '~@/common.scss';

.pg-agent-recharge-cash-transfer {
  background-color: #FAFAFA;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  .cmp-pane {
    margin: px2rem(15px) px2rem(15px) 0;

    &__title {
      margin-left: 0;
      margin-right: 0;
      font-size: px2rem(14px);
      font-weight: 500;
      color: #333333;
    }
  }

  .cmp-pane:first-child {
    margin: px2rem(15px) px2rem(15px) 0;
  }

  .cmp-wallet-banner {
    background-image: url('~@/images/wallet/withdraw-banner-bg.webp');

    &>.info {
      &>.img {
        background-image: url('~@/images/money.webp');
      }

      &>span {
        @include text-stroke('#00BF37', px2rem(1px));
      }
    }
  }

  &-top-up-popup__popup {

    &-header {
      height: px2rem(55px);
      line-height: px2rem(55px);
      position: relative;
      border-bottom: px2rem(1px) solid rgba($color: #999, $alpha: 0.4);
      border-top-left-radius: px2rem(12px);
    }

    &-title {
      width: 100%;
      line-height: px2rem(55px);
      font-size: px2rem(18px);
      text-align: center;
      color: #000;
    }

    &-cancel {
      position: absolute;
      font-size: px2rem(15px);
      color: #999;
      right: px2rem(15px);
      top: 0;
    }
  }

  .cmp-transfer-content__amount {
    &::before {
      background-image: url('~@/images/money.webp');
    }
  }
}



.mirror .pg-agent-recharge-cash-transfer {}