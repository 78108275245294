@function px2rem($px) {
  @return calc($px / 37.5px) * 1rem;
}

@function px2vw($px) {
  @return calc($px / 2180px) * 100vw;
}

// 文字描边
@mixin text-stroke($color: #fff, $width: 1px) {
  text-shadow: 0 -#{$width} #{$color},
  #{$width} 0 #{$color},
  0 #{$width} #{$color},
  -#{$width} 0 #{$color},
  -#{$width} -#{$width} #{$color},
  #{$width} #{$width} #{$color},
  #{$width} -#{$width} #{$color},
  -#{$width} #{$width} #{$color};
}